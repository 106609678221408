/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { IStep } from '../guidelines/customStep';
import { useDispatchRoot } from '@src/src/app/redux/store';
import { stepProcessData } from '../guidelines/guideline.types';
import { useLocation } from 'react-router-dom';

type PageHeaderProps = {
    pageTile: string;
    isButton?: boolean;
    buttonClicked?: any;
    buttonTitle?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerContent: {
            display: 'flex',
            flexDirection: 'row',
            paddingBottom: '10px',
            width: '200px',
            justifyContent: 'end',
        },
        title: {
            flex: '1 1 100%',
            color: '#ABABAB',
            fontSize: '20px',
            width: '80%'
        },
        btn: {
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'row',
        },
        btnComponent: {
            backgroundColor: '#FF5A80',
            color: '#FFFFFF',
            fontSize: '16px',
            textTransform: 'none'
        },
        highZindex: {
            zIndex: 900
        },
        tmp: {
            zIndex: 1
        },
    }),
);

const PageHeader: FC<PageHeaderProps> = ({ pageTile, isButton, buttonClicked, buttonTitle }: PageHeaderProps) => {
    const classes = useStyles();
    const dispatch = useDispatchRoot();
    const [lstSteps, setLstSteps] = React.useState<IStep[]>(stepProcessData);
    const [createANewPenGuidelineStyle, setCreateANewPenGuidelineStyle] = React.useState<any>({
        top: 200,
        right: 10,
        zIndex: 800
    });
    const createANewPenMainContentRef = React.useRef<HTMLDivElement>(null);
    const location = useLocation();

    // const showAuthModal = () => (e: any) => {
    //     dispatch(setIsShowGuideline(true));
    // }

    React.useEffect(() => {
        const offsetLeft = window.innerWidth - (createANewPenMainContentRef.current?.offsetLeft ?? 1);
        const offsetWidth = createANewPenMainContentRef.current?.offsetWidth ?? 1;
        const move = offsetLeft - 130;
        const offsetTop = createANewPenMainContentRef.current?.offsetTop ? createANewPenMainContentRef.current?.offsetTop : 1;
        setCreateANewPenGuidelineStyle({
            top: offsetTop,
            left: move
        })
    }, [])

    // const handleOkInCreateANewPen = () => {
    //     dispatch(setCurrentProcess(AllStepsProcess.FillInNewPenForm));
    //     buttonClicked();
    // }
    return (
        <div className={classes.headerContent + ' farm-title'}>
            {/* <div className={classes.title}>
                <Typography id="tableTitle">
                    {pageTile}
                </Typography>
            </div> */}
            {/* {(location.pathname === routeNames.penEmpty && isShowGuideline && currentProcess === AllStepsProcess.CreateANewPen) && <GuideLine guideLineRenderProps={{
                currentProcess: currentProcess, title: '', lstSteps, style: createANewPenGuidelineStyle, modalText: 'Chọn nút "Tạo chuồng" để tạo một chuồng nuôi mới.',
                visible: isShowGuideline && currentProcess === AllStepsProcess.CreateANewPen, confirmLoading: true, handleCancel: () => { dispatch(setIsShowGuideline(false)) }, handleOk: handleOkInCreateANewPen
            }} />} */}
            <div className={classes.btn} ref={createANewPenMainContentRef}>
                {
                    isButton && (
                        <Button variant="contained" className={`${classes.btnComponent}`} onClick={buttonClicked}>
                            {buttonTitle}
                        </Button>
                    )
                }
            </div>
        </div >
    );
};

export default PageHeader;
