/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { FC, useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SquareLoader from "react-spinners/SquareLoader";
import { apiStreamingVideo } from '@api/streaming-video'
import { apiPig } from '@api/pig';
import { useResizeDetector } from 'react-resize-detector';
import DetailPig from '@components/pigs/detail';
import Modal from '@material-ui/core/Modal';
import farmServices from '@services/farm';
import userServices from '@services/user';
import { ICam } from '@type/cam';
import ModalHeader from '../../common/modal/modal-header';
import { v4 as uuidv4 } from 'uuid';
import CImageLoading from '../../video/CImageLoading';
import StreamAPI from '@src/src/app/api/camera/streaming.api';

type CamDetailProps = {
    camSelectedData: ICam;
    // uuId: string;
    backOnClick?: any;
    isBackOnClick: boolean;
    isEmptyPen?: boolean;
    penSelected?: any;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            width: '100%',
            // overflowY: 'scroll',
            // maxHeight: '750px'
        },
        penVideo: {
            width: '100%',
            // height: '100%'
        },
        spinnerLoadingVideo: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            marginTop: '23%',
            marginBottom: '23%'
        },
        spinnerLoadingAPI: {
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%'
        },
        img: {
            width: '100%'
        },
        back: {
            display: 'block',
            position: 'absolute',
            left: 10,
            top: 10,
            backgroundColor: '#00B2FF',
            color: 'white',
            fontSize: '12px',
            paddingLeft: '5px',
            paddingRight: '5px'
        },
        videoHelpText: {
            display: 'block',
            position: 'absolute',
            left: 10,
            top: 30,
            backgroundColor: '#FF5A80',
            color: 'white',
            fontSize: '12px',
            paddingLeft: '5px',
            paddingRight: '5px'
        },
        pointClicked: {
            display: 'block',
            position: 'absolute',
            backgroundColor: '#FF5A80',
            width: '20px',
            height: '20px',
            borderRadius: '100%'
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        modalContentCreate: {
            backgroundColor: 'white',
            width: '60%',
        }
    }),
);

const CamDetail: FC<CamDetailProps> = ({ camSelectedData, backOnClick, isBackOnClick, isEmptyPen, penSelected }: CamDetailProps) => {
    const classes = useStyles();
    const [uuId, updateUuid] = useState<string>(uuidv4())
    const [streamingVideoURL, setStreamingVideoURL] = useState<string>("");
    const [hover, setHover] = useState(false);
    const [cursorClicked, setCursorClicked] = useState(false);
    const [cursorX, setCursorX] = useState<number>(0);
    const [cursorY, setCursorY] = useState<number>(0);
    const [loadingVideo, setLoadingVideo] = useState(true);
    const [loadingAPI, setLoadingAPI] = useState(false);

    const [pigId, setPigId] = React.useState<string>('');

    const [openPigDetail, setOpenPigDetail] = useState(false);

    const user = userServices.get();
    const currentFarm: any = farmServices.getCurrentFarm() as any;

    const { width, height, ref } = useResizeDetector();

    useEffect(() => {
        //componentDidMount
        getURLStreaming();
        return () => {
            terminateStreamingVideoByUuId();
        };
    }, []);

    useEffect(() => {
        console.log('Reinit UUID')
        console.log(uuId)
    }, [uuId])

    const getURLStreaming = () => {
        console.log(uuId)
        setStreamingVideoURL(apiStreamingVideo.getStreamingVideoURL(camSelectedData.id, uuId));
    };

    const addCursorClickedEffect = (x: number, y: number) => {
        setCursorX(x);
        setCursorY(y);
        setCursorClicked(true);

        setTimeout(
            function () {
                setCursorClicked(false);
            }, 200
        );
    }

    const videoStreamingLoaded = () => {
        setLoadingVideo(false);
    }

    const highlightPositionOnClick = (e: any) => {
        console.log(e);
        addCursorClickedEffect(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
        setLoadingAPI(true);

        const payload = {
            client_uuid: uuId,
            x: e.nativeEvent.offsetX,
            y: e.nativeEvent.offsetY,
            width: width,
            height: height
        }

        apiStreamingVideo.highlightPosition(payload, camSelectedData.id)
            .then((res: any) => {
                setLoadingAPI(false);
                apiPig.getAll(res.pigId, camSelectedData.penId)
                    .then((res: any) => {
                        setPigId(res.id);
                        handleOpenPigDetail();
                    })
                    .catch((err) => {
                        console.log("err:" + err);
                    })
            })
            .catch((err) => {
                setLoadingAPI(false);
                console.log(err);
            });
    };

    const terminateStreamingVideoByUuId = () => {
        const payload = [uuId]

        // apiStreamingVideo.terminateByUuId(payload)
        //     .then((res) => {
        //         console.log(res);
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //     });
        console.log(camSelectedData.id);

        apiStreamingVideo.terminateRTCStreaming(camSelectedData.id, payload)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const onHover = () => {
        setHover(true);
    };

    const onLeave = () => {
        setHover(false);
    };

    const handleOpenPigDetail = () => {
        setOpenPigDetail(true);
    }

    const handleClosePigDetail = () => {
        setOpenPigDetail(false);
    }

    return (
        <div className={classes.content}>
            {
                <div className={classes.penVideo}>
                    {/* {
                        loadingVideo && (
                            <div className={classes.spinnerLoadingVideo}>
                                <ScaleLoader color={"#FF5A80"} loading={loadingVideo} />
                            </div>
                        )
                    } */}
                    <div
                        ref={ref}
                        onMouseEnter={onHover}
                        onMouseLeave={onLeave}
                        className="video-responsive"
                        style={{ position: 'relative' }}
                    >
                        {
                            loadingAPI && (
                                <div className={classes.spinnerLoadingAPI}>
                                    <SquareLoader color={"#FF5A80"} loading={loadingAPI} size={50} />
                                </div>
                            )
                        }
                        {
                            cursorClicked && (
                                <div className={classes.pointClicked} style={{ top: cursorY - 12, left: cursorX - 10 }} />
                            )
                        }
                        {
                            (hover && !loadingVideo) && (
                                <div className={classes.videoHelpText}>
                                    Click vào lợn để xem thông tin
                                </div>
                            )
                        }
                        {
                            isBackOnClick && (
                                <div className={classes.back} onClick={backOnClick}>{'<< Quay lại'}</div>
                            )
                        }
                        {
                            streamingVideoURL.length > 0 &&
                            // (
                            //     <img
                            //         onClick={highlightPositionOnClick}
                            //         src={streamingVideoURL}
                            //         className={classes.img}
                            //         onLoad={videoStreamingLoaded}
                            //         height={loadingVideo ? "" : "500px"}
                            //     />
                            // )
                            // <div className="cursor-pointer">
                            <CImageLoading
                                onClick={highlightPositionOnClick}
                                src={StreamAPI.getStream(camSelectedData.id)}
                                className="h-60"
                                isEmptyPen={isEmptyPen}
                                uuid={uuId}
                                // onClick={() =>
                                //     handleShowExpandMenu(true, item)
                                // }
                                isHiddenCamera={true}
                                idCamera={camSelectedData?.id}
                            />
                            // </div>
                        }
                    </div>
                </div>
            }

            <Modal
                open={openPigDetail}
                onClose={handleClosePigDetail}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={classes.modal}
            >
                <div className={classes.modalContentCreate}>
                    <ModalHeader title='Chi tiết lợn' closeButton={handleClosePigDetail} />
                    <DetailPig
                        dataRenderProps={{ pigId, pigAIId: '', user: { ...user, defaultFarm: currentFarm } }}
                        lastUpdate={penSelected?.last_update_date}
                    />
                </div>
            </Modal>
        </div>
    );
};

export default CamDetail;
