/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import './modal.scss';
import CancelIcon from '@material-ui/icons/Cancel';

interface IModal extends IToggleModal {
    title?: string
    children: JSX.Element
}

export interface IToggleModal {
    toggleModal: any
    isShowModal: boolean
    isHiddenHeader?: boolean
    className?: string
}

export const useToggleModal = (): any => {
    const [isShowModal, setIsShowModal] = React.useState(false);

    const toggleModal = (isShow: boolean) => (_: React.MouseEvent<HTMLElement>): void => {
        setIsShowModal(isShow);
    };

    return [isShowModal, toggleModal];
}

const ModalCustom: FC<IModal> = (props: IModal) => {
    const { title, children, isShowModal, toggleModal, isHiddenHeader } = props;

    return (
        <div id='wrapper-log' className={isShowModal ? 'show-modal' : undefined}>
            <div className="outside-log-box" onClick={toggleModal(false)} />
            <div className='log-box'>
                {!isHiddenHeader && (
                    <div className="log-box__header">
                        {title}

                        <div className="log-close__btn" onClick={toggleModal(false)}>
                            <CancelIcon style={{ color: '#FF0039' }} />
                        </div>
                    </div>
                )}
                <div className="log-box__content" id={props.className}>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default ModalCustom;
