/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import routesName from '@routes/route-names';

import pig from '@assets/pig-item.png';
import { IFarmCookiesData, IFarmData } from '@type/farm'
import { apiFarm } from '@api/farm';
import { apiUser } from '@api/user';
import farmService from '@services/farm';
import userServices from '@services/user';
import Modal from '@material-ui/core/Modal';
import ModalHeader from '../../common/modal/modal-header';
import FarmCreate from '../farm-create';
import { ILoginUser } from '@components/auth/auth.types';
import { AllStepsProcess } from '../../common/guidelines/guideline.types';
import { useDispatchRoot, useSelectorRoot } from '@src/src/app/redux/store';
import { changeSelectedFarm, fetchLstEmptyPens } from '@src/src/app/redux/controller/farm.slice';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            width: '40%',
            border: '1px solid #eee',
            borderRadius: '10px',
            marginBottom: '10%',
            paddingBottom: '20px'
        },
        title: {
            marginTop: '5%',
            marginBottom: '10%',
            fontSize: '24px',
            fontWeight: 400,
            color: '#FF5A80'
        },
        farmList: {
            width: '80%'
        },
        farm: {
            display: 'flex',
            flexDirection: 'row'
        },
        farmAvatar: {
            display: 'flex',
            flexDirection: 'row'
        },
        farmAvatarImg: {
            width: '40px',
            height: '40px',
            borderRadius: '100%',
            margin: '15px',
        },
        farmInfo: {
            display: 'flex',
            flexDirection: 'column',
            padding: '5px',
            width: '80%'
        },
        lineBreak: {
            width: '100%',
            height: '1px',
            backgroundColor: '#E5E5E5',
            marginBottom: '10px'
        },
        farmName: {
            color: '#3c4043',
            fontSize: '14px',
            fontWeight: 'bold'
        },
        farmNameEmpty: {
            color: '#00B1FF',
            fontSize: '14px',
            fontWeight: 'bold'
        },
        farmAddress: {
            color: '#5f6368',
            fontSize: '12px'
        },
        farmSelect: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            width: '20%',
            paddingBottom: '13px'
        },
        btn: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            width: '80%',
            marginTop: '5%'
        },
        btnCreateFarm: {
            backgroundColor: '#FF5A80',
            color: '#FFFFFF',
            fontSize: '16px',
            textTransform: 'none',
            paddingLeft: '35px',
            paddingRight: '35px',
            marginRight: '25px'
        },
        btnDone: {
            backgroundColor: '#FF5A80',
            color: '#FFFFFF',
            fontSize: '16px',
            textTransform: 'none',
            paddingLeft: '35px',
            paddingRight: '35px'
        },
        circularProgress: {
            marginLeft: 0,
            marginRight: '10px',
            color: 'white'
        },
        radio: {
            '&$checked': {
                color: '#FF5A80'
            }
        },
        checkbox: {
            '&$checked': {
                color: '#FF5A80'
            }
        },
        checked: {

        },
        formControlLabel: {
            color: '#3c4043',
            fontSize: '14px',
            paddingTop: '2px'
        },
        setDefaultFarm: {
            width: '80%'
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 800
        },
        modalContent: {
            backgroundColor: 'white',
            width: '50%'
        },
        highZindex: {
            zIndex: 900,
            backgroundColor: 'white'
        },
        highZindexBtn: {
            zIndex: 900,
            backgroundColor: '#fb8ca7'
        },
        highlightCheckboxDefaultFarm: {
            backgroundColor: '#fb8ca7'
        }
    }),
);

const SelectFarm: FC = () => {
    const classes = useStyles();
    const [farms, setFarms] = useState<Array<IFarmData | any>>([])
    const [isDefaultFarm, setIsDefaultFarm] = useState(false);
    const [farmSelected, setFarmSelected] = useState<IFarmData | any>();
    const [open, setOpen] = useState(false);
    const dispatch = useDispatchRoot();
    const { currentProcess, isShowGuideline } = useSelectorRoot(state => state.guideline)
    const history = useHistory();
    const user = userServices.get();

    useEffect(() => {
        console.log('user', user);

    }, [])

    // useEffect(() => {
    //     checkFarm();
    // }, [])

    useEffect(() => {
        if (farms.length > 0) {
            console.log(farms);


            const currentFarmCookies: IFarmCookiesData = {
                // address: farms[0].address,
                // id: farms[0].id,
                // name: farms[0].name,
                // ownerId: farms[0]?.ownerId
                address: farms[farms.length - 1].address,
                id: farms[farms.length - 1].id,
                name: farms[farms.length - 1].name,
                ownerId: farms[farms.length - 1].ownerId
            }
            farmService.setCurrentFarm(currentFarmCookies);
            const farm = farmService.getCurrentFarm();
            farms[0].pens && dispatch(fetchLstEmptyPens(farms[0].pens))
            farms[0] && dispatch(changeSelectedFarm(farms[0]))
            history.push(routesName.dashboard);
        }

    }, [farms])

    const checkFarm = async () => {
        const data = {
            offset: 0,
            size: 100,
            ownerId: user.id
        };
        console.log(data);

        try {
            const fetchFarm = await apiFarm.getAll(data) as any;
            console.log(fetchFarm.items);

            if (fetchFarm.items.length > 0) {

                const currentFarmCookies: IFarmCookiesData = {
                    address: fetchFarm.items[0].address,
                    id: fetchFarm.items[0].id,
                    name: fetchFarm.items[0].name,
                    ownerId: fetchFarm.items[0]?.ownerId
                }
                farmService.setCurrentFarm(currentFarmCookies);
                const farm = farmService.getCurrentFarm();
                fetchFarm.items[0].pens.length > 0 && dispatch(fetchLstEmptyPens(fetchFarm.items[0].pens))
                fetchFarm.items[0] && dispatch(changeSelectedFarm(fetchFarm.items[0]))
                history.push(routesName.dashboard);

            }
        } catch (err) {
            console.log("err:" + JSON.stringify(err));
        }
    }

    // const [lstSteps, setLstSteps] = React.useState<IStep[]>(stepProcessData);

    // const [guideLineStyle, setGuidelineStyle] = React.useState<any>({
    //     top: 20,
    //     // right: 10
    // });

    // const [selectFarmGuidelineStyle, setSelectFarmGuidelineStyle] = React.useState<any>({
    //     top: 200
    // });

    // const [createFarmGuidelineStyle, setCreateFarmGuidelineStyle] = React.useState<any>({
    //     firstTime: true,
    //     top: 200
    // });

    // const createFarmMainContentRef = useRef<any>(null); // HTMLDivElement
    // const selectFarmMainContentRef = useRef<any>(null);
    // const createFarmPopupContentRef = useRef<any>(null);

    // const showAuthModal = () => (e: any) => {
    //     dispatch(setIsShowGuideline(true));
    // }

    // const setPositionForWelcomeGuideline = () => {
    //     const rightDemension = createFarmMainContentRef.current?.offsetWidth ? createFarmMainContentRef.current?.offsetWidth / 2 : 1;
    //     const move = (createFarmMainContentRef.current?.offsetLeft + rightDemension) - window.innerWidth / 2;
    //     const offsetTop = createFarmMainContentRef.current?.offsetTop ? createFarmMainContentRef.current?.offsetTop : 1;
    //     const guidelineMaxHeight = 240;
    //     setGuidelineStyle({
    //         top: offsetTop - guidelineMaxHeight - 60,
    //         left: move
    //     })
    // }

    // const setPositionForCreateFarmGuideline = () => {
    //     if (createFarmGuidelineStyle?.firstTime === undefined) {
    //         return true;
    //     }
    //     const rightDemension = createFarmPopupContentRef.current?.offsetWidth ? createFarmPopupContentRef.current?.offsetWidth : 1;
    //     const move = (createFarmPopupContentRef.current?.offsetLeft);
    //     const offsetTop = createFarmPopupContentRef.current?.offsetTop ? createFarmPopupContentRef.current?.offsetTop : 1;
    //     const guidelineMaxHeight = 240;
    //     const offsetHeight = createFarmPopupContentRef.current?.offsetHeight ? createFarmPopupContentRef.current?.offsetHeight : 1;
    //     setCreateFarmGuidelineStyle({
    //         top: offsetTop + offsetHeight / 2 - guidelineMaxHeight / 2,
    //         left: move
    //     })
    //     return true;
    // }

    // useEffect(() => {
    //     const rightDemension = selectFarmMainContentRef.current?.offsetWidth ? selectFarmMainContentRef.current?.offsetWidth / 2 : 1;
    //     const move = rightDemension + 230;
    //     const offsetTop = selectFarmMainContentRef.current?.offsetTop ? selectFarmMainContentRef.current?.offsetTop : 1;
    //     setSelectFarmGuidelineStyle({
    //         top: offsetTop,
    //         left: move
    //     })
    //     setPositionForWelcomeGuideline();
    // }, [])

    // const startGuideline = () => {
    //     setTimeout(() => {
    //         showAuthModal()('');
    //     }, 800)
    // }

    useEffect(() => {
        getAllFarms();
    }, [user]);

    const getAllFarms = async (): Promise<void> => {

        const data = {
            offset: 0,
            size: 100,
            ownerId: user.id
        };

        console.log(user.id);

        try {
            const fetchFarm = await apiFarm.getAll(data) as any;
            setFarms(fetchFarm.items);
            console.log('====================================');
            console.log(fetchFarm.items);
            console.log('====================================');
            // if (fetchFarm.items.length === 0) {
            //     startGuideline()
            // }
        } catch (err) {
            console.log("err:" + JSON.stringify(err));
        }
    };

    const setDefaultFarm = async (): Promise<void> => {
        const data = {
            defaultFarm: farmSelected.id
        };

        const userData = await apiUser.updateUser(user.id, data) as ILoginUser;
        userServices.set(userData);
        history.push(routesName.dashboard);
    }

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChangeDefaultFarm = () => {
        setIsDefaultFarm(!isDefaultFarm);
    }

    const handleChangeFarmSelected = (evt: ChangeEvent<HTMLInputElement>) => {
        const { value } = evt.target;
        farms.filter(farm => farm.id == value).map(filteredFarm => (
            setFarmSelected(filteredFarm)
        ));
    }

    const doSubmit = () => {
        // const currentFarmCookies: IFarmCookiesData = {
        //     address: farmSelected.address,
        //     id: farmSelected.id,
        //     name: farmSelected.name,
        //     ownerId: farmSelected?.ownerId
        // }
        // farmService.setCurrentFarm(currentFarmCookies);
        // const farm = farmService.getCurrentFarm();
        // console.log(farmSelected)
        // farmSelected.pens && dispatch(fetchLstEmptyPens(farmSelected.pens))
        // farmSelected && dispatch(changeSelectedFarm(farmSelected))
        // if (isDefaultFarm) {
        //     setDefaultFarm();
        // } else {
        const currentFarmCookies: IFarmCookiesData = {
            address: farms[0].address,
            id: farms[0].id,
            name: farms[0].name,
            ownerId: farms[0]?.ownerId
        }
        farmService.setCurrentFarm(currentFarmCookies);
        const farm = farmService.getCurrentFarm();
        farms[0].pens && dispatch(fetchLstEmptyPens(farms[0].pens))
        farms[0] && dispatch(changeSelectedFarm(farms[0]))
        history.push(routesName.dashboard);
    }

    // const handleOkInSelectFarmProcess = () => {
    //     dispatch(setCurrentProcess(AllStepsProcess.ChooseDefaultFarm))
    // }

    // const handleOkInChooseDefaultFarm = () => {
    //     doSubmit();
    //     dispatch(setCurrentProcess(AllStepsProcess.DropdownFarm))
    //     history.push('tong-quan')
    // }

    // const handleOkInCreateANewFarm = () => {
    //     dispatch(setCurrentProcess(AllStepsProcess.SelectFarm))
    // }


    return (
        <div className={`${classes.root} main-content`}>
            {/* <div> */}
            {/* {(currentProcess === AllStepsProcess.Welcome) && <GuideLine guideLineRenderProps={{
                    currentProcess: currentProcess, title: 'Chào mừng bạn đến với PIGMAN!', lstSteps, style: guideLineStyle, arrowDirection: 'downArrow', modalText: "Bạn chưa có trang trại nào, hãy tạo trang trại mới ngay.",
                    visible: isShowGuideline && currentProcess === AllStepsProcess.Welcome, confirmLoading: true, handleCancel: () => { dispatch(setIsShowGuideline(false)) }, handleOk: () => { dispatch(setCurrentProcess(AllStepsProcess.CreateANewFarm)); setOpen(true); }
                }} />}
                {(currentProcess === AllStepsProcess.SelectFarm) && <GuideLine guideLineRenderProps={{
                    currentProcess: currentProcess, title: '', lstSteps, style: selectFarmGuidelineStyle, arrowDirection: 'downArrow', modalText: "Bạn chưa có trang trại nào, hãy tạo trang trại mới để có thể tiếp tục.",
                    visible: isShowGuideline && currentProcess === AllStepsProcess.SelectFarm, confirmLoading: true, handleCancel: () => { dispatch(setIsShowGuideline(false)) }, handleOk: handleOkInSelectFarmProcess
                }} />} */}
            {/* </div> */}
            {
                (farms.length != 0) && (<div className={classes.title}>
                    Danh sách trang trại
                </div>)
            }
            <div className={classes.farmList}>
                <RadioGroup
                    value={farmSelected}
                    onChange={handleChangeFarmSelected}
                >
                    {
                        farms.map((farm, index) => {
                            return (
                                <div key={index}>
                                    <div className={classes.farm}>
                                        <div className={classes.farmAvatar}>
                                            <img src={pig} alt="pig" className={classes.farmAvatarImg} />
                                        </div>
                                        <div className={classes.farmInfo}>
                                            <div className={classes.farmName}>
                                                {farm.name}
                                            </div>
                                            <div className={classes.farmAddress}>
                                                {farm.address}
                                            </div>
                                        </div>
                                        {/* <div className={classes.farmSelect}>
                                            <FormControlLabel
                                                value={farm.id}
                                                control={<Radio classes={{ root: classes.radio, checked: classes.checked }} />}
                                                label=""
                                                labelPlacement="start"
                                            />
                                        </div> */}
                                    </div>
                                    <div className={classes.lineBreak} />
                                </div>
                            );
                        })
                    }
                    {
                        (farms.length == 0) && (
                            <div>
                                <div className={classes.farm}>
                                    <div className={classes.farmInfo}>
                                        <div className={classes.farmNameEmpty}>
                                            Bạn chưa có trang trại nào. Hãy tạo trang trại ngay!
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.lineBreak} />
                            </div>
                        )
                    }
                </RadioGroup>
            </div>
            <div className={`${classes.setDefaultFarm} ${(isShowGuideline && currentProcess === AllStepsProcess.ChooseDefaultFarm) && classes.highlightCheckboxDefaultFarm}`}>
                {
                    // (farms.length != 0) && (<FormControlLabel
                    //     control={
                    //         <Checkbox
                    //             checked={isDefaultFarm}
                    //             value=""
                    //             onChange={handleChangeDefaultFarm}
                    //             icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    //             checkedIcon={<CheckBoxIcon fontSize="small" />}
                    //             classes={{
                    //                 root: classes.checkbox,
                    //                 checked: classes.checked,
                    //             }}
                    //         />
                    //     }
                    //     label={<Typography className={`${classes.formControlLabel} `}>Đặt trang trại đã chọn làm mặc định</Typography>}
                    // />)
                }
            </div>
            <div className={classes.btn}>
                {user?.role !== 'MANAGER' &&
                    <Button variant="contained" onClick={handleOpen} className={`${classes.btnCreateFarm} ${(isShowGuideline && (currentProcess === AllStepsProcess.Welcome)) && classes.highZindexBtn}`}>
                        Tạo trang trại
                    </Button>
                }
                {
                    (farms.length != 0) && (
                        <Button variant="contained" onClick={doSubmit} className={classes.btnDone} >
                            Tiếp tục
                        </Button>
                    )
                }
            </div>
            {open && <div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className={classes.modal}
                    style={{ zIndex: 799 }}
                >
                    <div className={classes.modalContent} >
                        <ModalHeader title="Tạo trang trại" closeButton={handleClose} />
                        <FarmCreate onClose={handleClose} onRefresh={getAllFarms} isUpdate={false} />
                    </div>
                </Modal>
                {/* {(open && currentProcess === AllStepsProcess.CreateANewFarm && setPositionForCreateFarmGuideline()) && <GuideLine guideLineRenderProps={{
                    currentProcess: currentProcess, title: '', lstSteps, style: createFarmGuidelineStyle, arrowDirection: 'leftArrow', modalText: 'Nhập "Tên trang trại" và "Địa chỉ" vào popup bên cạnh.',
                    visible: open && isShowGuideline && currentProcess === AllStepsProcess.CreateANewFarm, confirmLoading: true, handleCancel: () => { dispatch(setIsShowGuideline(false)) }, handleOk: handleOkInCreateANewFarm
                }} />} */}
            </div>}
        </div>
    );
}

export default SelectFarm;