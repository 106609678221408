/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { ChangeEvent, FC, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';

import CircularProgress from '@material-ui/core/CircularProgress';
import { apiFoodSupplier } from '@api/food-supplier';
import farmService from '@services/farm';
import userServices from '@services/user';
import { IFoodSupplierData } from '../../types/food-supplier';
import openNotification, { NotificationType } from '../common/notification/Notification';
import { Button, notification } from 'antd';

type FoodSupplierCreateProps = {
    onClose: any;
    onRefresh: any;
    foodSupplier: IFoodSupplierData;
    isUpdate: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        notchedOutline: {
            borderWidth: "1px",
            borderColor: "#FFDADE !important"
        },
        btn: {
            display: 'flex',
            justifyContent: 'end',
            gap: '10px'
        },
        btnSave: {
            fontSize: '16px',
            textTransform: 'none',
        },
        btnCanel: {
            fontSize: '16px',
            textTransform: 'none',
        },
        circularProgress: {
            marginLeft: 0,
            marginRight: '10px',
            color: 'white'
        },
        errMsg: {
            color: 'red',
        },
    }),
);

const customStyles = {
    control: (base: any, state: any) => ({
        ...base,
        borderColor: "#FFDADE !important"
    })
};

const FoodSupplierCreate: FC<FoodSupplierCreateProps> = ({ onClose, onRefresh, foodSupplier, isUpdate }: FoodSupplierCreateProps) => {
    const classes = useStyles();
    const [name, setName] = useState<string>(foodSupplier?.name);
    const [address, setAddress] = useState<string>(foodSupplier?.address);
    const [farmId, setFarmId] = useState<string>(farmService.getCurrentFarm().id);
    const [isLoading, setIsLoading] = useState(false);
    const [err, setErr] = useState<any>();
    const user = userServices.get();
    const userId = user.id;

    const doSave = () => {
        const data = {
            name,
            address,
            farmId
        }

        setIsLoading(true);

        if (isUpdate) {
            apiFoodSupplier.updateFoodSupplier(foodSupplier.id, data)
                .then((res) => {
                    onRefresh();
                    onClose();
                    openNotification(NotificationType.Info, 'topRight', `PIGMAN`, `Cập nhật nhà cung cấp thành công.`);
                })
                .catch((err) => {
                    setIsLoading(false);
                    setErr(JSON.stringify(err).toString());
                    console.log("err:" + JSON.stringify(err));
                });
        } else {
            apiFoodSupplier.createFoodSupplier(data)
                .then((res) => {
                    onRefresh();
                    onClose();
                    openNotification(NotificationType.Info, 'topRight', `PIGMAN`, `Thêm mới nhà cung cấp thành công.`);
                })
                .catch((err) => {
                    setIsLoading(false);
                    setErr(JSON.stringify(err).toString());
                    console.log("err:" + JSON.stringify(err));
                });
        }
    };

    const handleInputChangeEventName = (evt: ChangeEvent<HTMLInputElement>) => {
        const { value } = evt.target;
        setName(value);
    };

    const handleInputChangeAddress = (evt: ChangeEvent<HTMLInputElement>) => {
        const { value } = evt.target;
        setAddress(value);
    };

    return (
        <div>
            <Grid container>
                <Grid item xs={10} sm={4}>
                    <Box display="flex" justifyContent="flex-start" m={1} ml={2} p={1}>
                        Tên nhà cung cấp <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box display="flex" justifyContent="flex-start" p={1} >
                        <TextField
                            fullWidth={true}
                            id="name"
                            name="name"
                            label=""
                            variant="outlined"
                            size="small"
                            placeholder="Nhập tên nhà cung cấp"
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                            value={name}
                            onChange={handleInputChangeEventName}
                        />
                    </Box>
                    <Box display="flex" justifyContent="flex-start" ml={1}>
                        <span className={classes.errMsg}>{err}</span>
                    </Box>
                </Grid>
                <Grid item xs={10} sm={4}>
                    <Box display="flex" justifyContent="flex-start" m={1} ml={2} p={1}>
                        Địa chỉ <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box display="flex" justifyContent="flex-start" p={1} >
                        <TextField
                            fullWidth={true}
                            id="startDate"
                            name="startDate"
                            label=""
                            variant="outlined"
                            size="small"
                            placeholder="Nhập địa chỉ"
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                            value={address}
                            onChange={handleInputChangeAddress}
                        />
                    </Box>
                </Grid>
                <Grid item xs={10} sm={4}>
                    <Box display="flex" justifyContent="flex-start" m={1} p={1}>

                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box display="flex" justifyContent="flex-end" p={1} mb={5} className={classes.btn} >
                        <Button onClick={onClose}>
                            Hủy bỏ
                        </Button>
                        <Button
                            onClick={doSave}
                            disabled={(name && address) ? false : true}
                            type="primary" htmlType="submit">
                            {
                                isLoading && (
                                    <CircularProgress className={classes.circularProgress} size={20} />
                                )
                            }
                            {isUpdate ? "Cập nhật" : "Lưu"}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

export default FoodSupplierCreate;
