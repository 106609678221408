import React, { FC, useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { CaretDownOutlined, DownloadOutlined, UploadOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

import './style.farmList.scss';
import { Button, ConfigProvider, Form, Input, Modal, notification, Select, Space, Spin, Table, Tooltip, Upload } from 'antd';
import Search from 'antd/lib/input/Search';
import moment from 'moment';
import * as XLSX from 'xlsx';
import TextArea from 'antd/lib/input/TextArea';
import { error } from 'console';
import temperature from '../profile/temperature/temperature';
import { AiOutlineEye } from 'react-icons/ai';
import user from '../../services/user';
// import { UploadOutlined } from '@ant-design/icons';

// Các cột của bảng
const columns = [
    {
        title: 'STT',
        dataIndex: 'key',
    },
    {
        title: 'Tiêu đề',
        dataIndex: 'title',
    },
    {
        title: 'Nội dung',
        dataIndex: 'content',
    },
    {
        title: 'Thời gian đăng tin',
        dataIndex: 'createdAt',
        width: 200,
    },
    {
        title: 'Ảnh',
        dataIndex: 'image',
        width: 200,
    },
    {
        title: 'Thao tác',
        dataIndex: 'action',
        width: 100,
    }
];

const Option = Select.Option;
const NewPigs: FC = () => {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1); // Trang hiện tại
    const [pageSize, setPageSize] = useState(10); // Số bệnh lợn trên một trang
    const [filter, setFilter] = useState(''); // dùng để tìm kiếm
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(false); // Trạng thái loading
    const [lstFarm, setLstFarm] = useState<any>([]);
    const [farmId, setFarmId] = useState<any>(null);
    const [loadingButton, setLoadingButton] = useState(false);
    const [form] = Form.useForm(); // Form thêm bệnh lợn
    const [formEdit] = Form.useForm(); // Form sửa bệnh lợn
    const [isShowModalAdd, setIsShowModalAdd] = useState<any>(false);
    const [item, setItem] = useState<any>(null);
    const [isShowModalEdit, setIsShowModalEdit] = useState<any>(false);

    // routers/route-names.tsx có: profile_farmList: '/profile/quan-ly-trang-trai/:userID'
    // Phần sau dấu hai chấm là tên biến (có thể thay đổi được), ở đây được đặt là "userID",
    // thế nên useParams() trả về một object có field "userID" với value là ID của user
    const id: any = useParams();

    useEffect(() => {
        getAllFarm();
        setLoading(true);
        fetchData(1);
    }, [farmId]);

    const fetchData = (page: any) => {
        let url = ''
        if (farmId) {
            url = `https://sit.api.pigman.com.vn/behaviours10k?size=${pageSize}&offset=${pageSize * (page - 1)}&farmId=${farmId}`
        }
        else {
            url = `https://sit.api.pigman.com.vn/pigArticle`
        }
        console.log('====================================');
        console.log(url);
        console.log('====================================');
        axios
            .get(url)
            .then((response) => {
                console.log(response.data.data);
                // let count = pageSize * (page - 1);

                // Sắp xếp theo thời gian tạo bài viết mới nhất
                const sortedData = response.data.data.sort((a: any, b: any) => {
                    return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
                });

                setTotalRecords(0)
                setData(
                    sortedData.map((obj: any, index: any) => {
                        const imageSrc = `https://sit.api.pigman.com.vn/pigArticle/image/{imageName}?imageName=${obj.image}`;
                        console.log(imageSrc);

                        return {
                            key: index + 1, // STT (cột đầu tiên)
                            title: obj.title || 'Chưa có thông tin', // Tên trang trại 
                            content: obj.content || 'Chưa có thông tin',
                            description: obj.description || 'Chưa có thông tin',
                            createdAt: obj.createdAt ? (new Date(obj.createdAt)).toLocaleString('nl-BE') : 'Chưa có thông tin',
                            image:
                                (
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                        <img
                                            src={imageSrc}
                                            alt="Ảnh"
                                            style={{ width: '200px' }}
                                        />
                                    </div>
                                ),
                            action: (
                                <Space key={index} style={{ alignItems: 'end' }}>
                                    {/* <Tooltip title="Xem">
                                        <AiOutlineEye style={{ fontSize: 18 }} onClick={() => handleView(item)} />
                                    </Tooltip> */}
                                    <Tooltip title="Sửa">
                                        <EditOutlined onClick={() => handleEdit(obj)} rev={undefined} />
                                    </Tooltip>
                                    <Tooltip title="Xóa">
                                        <DeleteOutlined onClick={() => handleDelete(obj)} rev={undefined} />
                                    </Tooltip>

                                </Space>
                            ),

                        };
                    }),
                );

            })
            .then(() => {
                setLoading(false);
            })
            // catch lỗi khi người dùng nhấn "quản lý trang trại" thẳng luôn vì khi đó URL sẽ là "/profile/quan-ly-trang-trai/:userID", không có ID của user
            .catch(() => setData([]));
    }


    // Hàm thực hiện khi thay đổi trang hiện tại
    const handleChangePage = (page: number, pageSize?: number) => {
        // Mở loading
        setLoading(true);

        // Gán giá trị trang hiện tại và số bản ghi trên một trang ngay lập tức
        setCurrentPage(page);
        pageSize && setPageSize(pageSize);

        // Đẩy lên đầu trang
        window.scrollTo({ top: 0, behavior: 'smooth' });

        setTimeout(() => {
            setLoading(false);
            fetchData(page);
        }, 1000);
    };

    // Hàm thực hiện thay đổi các giá trị trong phân trang
    const paginationConfig = {
        current: currentPage,
        pageSize,
        total: totalRecords,
        onChange: handleChangePage,
        showSizeChanger: false,
        // showTotal: false,
        pageSizeOptions: ['10', '20', '30', '50'],
    };

    // Hàm thực hiện gán classname cho các dòng của bảng
    const getRowClassName = (record: unknown, index: number) => {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
    };

    const onSearch = () => {
        console.log('searching');
    };

    const getAllFarm = async () => {

        await axios.get(`https://sit.api.pigman.com.vn/admin/getAllFarm/getAllFarmFromAdmin`)
            .then((response) => {
                console.log(response.data.data);
                setLstFarm(response.data.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleChangeFarmList = (value: any) => {
        setFarmId(value);
    }
    const sortData = filter.length === 0 ? data : data.filter((item: any) => item.farmName.toLowerCase().includes(filter.toLowerCase()));
    const exportToExcel = async () => {
        setLoadingButton(true);
        await axios.get(`https://sit.api.pigman.com.vn/behaviours10k?size=100000&offset=0`)
            .then((response) => {
                console.log(response.data.data[0].items);
                let count = 0
                setTotalRecords(response.data.data[1].total)
                const data = response.data.data[0].items.map((obj: any) => {
                    count += 1;
                    return {
                        key: count, // STT (cột đầu tiên)
                        farmName: obj.farm?.name || 'Không xác định', // Tên trang trại 
                        penName: obj.pen?.name || 'Không xác định',
                        pigAIId: obj.pigAIId || 'Không xác định',
                        startTime: obj.startTime ? (new Date(obj.startTime)).toLocaleString('nl-BE') : 'Không xác định',
                        endTime: obj.endTime ? (new Date(obj.endTime)).toLocaleString('nl-BE') : 'Không xác định',
                        runningTimes: obj.runningTimes || '0',
                        standingTimes: obj.standingTimes || '0',
                        lieDownTimes: obj.lieDownTimes || '0',
                        eatingTimes: obj.eatingTimes || '0',
                    };
                });
                // Map over sortData to create a new array with the desired column names
                const dataWithVietnameseKeys = data.map((item: any) => ({
                    'STT': item.key,
                    'Tên trang trại': item.farmName,
                    'Tên chuồng': item.penName,
                    'ID lợn phát hiện bởi AI': item.pigAIId,
                    'Thời gian bắt đầu theo dõi': item.startTime,
                    'Thời gian kết thúc theo dõi': item.endTime,
                    'Thời gian đi (s)': item.runningTimes,
                    'Thời gian đứng (s)': item.standingTimes,
                    'Thời gian nằm (s)': item.lieDownTimes,
                    'Thời gian ăn (s)': item.eatingTimes,
                }));

                const ws = XLSX.utils.json_to_sheet(dataWithVietnameseKeys);
                ws['!cols'] = [
                    { width: 5 },
                    { width: 30 },
                    { width: 30 },
                    { width: 30 },
                    { width: 30 },
                    { width: 30 },
                    { width: 15 },
                    { width: 15 },
                    { width: 15 },
                    { width: 15 },

                ];
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
                XLSX.writeFile(wb, "Danh sach hanh vi cua Lon.xlsx");
                setLoadingButton(false);
            })

    };

    const handleAdd = () => {
        form.resetFields();

        setIsShowModalAdd(true);
    }

    const handleFinishForm = async (values: any) => {
        console.log(values);

        const formData = new FormData();
        formData.append('title', values.title);
        formData.append('content', values.content);
        formData.append('description', values.description);
        formData.append('image', values.image.file);

        await axios.post(`https://sit.api.pigman.com.vn/pigArticle`, formData)
            .then((response) => {
                console.log(response.data);
                fetchData(1);
                setIsShowModalAdd(false);

                notification.success({
                    message: 'Thành công',
                    description: 'Thêm tin tức thành công!'

                } as any);
            })
            .catch((error) => {
                console.log(error);
            });

    }

    const handleView = (item: any) => {
        console.log(item);
    }

    const handleEdit = (item: any) => {
        console.log(item);

        formEdit.resetFields();

        setItem(item);
        formEdit.setFieldsValue({
            title: item.title || '',
            content: item.content || '',
            description: item.description || '',
            image: '',
        });

        console.log(formEdit.getFieldsValue());


        setIsShowModalEdit(true);

    }

    const handleFinishEditForm = async (values: any) => {
        console.log(values);

        const req = {
            title: values.title,
            content: values.content,
            description: values.description,
        }

        await axios.put(`https://sit.api.pigman.com.vn/pigArticle/{id}?id=${item.id}`, req)
            .then((response) => {
                console.log(response.data);
                fetchData(1);
                setIsShowModalEdit(false);

                notification.success({
                    message: 'Thành công',
                    description: 'Sửa tin tức thành công!'

                } as any);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleDelete = (item: any) => {
        console.log(item);

        Modal.confirm({
            title: 'Xác nhận xóa',
            content: 'Bạn có chắc chắn muốn xóa tin tức này?',
            okText: 'Xóa',
            cancelText: 'Hủy',
            onOk: async () => {
                await axios.delete(`https://sit.api.pigman.com.vn/pigArticle/{id}?id=${item.id}`)
                    .then((response) => {
                        console.log(response.data);
                        fetchData(1);
                        notification.success({
                            message: 'Thành công',
                            description: 'Xóa tin tức thành công!'

                        } as any);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        });


    }




    return (
        <>
            <div className="news-pig-body">
                <div className="button-farmList">
                    <p className="farmList-h2">Bản ghi tin tức</p>
                    <div className="search-and-sort">
                        {/* <Search placeholder="Tìm kiếm theo trang trại" onSearch={onSearch}
                            onChange={(event) => setFilter(event.target.value)}
                            enterButton
                            allowClear
                        /> */}
                        <Button onClick={handleAdd}>Thêm tin tức</Button>

                        {/* <Select
                            className='select-behaviour-pig'
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            filterOption={(input: any, option: any) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            suffixIcon={<CaretDownOutlined rev={undefined} />}
                            onChange={handleChangeFarmList}
                            placeholder="Lọc theo trang trại"
                        >
                            <Option key={0} value={''} title={'Tất cả trang trại'}>{'Tất cả trang trại'}</Option>
                            {lstFarm?.map((item: any, index: any) => (
                                <Option key={index} value={item.id} title={item.name}>{item.name}</Option>
                            ))}
                        </Select> */}
                    </div>
                </div>
                <Spin spinning={loading} delay={500} tip="Đang lấy dữ liệu...">
                    <div className="table-farmList">
                        <Table
                            dataSource={sortData}
                            columns={columns}
                            rowClassName={getRowClassName}
                            pagination={paginationConfig}
                        />
                        {/* <div className='button-excel'>
                            <Button loading={loadingButton} disabled={(sortData && sortData.length > 0) ? false : true} onClick={exportToExcel} type="primary" icon={<DownloadOutlined />}> Xuất báo cáo</Button>
                        </div> */}
                    </div>
                </Spin>

                <Modal
                    title="Thêm tin tức"
                    visible={isShowModalAdd}
                    onOk={() => {
                        form.submit();
                    }}
                    onCancel={() => {
                        setIsShowModalAdd(false);
                    }}
                    okText="Thêm"
                    cancelText="Hủy"
                    width={1000}
                    style={{
                        maxWidth: '1000px',
                    }}
                >
                    <Form
                        form={form}
                        onFinish={handleFinishForm}
                    >
                        <Form.Item
                            label="Tiêu đề"
                            name="title"
                            rules={[{ required: true, message: 'Vui lòng nhập tiêu đề!' }]}
                        >
                            <TextArea
                                rows={2}
                            />  
                        </Form.Item>
                        <Form.Item
                            label="Nội dung"
                            name="content"
                            rules={[{ required: true, message: 'Vui lòng nhập nội dung!' }]}
                        >
                            <TextArea
                                rows={8}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Link bài viết"
                            name="description"
                            rules={[{ required: true, message: 'Vui lòng chọn mô tả!' }]}
                        >
                            <TextArea
                                rows={2}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Ảnh"
                            name="image"
                            rules={[{ required: true, message: 'Vui lòng nhập ảnh!' }]}
                        >
                            <Upload
                                name="image"
                                listType="picture"
                                beforeUpload={() => false} // Prevent automatic upload
                                accept="image/*"
                                maxCount={1}
                            >
                                <Button icon={<UploadOutlined />}>Chọn ảnh</Button>
                            </Upload>
                        </Form.Item>

                    </Form>
                </Modal>

                <Modal
                    title="Sửa tin tức"
                    visible={isShowModalEdit}
                    onOk={() => {
                        formEdit.submit();
                    }}
                    onCancel={() => {
                        setIsShowModalEdit(false);
                    }}
                    okText="Sửa"
                    cancelText="Hủy"
                    width={1000}
                    style={{
                        maxWidth: '1000px',
                    }}
                >
                    <Form
                        form={formEdit}
                        onFinish={handleFinishEditForm}
                    >
                        <Form.Item
                            label="Tiêu đề"
                            name="title"
                            rules={[{ required: true, message: 'Vui lòng nhập tiêu đề!' }]}
                        >
                            <TextArea
                                rows={2}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Nội dung"
                            name="content"
                            rules={[{ required: true, message: 'Vui lòng nhập nội dung!' }]}
                        >
                            <TextArea
                                rows={8}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Link bài viết"
                            name="description"
                            rules={[{ required: true, message: 'Vui lòng chọn mô tả!' }]}
                        >
                            <TextArea
                                rows={2}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Ảnh"
                            name="image"
                        // rules={[{ required: true, message: 'Vui lòng nhập ảnh!' }]}
                        >
                            <Upload
                                name="image"
                                listType="picture"
                                beforeUpload={() => false} // Prevent automatic upload
                                accept="image/*"
                                fileList={
                                    item && item.image ? [
                                        {
                                            uid: '-1',
                                            name: 'image.png',
                                            status: 'done',
                                            url: `https://sit.api.pigman.com.vn/pigArticle/image/{imageName}?imageName=${item.image}`
                                        }
                                    ] : []
                                }
                                maxCount={1}
                                disabled={true}
                            >
                                {/* <Button icon={<UploadOutlined />}>Chọn ảnh</Button> */}
                            </Upload>
                        </Form.Item>

                    </Form>
                </Modal>

            </div>
        </>
    );
};

export default NewPigs;
