/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from 'react-select';
import { apiEvent } from '@api/event';
import { apiEventType } from '@api/event-type';
import { apiPen } from '@api/pen';
import { IEventData } from '@type/event';
import farmService from '@services/farm';
import userServices from '@services/user';
import openNotification, { NotificationType } from '../../common/notification/Notification';

type EventCreateProps = {
    onClose: any;
    onRefresh: any;
    eventData: IEventData;
    isUpdate: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        notchedOutline: {
            borderWidth: "1px",
            borderColor: "#FFDADE !important"
        },
        btnSave: {
            backgroundColor: '#FF5A80',
            color: '#FFFFFF',
            fontSize: '16px',
            textTransform: 'none',
            paddingLeft: '40px',
            paddingRight: '40px'
        },
        btnCanel: {
            backgroundColor: '#FFDADE',
            color: '#FF5A80',
            fontSize: '16px',
            textTransform: 'none',
            paddingLeft: '40px',
            paddingRight: '40px',
            marginLeft: '40px'
        },
        circularProgress: {
            marginLeft: 0,
            marginRight: '10px',
            color: 'white'
        }
    }),
);

const customStyles = {
    control: (base: any, state: any) => ({
        ...base,
        borderColor: "#FFDADE !important"
    })
};

const EventCreate: FC<EventCreateProps> = ({ onClose, onRefresh, eventData, isUpdate }: EventCreateProps) => {
    const classes = useStyles();
    const [name, setName] = useState<string>(eventData?.name);
    const [startDate, setStartDate] = useState<string>(eventData?.startDate);
    const [endDate, setEndDate] = useState<string>(eventData?.endDate);
    const [penIdsDisplay, setPenIdsDisplay] = useState<Array<any>>(
        eventData?.pens.map(pen => {
            return { value: pen.id, label: pen.name }
        })
    );
    const [penIds, setPenIds] = useState<Array<any>>(
        eventData?.pens.map(pen => {
            return pen.id;
        })
    );
    const [eventTypeId, setEventTypeId] = useState<string>(eventData?.eventType.id);
    const [eventTypeName, setEventTypeName] = useState<string>(eventData?.eventType.name);
    const [desc, setDesc] = useState<string>(eventData?.desc);
    const [eventTypes, setEventTypes] = useState<Array<any>>([]);
    const [farmIds, setFarmIds] = useState<Array<any>>([farmService.getCurrentFarm().id]);
    const [pens, setPens] = useState<Array<any>>([]);
    const [isLoading, setIsLoading] = useState(false);

    const currentFarm = farmService.getCurrentFarm();
    const user = userServices.get();
    const userId = user.id;

    useEffect(() => {
        getAllEventTypes();
        getAllPens();
    }, []);

    const getAllEventTypes = () => {
        const params = {
            farmId: currentFarm.id
        }

        apiEventType.getAll(params)
            .then((res: any) => {
                const eventTypeList: any = [];
                res.map((eventType: any) => {
                    eventTypeList.push({ value: eventType.id, label: eventType.name });
                })
                setEventTypes(eventTypeList);
            })
            .catch((err) => {
                console.log("err:" + JSON.stringify(err));
            })
    }

    const getAllPens = () => {
        const params = {
            offset: 0,
            size: 1000,
            farmId: currentFarm.id
        }

        apiPen.getAllPen(params)
            .then((res: any) => {
                const penList: any = [];
                res.items.map((pen: any) => {
                    penList.push({ value: pen.id, label: pen.name });
                });
                setPens(penList);
            })
            .catch((err) => {
                console.log("err:" + JSON.stringify(err));
            })
    }

    const doSave = () => {
        const data = {
            name,
            startDate,
            endDate,
            farmIds,
            penIds,
            userId,
            desc,
            eventTypeId
        }

        setIsLoading(true);

        if (isUpdate) {
            apiEvent.updateEvent(eventData.id, data)
                .then((res) => {
                    onRefresh();
                    onClose();
                    openNotification(NotificationType.Info, 'topRight', `PIGMAN`, `Cập nhật sự kiện thành công.`);
                })
                .catch((err) => {
                    setIsLoading(false);
                    console.log("err:" + JSON.stringify(err));
                });
        } else {
            apiEvent.createEvent(data)
                .then((res) => {
                    onRefresh();
                    onClose();
                    openNotification(NotificationType.Info, 'topRight', `PIGMAN`, `Tạo sự kiện thành công.`);
                })
                .catch((err) => {
                    setIsLoading(false);
                    console.log("err:" + JSON.stringify(err));
                });
        }
    };

    const handleInputChangeEventName = (evt: ChangeEvent<HTMLInputElement>) => {
        const { value } = evt.target;
        setName(value);
    };

    const handleInputChangeStartDate = (evt: ChangeEvent<HTMLInputElement>) => {
        const { value } = evt.target;
        setStartDate(value);
    };

    const handleInputChangeEndDate = (evt: ChangeEvent<HTMLInputElement>) => {
        const { value } = evt.target;
        setEndDate(value);
    };

    const handleInputChangePenIds = (selectedOptions: any) => {
        const penIdsTemp: any = [];
        selectedOptions.map((option: any) => {
            penIdsTemp.push(option.value);
        })
        setPenIds(penIdsTemp);
    };

    const handleInputChangeEventType = (selectedOption: any) => {
        const { value } = selectedOption;
        setEventTypeId(value);
    };

    const handleInputChangeDesc = (evt: ChangeEvent<HTMLInputElement>) => {
        const { value } = evt.target;
        setDesc(value);
    };

    return (
        <div>
            <Grid container>
                <Grid item xs={10} sm={4}>
                    <Box display="flex" justifyContent="flex-end" m={1} p={1}>
                        Tên sự kiện
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box display="flex" justifyContent="flex-start" p={1} width="70%">
                        <TextField
                            fullWidth={true}
                            id="name"
                            name="name"
                            label=""
                            variant="outlined"
                            size="small"
                            placeholder="Nhập tên sự kiện"
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                            value={name}
                            onChange={handleInputChangeEventName}
                        />
                    </Box>
                </Grid>
                <Grid item xs={10} sm={4}>
                    <Box display="flex" justifyContent="flex-end" m={1} p={1}>
                        Ngày bắt đầu
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box display="flex" justifyContent="flex-start" p={1} width="70%">
                        <TextField
                            fullWidth={true}
                            id="startDate"
                            name="startDate"
                            label=""
                            type="date"
                            variant="outlined"
                            size="small"
                            placeholder="Nhập ngày diễn ra"
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                            value={startDate}
                            onChange={handleInputChangeStartDate}
                        />
                    </Box>
                </Grid>
                <Grid item xs={10} sm={4}>
                    <Box display="flex" justifyContent="flex-end" m={1} p={1}>
                        Ngày kết thúc
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box display="flex" justifyContent="flex-start" p={1} width="70%">
                        <TextField
                            fullWidth={true}
                            id="endDate"
                            name="endDate"
                            label=""
                            type="date"
                            variant="outlined"
                            size="small"
                            placeholder="Nhập ngày kết thúc"
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                            value={endDate}
                            onChange={handleInputChangeEndDate}
                        />
                    </Box>
                </Grid>
                <Grid item xs={10} sm={4}>
                    <Box display="flex" justifyContent="flex-end" m={1} p={1}>
                        Địa điểm
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box p={1} width="70%">
                        <Select
                            isMulti
                            name="location"
                            options={pens}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            styles={customStyles}
                            placeholder="Chọn chuồng"
                            defaultValue={penIdsDisplay}
                            onChange={handleInputChangePenIds}
                        />
                    </Box>
                </Grid>
                <Grid item xs={10} sm={4}>
                    <Box display="flex" justifyContent="flex-end" m={1} p={1}>
                        Loại sự kiện
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box p={1} width="70%">
                        <Select
                            name="eventType"
                            options={eventTypes}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            styles={customStyles}
                            placeholder="Chọn loại sự kiện"
                            defaultValue={{ value: eventTypeId, label: eventTypeName }}
                            onChange={handleInputChangeEventType}
                        />
                    </Box>
                </Grid>
                <Grid item xs={10} sm={4}>
                    <Box display="flex" justifyContent="flex-end" m={1} p={1}>
                        Ghi chú
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box display="flex" justifyContent="flex-start" p={1}>
                        <TextField
                            id="desc"
                            name="desc"
                            label=""
                            multiline
                            rows={4}
                            defaultValue=""
                            variant="outlined"
                            fullWidth={true}
                            placeholder="Nhập ghi chú"
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                            value={desc}
                            onChange={handleInputChangeDesc}
                        />
                    </Box>
                </Grid>
                <Grid item xs={10} sm={4}>
                    <Box display="flex" justifyContent="flex-end" m={1} p={1}>

                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box display="flex" justifyContent="flex-start" p={1} mb={5}>
                        <Button
                            onClick={doSave}
                            variant="contained"
                            className={classes.btnSave}
                            disabled={(name && startDate && endDate && penIds && eventTypeId) ? false : true}
                        >
                            {
                                isLoading && (
                                    <CircularProgress className={classes.circularProgress} size={20} />
                                )
                            }
                            {isUpdate ? "Cập nhật" : "Lưu"}
                        </Button>
                        <Button onClick={onClose} variant="contained" className={classes.btnCanel}>
                            Hủy bỏ
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

export default EventCreate;
