/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import pig from '@assets/pig-item.png';
import runnIcon from '@assets/pig-walk.png';
import standIcon from '@assets/pig-stand.png';
import eatIcon from '@assets/pig-eat.png';
import lieIcon from '@assets/pig-lie.png';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import { IPigData } from '@src/src/app/types/pig';
import Utils from '@src/src/common/utils';
import './style.pig-item.scss'
import { BiWalk } from 'react-icons/bi'
import { LuPersonStanding } from 'react-icons/lu'
import { GiNightSleep } from 'react-icons/gi'
import { TbMeat } from 'react-icons/tb'
import moment from 'moment';

type PigItemProps = {
    runningTime: string,
    eatingTime: string;
    lieDownTime: string;
    standingTime: string;
    onClick?: any;
    pigAIId: string;
    pigSelected: boolean;
    pigInfo: IPigData
    lastUpdate?: string
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            backgroundColor: '#FFF9F9',
            marginBottom: '10px',
            borderRadius: '10px',
            gap: '10px',
        },
        pigAvatar: {
            display: 'flex',
            flexDirection: 'row'
        },
        pigAvatarImg: {
            width: '50px',
            height: '50px',
            borderRadius: '100%',
            margin: '4px',
        },
        pigInfor: {
            display: 'flex',
            flexDirection: 'column',
        },
        pigId: {
            display: 'flex',
            flexDirection: 'row',
            fontSize: '14px',
            color: '#18191F',
            fontWeight: 'bold',
            padding: '5px'
        },
        timeMoved: {
            display: 'grid',
            // flexDirection: 'row',
            fontSize: '12px',
            color: '#18191F',
            marginBottom: '5px',
            // justifyContent: 'space-beetwen'
            gridTemplateColumns: '25% 25% 25% 25%',
            width: '27vw'
        },
        iconBehaviour: {
            display: 'flex',
            flexDirection: 'row'
        },
        activityIcon: {
            width: '20px',
            objectFit: 'contain',
        }
    }),
);

const PigItem: FC<PigItemProps> = ({ runningTime, eatingTime, lieDownTime, standingTime, onClick, pigAIId, pigSelected, pigInfo, lastUpdate }: PigItemProps) => {
    const classes = useStyles();

    return (
        <div className={classes.content} style={{ backgroundColor: pigSelected ? '#FF5A80' : '' }}>
            <div className={classes.pigAvatar}>
                <img src={pig} alt="pig" className={classes.pigAvatarImg} />
            </div>
            <div className={classes.pigInfor} onClick={onClick}>
                <div className={classes.pigId}>
                    {pigAIId}
                </div>
                <div className={classes.timeMoved}>
                    <div className={'iconBehaviour'}>
                        <div className='icon-groud'>
                            <Tooltip title="Thời gian đi"><img src={runnIcon} className={classes.activityIcon} /></Tooltip>
                            <span style={{ fontSize: '10px', marginRight: '10px', marginLeft: '5px' }}>{runningTime}</span>
                        </div>
                        <b>Đi</b>
                    </div>
                    <div className={'iconBehaviour'}>
                        <div className="icon-groud">
                            <Tooltip title="Thời gian đứng"><img src={standIcon} className={classes.activityIcon} /></Tooltip>
                            <span style={{ fontSize: '10px', marginRight: '10px', marginLeft: '5px' }}>{standingTime}</span>
                        </div>
                        <b>Đứng</b>
                    </div>
                    <div className={'iconBehaviour'}>
                        <div className="icon-groud">
                            <Tooltip title="Thời gian nằm"><img src={lieIcon} className={classes.activityIcon} /></Tooltip>
                            <span style={{ fontSize: '10px', marginRight: '10px', marginLeft: '5px' }}>{lieDownTime}</span>
                        </div>
                        <b>Nằm</b>
                    </div>
                    <div className={'iconBehaviour'}>
                        <div className="icon-groud">
                            <Tooltip title="Thời gian ăn"><img src={eatIcon} className={classes.activityIcon} /></Tooltip>
                            <span style={{ fontSize: '10px', marginRight: '10px', marginLeft: '5px' }}>{eatingTime}</span>
                        </div>
                        <b>Ăn</b>
                    </div>
                </div>
                <div>
                    {/* <Tooltip title="Thời gian bắt đầu theo dõi"> */}
                    <span style={{ fontSize: '11px' }}>Bắt đầu theo dõi: {moment(lastUpdate).format('DD/MM/YYYY HH:mm:ss')}</span>
                    {/* </Tooltip> */}
                </div>
            </div>
        </div>
    );
}

export default PigItem;