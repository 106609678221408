/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { FC } from 'react';
import { ICam } from '@type/cam';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { v4 as uuidv4 } from 'uuid';
import CImageLoading from '../../video/CImageLoading';
import StreamAPI from '@src/src/app/api/camera/streaming.api';
import { classNames } from 'react-select/src/utils';

interface ICamList {
    camLists: ICam[];
    camSelected: any;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
        },
        videoItem: {
            width: '400px',
            margin: '20px',
            position: 'relative',
            border: '2px solid black',
        },
        camName: {
            display: 'block',
            position: 'absolute',
            left: 10,
            top: 10,
            backgroundColor: '#FF5A80',
            color: 'white',
            fontSize: '12px',
            paddingLeft: '5px',
            paddingRight: '5px'
        },
        selectedHelpText: {
            display: 'block',
            position: 'absolute',
            left: 10,
            top: 30,
            backgroundColor: '#FF5A80',
            color: 'white',
            fontSize: '12px',
            paddingLeft: '5px',
            paddingRight: '5px'
        },
    }),
);

const CamList: FC<ICamList> = ({ camLists, camSelected }: ICamList) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {
                camLists.map((cam, index) => {
                    return (
                        <div key={index} className={classes.videoItem} onClick={() => camSelected(cam)}>
                            <div className={classes.camName}><b>{cam.camName}</b></div>
                            <div className={classes.selectedHelpText}>Click vào video để phóng to</div>
                            {/* <VideoItem uuId={uuidv4()} camId={cam.id} width='400' height='300' /> */}
                            {/*video*/}
                            <div className={`cursor-pointer`}>
                                <CImageLoading
                                    src={StreamAPI.getStream(cam.id)}
                                    className="h-60"
                                    uuid={uuidv4()}
                                    // onClick={() =>
                                    //     handleShowExpandMenu(true, item)
                                    // }
                                    isHiddenCamera={cam?.id ? true : false}
                                    idCamera={cam?.id}
                                />
                            </div>
                            {/*end*/}
                        </div>
                    )
                })
            }
        </div>
    )
}

export default CamList;