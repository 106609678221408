import { CaretDownOutlined } from '@ant-design/icons';
import { IconButton } from '@material-ui/core';
import { Breadcrumb, Button, ConfigProvider, DatePicker, Form, Input, List, Modal, Select, Space, Spin, Table, Tooltip, notification } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { FaEdit, FaHistory, FaTemperatureHigh } from 'react-icons/fa';
import PigIcon from '../../../../public/assets/icon-pig.png';
import { IPenData } from '../../../types/pen';
import './style.tagpigspen.scss';
import farmService from '@services/farm';
import { SmileOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useDispatchRoot, useSelectorRoot } from '@src/src/app/redux/store';
import { setCpenSelected, setDataTagPigSick, setShowModalAddSickPig } from '@src/src/app/redux/controller/farm.slice';
import * as XLSX from 'xlsx';
import { DownloadOutlined } from '@ant-design/icons';


type TagPigPenProps = {
    penSelected: IPenData | undefined;
    dataTagPigs: any[];
    resetDataTagPigs: (id: any) => void;
};

// Các cột của bảng
const columnsPig = [
    {
        title: 'STT',
        dataIndex: 'ordinalNumber',
        key: 'ordinalNumber',
    },
    {
        title: 'Mã thẻ',
        dataIndex: 'tagId',
        key: 'tagId',
    },
    {
        title: 'Thân nhiệt hiện tại',
        dataIndex: 'temperature',
        key: 'temperature',
    },
    {
        title: 'Tình trạng bệnh',
        dataIndex: 'status',
        key: 'status',
    },

    {
        key: 'operation',
        title: 'Thao tác',
        dataIndex: 'operation',
    },
];

const columnsTempPig = [
    // {
    //     title: 'STT',
    //     dataIndex: 'ordinalNumber',
    //     key: 'ordinalNumber',
    // },
    {
        title: 'Tên chuồng',
        dataIndex: 'penName',
        key: 'penName',
    },
    {
        title: 'TAG',
        dataIndex: 'tagId',
        key: 'tagId',
    },
    {
        title: 'Ngày giờ đo',
        dataIndex: 'timestamp',
        key: 'timestamp',
    },
    {
        title: 'Nhiệt độ',
        dataIndex: 'temperature',
        key: 'temperature',
    },
    {
        title: 'Cảnh báo',
        dataIndex: 'status',
        key: 'status',
    },

    // {
    //     key: 'operation',
    //     title: 'Thao tác',
    //     dataIndex: 'operation',
    // },
];

const columnsSickPig = [
    // {
    //     title: 'STT',
    //     dataIndex: 'ordinalNumber',
    //     key: 'ordinalNumber',
    // },
    {
        title: 'Thời gian',
        dataIndex: 'timestamp',
        key: 'timestamp',
    },
    {
        title: 'Loại bệnh',
        dataIndex: 'diseaseName',
        key: 'diseaseName',
    },
    {
        title: 'Ghi chú',
        dataIndex: 'note',
        key: 'note',
    },

    {
        key: 'operation',
        title: 'Thao tác',
        dataIndex: 'operation',
    },
];

const padTo2Digits = (num: any) => {
    return num.toString().padStart(2, '0');
}

const formatDate = (date: any) => {
    return (
        [
            padTo2Digits(date.getDate()),
            padTo2Digits(date.getMonth() + 1),
            date.getFullYear(),
        ].join('/') +
        ' ' +
        [
            padTo2Digits(date.getHours()),
            padTo2Digits(date.getMinutes()),
            padTo2Digits(date.getSeconds()),
        ].join(':')
    );
}
const TagPigsPen = ({ penSelected, dataTagPigs, resetDataTagPigs }: TagPigPenProps) => {
    const { Option } = Select;
    const [dataPigs, setDataPigs] = React.useState<any[]>([]); // Dữ liệu lợn
    const [currentPage, setCurrentPage] = useState(1); // Trang hiện tại
    const [pageSize, setPageSize] = useState(10); // Số thẻ lợn trên một trang
    const [offset, setOffset] = useState(0); // Vị trí bắt đầu lấy dữ liệu
    const [size, setSize] = useState(1000); // Số lượng lợn lấy ra
    const [dataTempPig, setDataTempPig] = useState<any[]>([]); // Dữ liệu thân nhiệt lợn
    const [dataSickPig, setDataSickPig] = useState<any[]>([]); // Dữ liệu bệnh lợn
    const [pigSelected, setPigSelected] = useState<any>(null); // Lợn được chọn
    const [tempPigSelected, setTempPigSelected] = useState<any>(null); // Thân nhiệt lợn được chọn
    const [sickPigSelected, setSickPigSelected] = useState<any>(null); // Bệnh lợn được chọn
    const [isShowAddModal, setIsShowAddModal] = useState(false); // Trạng thái hiển thị của modal thêm lợn
    const [isShowEditModal, setIsShowEditModal] = useState(false); // Trạng thái hiển thị của modal sửa lợn
    const [form] = Form.useForm(); // Form thêm thẻ lợn
    const [formTagPig] = Form.useForm(); // Form sửa thẻ lợn
    const [lstSick, setLstSick] = useState<any[]>([]); // Danh sách bệnh
    const [isEdit, setIsEdit] = useState(false); // Trạng thái sửa
    const [loading, setLoading] = useState(false); // Trạng thái loading
    const [loadingTempPig, setLoadingTempPig] = useState(false); // Trạng thái loading
    const [loadingSickPig, setLoadingSickPig] = useState(false); // Trạng thái loading
    const [editPigId, setEditPigId] = useState<any>(null); // Id lợn đang được sửa
    const [isShowDeleteModal, setIsShowDeleteModal] = useState(false); // Trạng thái hiển thị của modal xóa
    const [deletePigId, setDeletePigId] = useState<any>(null); // Id lợn đang được xóa
    const [editTagPigId, setEditTagPigId] = useState<any>(null); // Id thẻ lợn đang được sửa
    const [lstPen, setLstPen] = useState<any[]>([]); // Danh sách chuồng
    const currentFarm = farmService.getCurrentFarm(); // Chuồng đang được chọn
    const dispatch = useDispatchRoot()
    const [idSickPigOther, setIdSickPigOther] = useState<any>('64b8f78b0f4e68c1aa894b34'); // Id lợn đang được sửa
    const history = useHistory();

    const { tempPigId } = useSelectorRoot(state => state.farm)


    console.log(dataTagPigs);


    const loadSickList = async () => {
        axios.get(`https://sit.api.pigman.com.vn/disease?size=1000`)
            .then((res) => {
                console.log(res.data.data.items);
                setLstSick(res.data.data.items);
            })
    }

    // Hàm thực hiện lấy dữ liệu chuồng
    const loadLstPen = async () => {
        axios.get(`https://sit.api.pigman.com.vn/pens/allPens/${currentFarm.id}?offset=${offset}&size=${size}`)
            .then(res => {
                const tmp: { id: any; name: any; }[] = []
                console.log(res);
                res.data.data.items.forEach((item: any) => {
                    item.analysisWarning !== null &&
                        tmp.push({
                            id: item.id,
                            name: item.name
                        })
                })
                setLstPen(tmp);
            })
    }

    // const loadTempTagByPen = async (id: string) => {
    //     axios.get(`https://sit.api.pigman.com.vn/temperatureTag/penId/${id}?offset=${offset}&size=${size}`)
    //         .then((res: any) => {
    //             console.log(res.data.data.items);
    //             if (res.data.data.items !== null && res.data.data.items.length > 0 && res.data.data.items[0] !== null) {
    //                 setDataTagPigs(res.data.data.items);
    //             }
    //             else {
    //                 notification['error']({
    //                     message: 'Không tìm thấy thẻ nhiệt',
    //                     description:
    //                         'Chưa có thẻ lợn nào được gắn vào chuồng này',
    //                 });
    //                 setDataTagPigs([]);
    //             }
    //         })
    // }
    useEffect(() => {
        console.log(dataTagPigs);
        console.log(penSelected);

        if (!tempPigId && dataTagPigs.length > 0) {
            console.log(dataTagPigs[0].tagId);

            loadSickList();
            loadLstPen();
            // Tìm thẻ lợn có thân nhiệt
            setTempPigSelected(dataTagPigs[0].tagId);
            handleClickTempPig(dataTagPigs[0].tagId);
        }
    }, [])

    useEffect(() => {
        loadSickList();
        loadLstPen();
        console.log(tempPigId);

        if (tempPigId) {
            handleClickTempPig(parseInt(tempPigId.tagId));
            setTempPigSelected(parseInt(tempPigId.tagId));
            setSickPigSelected(null);
        }
    }, [tempPigId])

    // Hàm thực hiện gán classname cho các dòng của bảng
    const getRowClassName = (record: any, index: number) => {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
    };

    const handleChangePageTemp = (page: number, pageSize?: number) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setCurrentPage(page);
        pageSize && setPageSize(pageSize);
        // Tắt loading
    };

    // Hàm thực hiện thay đổi các giá trị trong phân trang
    const paginationConfig = {
        current: currentPage,
        pageSize,
        total: dataPigs.length,
        onChange: handleChangePageTemp,
        showSizeChanger: false,
        // showTotal: false,
        pageSizeOptions: ['10', '20', '30', '50'],
    };

    // Hàm thực hiện thay đổi các giá trị trong phân trang
    const paginationConfigTemp = {
        current: currentPage,
        pageSize,
        total: dataTempPig.length,
        onChange: handleChangePageTemp,
        showSizeChanger: false,
        // showTotal: false,
        pageSizeOptions: ['10', '20', '30', '50'],
    };

    // Hàm thực hiện thay đổi các giá trị trong phân trang
    const paginationConfigSick = {
        current: currentPage,
        pageSize,
        total: dataSickPig.length,
        onChange: handleChangePageTemp,
        showSizeChanger: false,
        // showTotal: false,
        pageSizeOptions: ['10', '20', '30', '50'],
    };

    const handleClickTempPig = (item: any) => {
        console.log(item);
        setLoadingTempPig(true);
        axios.get(`https://sit.api.pigman.com.vn/temperatureStatus/realData?tagId=${item}&page=1&limit=1000`)
        // axios.get(`https://sit.api.pigman.com.vn/temperatureStatus?temperatureTagId=${item}&size=1000&offset=0`)

            .then((res: any) => {
                console.log(res);
                console.log(dataTagPigs);
                let tagName = dataTagPigs.find((tag: any) => parseInt(tag.tagId) === parseInt(item));
                console.log(tagName);
                const offsetPigs = (tagName.offset !== null && tagName.offset !== undefined && !isNaN(tagName.offset)) ? tagName.offset : 0;
                tagName = tagName?.name ? tagName?.name : ('Heo ' + item);
                console.log(offsetPigs);

                setDataSickPig([]);
                if (res?.data?.data && res?.data?.data.length > 0) {
                    // setOpenTemp(true);
                    const data = res.data.data.map((item: any, index: number) => {
                        // Chuyển đổi dữ liệu lấy được thành dạng dữ liệu của bảng
                        const tmptimestamp = formatDate(new Date(item.timestamp));
                        return {
                            ordinalNumber: index + 1,
                            penName: penSelected?.name,
                            tagId: tagName,
                            timestamp: tmptimestamp,
                            temperature: (parseFloat(item.temperature) + parseFloat(offsetPigs)).toFixed(2) + '°C',
                            status: (
                                <div className={item.status === 'Bất thường' ? 'warning' : ''}>{item.status}</div>
                            ),
                        }
                    });
                    // Gán dữ liệu vào biến dataPigs
                    setDataTempPig(data);

                }
                // if (res?.data?.data?.items && res?.data?.data?.items.length > 0) {
                //     // setOpenTemp(true);
                //     const data = res.data.data.items.map((item: any, index: number) => {
                //         // Chuyển đổi dữ liệu lấy được thành dạng dữ liệu của bảng
                //         const tmptimestamp = formatDate(new Date(item.timestamp));
                //         return {
                //             ordinalNumber: index + 1,
                //             timestamp: tmptimestamp,
                //             temperature: item.temperature + '°C',
                //             note: item.status,
                //             status: (
                //                 <div className={item.status === 'Bất thường' ? 'warning' : ''}>{item.status}</div>
                //             ),
                //         }
                //     });
                //     // Gán dữ liệu vào biến dataPigs
                //     setDataTempPig(data);

                // }
                else {
                    // notification['error']({
                    //     message: 'Không tìm thấy thân nhiệt',
                    //     description:
                    //         'Chưa có thân nhiệt nào của lợn này',
                    // });
                    setDataTempPig([]);

                }
                setLoadingTempPig(false);
            })
    }

    const handleClickSickPig = (item: any) => {
        console.log(item);
        setLoadingSickPig(true);
        axios.get(`https://sit.api.pigman.com.vn/historyDisease?temperatureTagId=${item}&size=1000&offset=0`)
            .then((res: any) => {
                setDataTempPig([]);
                if (res.data.data.items !== null && res.data.data.items.length > 0 && res.data.data.items[0] !== null) {
                    // setOpenTemp(true);
                    const data = res.data.data.items.map((item: any, index: number) => {
                        // Chuyển đổi dữ liệu lấy được thành dạng dữ liệu của bảng
                        console.log(item);

                        const startTime = new Date(item.startTime).toLocaleDateString('en-GB');
                        const endTime = new Date(item.endTime).toLocaleDateString('en-GB');
                        return {
                            ordinalNumber: index + 1,
                            timestamp: startTime + ' - ' + endTime,
                            diseaseName: item.disease ? item.disease.name : '',
                            note: item.status,
                            operation:
                                <Space>
                                    <Tooltip title="Sửa lịch sử bệnh">
                                        <IconButton style={{ fontSize: 18 }} aria-label="Sửa lịch sử bênh" onClick={() => handleClickEditSickPig(item)}>
                                            <FaEdit />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Xóa lịch sử bệnh">
                                        <IconButton style={{ fontSize: 18 }} aria-label="Xóa lịch sử bệnh" onClick={() => handleClickDeleteSickPig(item)}>
                                            <AiOutlineDelete />
                                        </IconButton>
                                    </Tooltip>
                                </Space>
                        }
                    });
                    // Gán dữ liệu vào biến dataPigs
                    setDataSickPig(data);
                }
                else {
                    // notification['error']({
                    //     message: 'Không tìm thấy lịch sử bệnh',
                    //     description:
                    //         'Chưa có lịch sử bệnh nào của lợn này',
                    // });
                    setDataSickPig([]);

                }
                setLoadingSickPig(false);
            })
    }

    const handleCancel = () => {
        setIsShowAddModal(!isShowAddModal);
    }

    const handleCancelDeleteModal = () => {
        setIsShowDeleteModal(!isShowDeleteModal);
    }

    const handleCancelEditModal = () => {
        setIsShowEditModal(!isShowEditModal);
    }

    const handleOk = () => {
        setIsShowAddModal(!isShowAddModal);
    }

    const handleOkEditModal = () => {
        setIsShowEditModal(!isShowEditModal);
    }

    const handleClickAddSickPig = () => {
        form.setFieldsValue({
            startTime: null,
            endTime: null,
            status: null,
            diseaseId: null,
        });
        setIsShowAddModal(true);
        setIsEdit(false);
    }

    const handleClickEditSickPig = (item: any) => {
        // Chuyển đổi dữ liệu lấy được thành dạng dữ liệu của bảng
        form.setFieldsValue({
            startTime: moment(item.startTime),
            endTime: moment(item.endTime),
            status: item.status,
            diseaseId: item.diseaseId,
        });
        setIsShowAddModal(true);
        setIsEdit(true);
        setEditPigId(item.id);
    }

    const handleClickDeleteSickPig = (item: any) => {
        console.log(item);
        setDeletePigId(item.id);
        setIsShowDeleteModal(true);
    }

    const handleClickEditTagPig = (item: any) => {
        console.log(item);
        setEditTagPigId(item.id);
        setIsShowEditModal(true);
        formTagPig.setFieldsValue({
            tagId: item.tagId,
            manufacturer: item.manufacturer,
            createdAt: moment(item.createdAt),
            warrantyPeriod: item.warrantyPeriod,
            batteryLevel: item.batteryLevel,
            penId: (item.penId === "000000000000000000000000" || !item.penId) ? null : item.penId,

        });
    }

    const onFinish = (values: any) => {
        setLoading(true);
        console.log('Success:', values);
        const res = {
            temperatureTagId: sickPigSelected,
            penId: penSelected?.id,
            farmId: currentFarm?.id,
            startTime: values.startTime._d,
            endTime: values.endTime._d,
            diseaseId: values.diseaseId,
            description: '',
            status: values.status,
            additionalProp1: {}
        }
        console.log(res);
        if (isEdit) {
            axios.put(`https://sit.api.pigman.com.vn/historyDisease/update/${editPigId}`, res)
                .then((res: any) => {
                    setTimeout(() => {
                        setIsShowAddModal(false);
                        // Hiển thị thông báo xóa thành công
                        notification['success']({
                            message: 'Sửa lịch sử bệnh thành công',
                            description:
                                'Đã sửa lịch sử bệnh thành công cho lợn',
                        });
                        // Gọi hàm fetchData để lấy lại dữ liệu
                        handleClickSickPig(sickPigSelected);
                        // Tắt loading
                        setLoading(false);
                    }, 1000);
                })
        }
        else {
            axios.post('https://sit.api.pigman.com.vn/historyDisease/create', res)
                .then((res: any) => {
                    setTimeout(() => {
                        setIsShowAddModal(false);
                        // Hiển thị thông báo xóa thành công
                        notification['success']({
                            message: 'Thêm lịch sử bệnh thành công',
                            description:
                                'Đã thêm lịch sử bệnh thành công cho lợn',
                        });
                        // Gọi hàm fetchData để lấy lại dữ liệu
                        handleClickSickPig(sickPigSelected);
                        // Tắt loading
                        setLoading(false);
                    }, 1000);
                })
                .catch((err: any) => {
                    console.log(err);
                })
        }
    }

    const onFinishEditTag = (values: any) => {
        console.log('Success:', values);
        // Hiển thị loading
        setLoading(true);

        // Nếu thẻ lợn đang được chỉnh sửa thì gọi api sửa thẻ lợn
        // Lấy ra giá trị đã nhập trong form
        const req = {
            farmId: currentFarm.id,
            tagId: values.tagId,
            penId: values.penId,
            manufacturer: values.manufacturer,
            warrantyPeriod: parseInt(values.warrantyPeriod),
            createdAt: values.createdAt._d,
            additionalProp1: {}
        }
        console.log(req);
        // Gọi api sửa thẻ lợn
        axios.put('https://sit.api.pigman.com.vn/temperatureTag/' + editTagPigId, req)
            .then(response => {
                setTimeout(() => {
                    console.log(response);
                    // Hiển thị thông báo sửa thành công
                    notification['success']({
                        message: 'Sửa thẻ lợn thành công',
                        description:
                            'Đã sửa thẻ lợn vào cơ sở dữ liệu',
                    });
                    // Gọi hàm fetchData để lấy lại dữ liệu
                    resetDataTagPigs(values.penId);
                    // Tắt modal thêm/sửa thẻ lợn
                    setIsShowEditModal(false);
                    // Tắt loading
                    setLoading(false);
                }, 1000);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleDeleteSubmit = () => {
        // Hiển thị loading
        setLoading(true);

        // Gọi api xóa thẻ lợn
        axios.delete('https://sit.api.pigman.com.vn/historyDisease/delete/' + deletePigId)
            .then(() => {
                setTimeout(() => {
                    setIsShowDeleteModal(false);
                    // Hiển thị thông báo xóa thành công
                    notification['success']({
                        message: 'Xóa lịch sử bệnh lợn thành công',
                        description:
                            'Đã xóa lịch sử bệnh lợn khỏi cơ sở dữ liệu',
                    });
                    // Gọi hàm fetchData để lấy lại dữ liệu
                    handleClickSickPig(sickPigSelected);
                    // Tắt loading
                    setLoading(false);
                }, 1000);
            });
    }

    const customizeRenderEmpty = () => (
        <div className='empty-sick' onClick={handleClickEmpty}>
            Bệnh khác
        </div>
    );

    const handleClickEmpty = () => {
        form.setFieldsValue({
            diseaseId: idSickPigOther,
        });
        const tmp = document.getElementById('input-status-pig');
        tmp?.focus();

    }
    const handleClickPen = () => {
        dispatch(setCpenSelected(undefined))
        dispatch(setDataTagPigSick([]))
        history.push('/quan-ly-chuong')
    }

    const handleChangeValueSelect = (value: any) => {
        console.log(value);
    }

    useEffect(() => {
        if (tempPigSelected || sickPigSelected) {
            setTimeout(() => {
                const element = document.getElementById('active');
                element?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
            }, 500);
        }
    }, [tempPigSelected, sickPigSelected])

    const exportToExcelTempTable = () => {
        axios.get(`https://sit.api.pigman.com.vn/temperatureStatus/realData?tagId=${tempPigSelected}&page=1&limit=1000`)
        // axios.get(`https://sit.api.pigman.com.vn/temperatureStatus?temperatureTagId=${item}&size=1000&offset=0`)

            .then((res: any) => {
                console.log(res.data.data);

                setDataSickPig([]);
                if (res?.data?.data && res?.data?.data.length > 0) {
                    let tagName = dataTagPigs.find((tag: any) => tag.tagId === tempPigSelected);
                    tagName = tagName?.name ? tagName?.name : ('Heo ' + tempPigSelected);
                    // setOpenTemp(true);
                    const data = res.data.data.map((item: any, index: number) => {
                        // Chuyển đổi dữ liệu lấy được thành dạng dữ liệu của bảng
                        const tmptimestamp = formatDate(new Date(item.timestamp));
                        return {
                            ordinalNumber: index + 1,
                            penName: penSelected?.name,
                            tagId: tagName,
                            timestamp: tmptimestamp,
                            temperature: parseFloat(item.temperature.toFixed(2)).toFixed(2) + '°C',
                            status: item.status,
                        }
                    });
                    // Gán dữ liệu vào biến dataPigs
                    // Map over sortData to create a new array with the desired column names
                    const dataWithVietnameseKeys = data.map((item: any) => ({
                        'STT': item.ordinalNumber,
                        'Tên chuồng': item.penName,
                        'TAG': item.tagId,
                        'Ngày giờ đo': item.timestamp,
                        'Nhiệt độ': item.temperature,
                        'Cảnh báo': item.status,
                    }));

                    const ws = XLSX.utils.json_to_sheet(dataWithVietnameseKeys);
                    ws['!cols'] = [
                        { width: 5 },
                        { width: 25 },
                        { width: 25 },
                        { width: 25 },
                        { width: 25 },
                        { width: 30 },

                    ];
                    const wb = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
                    console.log(tempPigSelected);
                    // Chuyển đổi currentFarm.name từ có dấu thành không dấu
                    const str = currentFarm.name;
                    const str1 = str.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                    // Chuyển đổi penSelected.name từ có dấu thành không dấu
                    const str2 = penSelected?.name;
                    const str3 = str2?.normalize('NFD').replace(/[\u0300-\u036f]/g, "");

                    XLSX.writeFile(wb, `Lichsuthannhiet_${str1}_${str3}_${tempPigSelected}.xlsx`);
                }

                else {

                    setDataTempPig([]);

                }
            })

    };

    const exportToExcelSickTable = () => {
        // Map over sortData to create a new array with the desired column names
        const dataWithVietnameseKeys = dataSickPig.map((item: any) => ({
            'STT': item.ordinalNumber,
            'Thời gian': item.timestamp,
            'Loại bệnh': item.diseaseName,
            'Ghi chú': item.note,
        }));

        const ws = XLSX.utils.json_to_sheet(dataWithVietnameseKeys);
        ws['!cols'] = [
            { width: 5 },
            { width: 25 },
            { width: 25 },
            { width: 25 },

        ];
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        // Chuyển đổi currentFarm.name từ có dấu thành không dấu
        const str = currentFarm.name;
        const str1 = str.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
        // Chuyển đổi penSelected.name từ có dấu thành không dấu
        const str2 = penSelected?.name;
        const str3 = str2?.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
        XLSX.writeFile(wb, `Danh sach lich su benh cua the nhiet_${str1}_${str3}_${sickPigSelected}.xlsx`);

    };
    return (
        <div className='main-tag-pig-pen'>
            <div className='main-breadcrumb'>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Tooltip title="Quay lại danh sách chuồng">
                            <span style={{ cursor: 'pointer' }} onClick={handleClickPen}>Chuồng nuôi</span>
                        </Tooltip>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Tên chuồng: {penSelected?.name}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Danh sách lợn</Breadcrumb.Item>
                    {tempPigSelected &&
                        <Breadcrumb.Item>Thông tin thân nhiệt của lợn</Breadcrumb.Item>
                    }
                    {sickPigSelected &&
                        <Breadcrumb.Item>Lịch sử bệnh của lợn</Breadcrumb.Item>
                    }
                </Breadcrumb>
                {sickPigSelected &&
                    <Button className='title-button' onClick={handleClickAddSickPig}>Thêm lịch sử bệnh</Button>
                }

            </div>
            <Spin spinning={loading} delay={500} tip="Đang lấy dữ liệu...">
                <div className='table-tag-pig'>
                    {((dataTempPig.length <= 0 || dataSickPig.length <= 0) && dataTagPigs.length > 0) &&
                        // <Table
                        //     className='table-temp table-list-pig'
                        //     dataSource={dataPigs}
                        //     columns={columnsPig}
                        //     rowClassName={getRowClassName}
                        //     pagination={paginationConfig}
                        // />
                        <List
                            itemLayout="horizontal"
                            dataSource={dataTagPigs}
                            renderItem={(item, index) => (
                                <List.Item
                                    id={(item.tagId === tempPigSelected || item.tagId === sickPigSelected) ? 'active' : ''}
                                // onClick={() => setPigSelected(item.id)}
                                >
                                    <div className='list-pig-item'>
                                        <div className='item-ordinalNumber'>{index + 1}</div>
                                        <div className='item-image-pig' >
                                            <img src={PigIcon} alt='pig' />
                                            <span>{item.tagId}</span>
                                        </div>
                                        <div className='item-content'>
                                            {/* Làm tròn đến chữ số thập phân thứ 2 */}
                                            <div className='item-status'>Số tag: {item.name ? item.name : ('Heo ' + item.tagId)}</div>
                                            {(item.offset !== null && item.offset !== undefined && !isNaN(item.offset)) ?
                                                <div className={'item-temp'}>Thân nhiệt hiện tại: {(parseFloat(item.currentTemperature) + parseFloat(item.offset)).toFixed(2)}°C</div>
                                                :
                                                <div className={'item-temp'}>Thân nhiệt hiện tại: {(parseFloat(item.currentTemperature)).toFixed(2)}°C</div>

                                            }
                                            {/* <div className={'item-temp'}>Thân nhiệt hiện tại: {parseFloat(item.currentTemperature).toFixed(2)}°C</div> */}
                                            <div className='item-status'>Trạng thái: {((parseFloat(item.currentTemperature) + parseFloat(item.offset)) > 39) ? 'Bất thường' : 'Bình thường'}</div>
                                            <div className='item-status'>Chuồng: {penSelected?.name}</div>
                                        </div>
                                        <div className="item-icon">
                                            <Tooltip title="Thân nhiệt">
                                                <IconButton
                                                    id={item.tagId === tempPigSelected ? 'active-button' : ''}
                                                    style={{ fontSize: 18 }}
                                                    aria-label="Thẻ nhiệt"
                                                    onClick={
                                                        () => {
                                                            handleClickTempPig(item.tagId)
                                                            setTempPigSelected(item.tagId);
                                                            setSickPigSelected(null);
                                                        }
                                                    } >
                                                    <FaTemperatureHigh />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Lịch sử bệnh">
                                                <IconButton id={item.tagId === sickPigSelected ? 'active-button' : ''} style={{ fontSize: 18 }} aria-label="Lịch sử bệnh" onClick={
                                                    () => {
                                                        setSickPigSelected(item.id);
                                                        setTempPigSelected(null);
                                                        handleClickSickPig(item.id)
                                                    }
                                                }
                                                >
                                                    <FaHistory />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </List.Item>
                            )}
                        />
                    }
                    {(dataSickPig.length <= 0 && !sickPigSelected && tempPigSelected) &&
                        <Spin
                            spinning={loadingTempPig}
                            delay={500}
                            tip="Đang lấy dữ liệu..."
                        >
                            <Table
                                className='table-temp table-temp-pig'
                                dataSource={dataTempPig}
                                columns={columnsTempPig}
                                rowClassName={getRowClassName}
                                pagination={paginationConfigTemp}
                            />
                            <div className='button-excel'>
                                <Button disabled={(dataTempPig && dataTempPig.length > 0) ? false : true} onClick={exportToExcelTempTable} type="primary" icon={<DownloadOutlined />}> Xuất báo cáo</Button>
                            </div>
                        </Spin>
                    }
                    {(dataTempPig.length <= 0 && !tempPigSelected && sickPigSelected) &&
                        <Spin
                            spinning={loadingSickPig}
                            delay={500}
                            tip="Đang lấy dữ liệu..."
                        >
                            {/* <div className='title-table'>
                                <div className='title-name'>Lịch sử bệnh của lợn</div>
                            </div> */}
                            <Table
                                className='table-temp table-sick-pig'
                                dataSource={dataSickPig}
                                columns={columnsSickPig}
                                rowClassName={getRowClassName}
                                pagination={paginationConfigSick}
                            />
                            <div className='button-excel'>
                                <Button disabled={(dataSickPig && dataSickPig.length > 0) ? false : true} onClick={exportToExcelSickTable} type="primary" icon={<DownloadOutlined />}> Xuất báo cáo</Button>
                            </div>
                        </Spin>
                    }
                </div>
            </Spin>
            <Modal
                visible={isShowAddModal}
                title={isEdit ? 'Sửa lịch sử bệnh' : 'Thêm lịch sử bệnh'}
                onCancel={handleCancel}
                onOk={handleOk}
                maskClosable={true}
                footer={false}
            >
                <Form layout="horizontal" onFinish={onFinish} form={form}>

                    <Form.Item name="startTime" label="Ngày bắt đầu" rules={[{ required: true, message: 'Vui lòng nhập ngày bắt đầu' }]}>
                        <DatePicker format={'DD/MM/yyyy'} placeholder='Nhập ngày bắt đầu' />
                    </Form.Item>
                    <Form.Item name="endTime" label="Ngày kết thúc" dependencies={['startTime']}
                        rules={[
                            { required: true, message: 'Vui lòng nhập ngày kết thúc' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (getFieldValue('startTime') <= value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Ngày kết thúc phải lớn hơn hoặc bằng ngày bắt đầu'));
                                },
                            }),

                        ]}>
                        <DatePicker format={'DD/MM/yyyy'} placeholder='Nhập ngày kết thúc' />
                    </Form.Item>

                    <ConfigProvider renderEmpty={customizeRenderEmpty}>
                        <Form.Item name="diseaseId" label="Loại bệnh"  >
                            <Select
                                id='select-sick-pig'
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                filterOption={(input: any, option: any) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                suffixIcon={<CaretDownOutlined />}
                                onChange={handleChangeValueSelect}
                                placeholder="Chọn loại bệnh"
                            >
                                {lstSick?.map((item, index) => (
                                    <Option key={index} value={item.id}>{item.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </ConfigProvider>
                    <Form.Item name="status" label="Ghi chú" rules={[{ required: true, message: 'Vui lòng nhập ghi chú' }]} >
                        <Input placeholder='Nhập ghi chú' id='input-status-pig' />
                    </Form.Item>
                    <div className='action-btn'>
                        <Button className='cancel-btn' type="primary" onClick={() => setIsShowAddModal(false)}>
                            Hủy bỏ
                        </Button>
                        <Button className='summit-btn' type="primary" htmlType="submit">
                            Xác nhận
                        </Button>

                    </div>
                </Form>
            </Modal>
            <Modal
                visible={isShowDeleteModal}
                title='Xóa thẻ lợn'
                onCancel={handleCancelDeleteModal}
                maskClosable={true}
                footer={false}
                className='delete-modal'
            >
                <div>Bạn có chắc chắn muốn xóa thẻ lợn này?</div>
                <div className='action-btn'>
                    <Button className='cancel-btn' type="primary" onClick={handleCancelDeleteModal}>
                        Hủy bỏ
                    </Button>
                    <Button className='summit-btn' type="primary" htmlType="submit" onClick={handleDeleteSubmit}>
                        Xác nhận
                    </Button>
                </div>
            </Modal>
            <Modal
                visible={isShowEditModal}
                title={'Chỉnh sửa thẻ lợn'}
                onCancel={handleCancelEditModal}
                onOk={handleOkEditModal}
                maskClosable={true}
                footer={false}
            >
                <Form layout="horizontal" onFinish={onFinishEditTag} form={formTagPig}>
                    <Form.Item name="tagId" label="Mã thẻ" rules={[{ required: true, message: 'Vui lòng nhập mã thẻ' }]} >
                        <Input type='number' placeholder='Nhập mã thẻ' />
                    </Form.Item>
                    <Form.Item name="manufacturer" label="Nhãn hiệu" rules={[{ required: true, message: 'Vui lòng nhập nhà sản xuất' }]}>
                        <Input placeholder='Nhập nhãn hiệu' />
                    </Form.Item>
                    <Form.Item name="createdAt" label="Ngày sản xuất" rules={[{ required: true, message: 'Vui lòng nhập ngày nhập' }]}>
                        <DatePicker format={'DD/MM/yyyy'} placeholder='Nhập ngày nhập thẻ' />
                    </Form.Item>
                    {/* <Form.Item name="warrantyPeriod" label="Thời gian bảo hành" rules={[{ required: true, message: 'Vui lòng nhập hạn bảo hành' }]}>
                        <Input type='number' placeholder='Thời gian bảo hành (tháng)' />
                    </Form.Item> */}
                    <Form.Item name="penId" label="Tên chuồng"  >
                        <Select
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            filterOption={(input: any, option: any) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            suffixIcon={<CaretDownOutlined />}
                            // onChange={handleChangeValueSelect}
                            placeholder="Chọn tên chuồng"
                        >
                            {lstPen?.map((item, index) => (
                                <Option key={index} value={item.id}>{item.name}</Option>
                            ))}
                        </Select>

                    </Form.Item>
                    <div className='action-btn'>
                        <Button className='cancel-btn' type="primary" onClick={() => setIsShowEditModal(false)}>
                            Hủy bỏ
                        </Button>
                        <Button className='summit-btn' type="primary" htmlType="submit">
                            Xác nhận
                        </Button>

                    </div>
                </Form>

            </Modal>
        </div>
    )
}

export default TagPigsPen