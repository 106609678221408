import React, { FC } from 'react';
import { renderMenu } from '@components/common/header/header';
import { Link } from 'react-router-dom';
import logo from '@assets/logo.png';
import appStore from '@assets/app-store.png';
import googlePlay from '@assets/google-play.png';
import './footer.scss';

const Footer: FC = () => {
    return (
        <div id='footer'>
            <div id='footer-content' className="main-content">
                <div id="left-footer">
                    <div id="footer-logo">
                        <Link to='/'><img src={logo} alt="logo" /></Link>
                    </div>

                    <div id="footer-link">
                        <div id="row-link">
                            <div> Địa chỉ:  Nhà A28, Tòa nhà Ươm tạo công nghệ  -  Số 18 Hoàng Quốc Việt, Phường Nghĩa Đô, <br /> Quận Cầu Giấy, Thành phố Hà Nội </div>
                            <div>Số điện thoại: (+84) 24 3756 8422 / (+84) 916 661 078</div>
                            <div>Email: vanthu@cretech.vast.vn / namduongthanh@gmail.com</div>
                        </div>
                        <div className={`nav-menu nav-menu-footer`}>
                            {renderMenu()}
                        </div>
                    </div>

                    <div id="rights">
                        Copyright © 2021 Trung tâm Nghiên cứu và Chuyển giao công nghệ. All rights reserved
                    </div>

                </div>

                <div id="right-footer">
                    <p id='right-footer-title'> Get the app </p>
                    <div className="download-link"> <a href='https://apps.apple.com/us/app/pigman/id6468979176'><img src={appStore} alt="" /></a> </div>
                    <div className="download-link"> <a href='https://play.google.com/store/apps/details?id=com.piggin&pli=1'><img src={googlePlay} alt="" /></a> </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;
