/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { FC, useEffect, useState } from 'react';
import { IMenu } from '@components/common/header/header.types';
import logo from '@assets/logo.png';
import { Link, useHistory } from 'react-router-dom';
import userServices from '@services/user';
import defaultUser from '@assets/default-user.png';
import './header.scss';
import { ILoginUser } from '../../auth/auth.types';
import routesName from '@routes/route-names';
// import Modal from '@material-ui/core/Modal';
// import ModalHeader from '../../common/modal/modal-header';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import farmService from '@services/farm';
import { IFarmCookiesData } from '@type/farm';
import { apiFarm } from '@api/farm';
// import Select from 'react-select';
import { Context } from '@components/context/context';

import { useToggleModal } from '@elements/modal/modal';
import AuthModal from '@components/auth/auth-modal';
import { EAuthFormType } from '@components/common/header/header.types';
import { AuthPopupOrigin } from '@src/src/app/utils/global';
import { useDispatchRoot, useSelectorRoot } from '@src/src/app/redux/store';
import { setAuthPopupOrigin } from '@src/src/app/redux/controller';
import { changeSelectedFarm, fetchLstEmptyPens, setClickNotificationType, setCpenSelected, setDataTagPigSick, setShowNotification, setTagPenId, setTempPigId, setTokenPublic, updateFarmList } from '@src/src/app/redux/controller/farm.slice';
import { FaBell } from "react-icons/fa";
import { BellOutlined } from '@ant-design/icons';
import { Badge, Button, ConfigProvider, DatePicker, Dropdown, Form, Input, Menu, message, notification, Select, Slider, Tooltip, Upload } from 'antd';
import { FiMoreHorizontal } from "react-icons/fi";
import PigIcon from '../../../../public/assets/icon-pig.png';
import TempIcon from '../../../../public/assets/TempIcon.png';
import { hi, id, ro } from 'date-fns/locale';
import { BsDot, BsThreeDotsVertical } from "react-icons/bs";
import axios from 'axios';
import Modal from '@material-ui/core/Modal';
import { useToolbarStyles } from '../../pigs/pig-helper';
import ModalHeader from '../modal/modal-header';
import PenDetail from '../../farm/pen/pen-detail';
import { Modal as ModalAntd } from 'antd';
import form from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import { set } from 'date-fns';
import { IoMdArrowDropdown } from "react-icons/io";
import { fetchToken, onMessageListener } from '../../../../firebase';
import { IBehaviorPen, IPenData } from '@src/src/app/types/pen';
import PenCreate from '../../farm/pen/pen-create';
import { apiPen } from '@src/src/app/api/pen';
import SubMenu from 'antd/lib/menu/SubMenu';
import moment from 'moment';
import 'moment/locale/vi';

// import vi from "moment/locale/vi";

export const navMenu: IMenu[] = [
    {
        name: 'Tổng quan',
        path: routesName.dashboard,
        isLoginRequired: true,
    },
    {
        name: 'Trang trại',
        path: routesName.farm,
        isLoginRequired: true,
        children: [
            {
                name: 'Chuồng nuôi',
                path: 'quan-ly-chuong',
                isLoginRequired: true,
            },
            {
                name: 'Chuồng trống',
                path: routesName.penEmpty,
                isLoginRequired: true,
            },
            {
                name: 'Camera',
                path: '/video',
                isLoginRequired: true,
            },
            {
                name: 'Thẻ nhiệt',
                path: routesName.tagPig,
                isLoginRequired: true,
            },
            // {
            //     name: 'Sự kiện',
            //     path: routesName.event,
            //     isLoginRequired: true,
            // },
        ],
    },
    // {
    //     name: 'Cá thể',
    //     path: routesName.pig,
    //     isLoginRequired: true,
    // },

    // {
    //     name: 'Báo cáo',
    //     path: '*',
    //     isLoginRequired: true,
    // },

    {
        name: ' Bệnh lợn',
        path: routesName.sickPig,
        isLoginRequired: true,
        children: [
            {
                name: 'Danh sách bệnh của lợn',
                path: routesName.sickPig,
                isLoginRequired: true,
            },
            {
                name: 'Chẩn đoán bệnh',
                path: routesName.sickPigDiagnosis,
                isLoginRequired: true,
            },
        ],
    },
    {
        name: 'Thức ăn',
        path: routesName.foodSupplier,
        isLoginRequired: true,
        children: [
            {
                name: 'Nhà cung cấp',
                path: routesName.foodSupplier,
                isLoginRequired: true,
            },
            {
                name: 'Danh mục thức ăn',
                path: routesName.food,
                isLoginRequired: true,
            },
            {
                name: 'Nhập thức ăn',
                path: routesName.foodOrder,
                isLoginRequired: true,
            },
            {
                name: 'Xuất thức ăn',
                path: routesName.foodConsume,
                isLoginRequired: true,
            },
        ],
    },
    {
        name: 'Phòng dịch bệnh',
        path: routesName.vaccine_supplier,
        isLoginRequired: true,
        children: [
            {
                name: 'Hãng sản xuất',
                path: routesName.vaccine_supplier,
                isLoginRequired: true,
            },
            {
                name: 'Danh mục Thuốc/Vacxin',
                path: routesName.vaccine_list,
                isLoginRequired: true,
            },
            {
                name: 'Nhập Thuốc/Vacxin',
                path: routesName.vaccine_order,
                isLoginRequired: true,
            },
            {
                name: 'Xuất Thuốc/Vacxin',
                path: routesName.vaccine_consume,
                isLoginRequired: true,
            },
            {
                name: 'Kế hoạch tiêm phòng',
                path: routesName.vaccine_plan,
                isLoginRequired: true,
            }
            // {
            //     name: 'Kế hoạch tiêm phòng',
            //     path: routesName.vaccine_plan,
            //     isLoginRequired: true,
            // },
        ]
    },
    {
        name: 'Báo cáo',
        path: routesName.reportSickPig,
        isLoginRequired: true,
        children: [
            {
                name: 'Danh sách lợn bị bệnh',
                path: routesName.reportSickPig,
                isLoginRequired: true,
            },
            {
                name: 'Danh sách lợn bất thường',
                path: routesName.reportUnusualPig,
                isLoginRequired: true,
                moreChildren: [
                    {
                        name: 'Danh sách lợn bất thường hành vi',
                        path: routesName.reportUnusualPig,
                        isLoginRequired: true,
                    },
                    {
                        name: 'Danh sách lợn bất thường nhiệt độ',
                        path: routesName.reportTempPig,
                        isLoginRequired: true,
                    }
                ]
            },
            {
                name: 'Thống kê thức ăn',
                path: routesName.reportFoodConsume,
                isLoginRequired: true,
                moreChildren: [
                    {
                        name: 'Thống kê thức ăn nhập kho',
                        path: routesName.reportFoodConsume,
                        isLoginRequired: true,
                    },
                    {
                        name: 'Thống kê thức ăn xuất kho',
                        path: routesName.reportFoodOrder,
                        isLoginRequired: true,
                    },
                    {
                        name: 'Thống kê thức ăn tồn kho',
                        path: routesName.reportFoodInventory,
                        isLoginRequired: true,
                    },
                ]
            }
            // {
            //     name: 'Thống kê thức ăn nhập kho',
            //     path: routesName.reportFoodConsume,
            //     isLoginRequired: true,
            // },
            // {
            //     name: 'Thống kê thức ăn xuất kho',
            //     path: routesName.reportFoodOrder,
            //     isLoginRequired: true,
            // },
            // {
            //     name: 'Thống kê thức ăn tồn kho',
            //     path: routesName.reportFoodInventory,
            //     isLoginRequired: true,
            // },
            // {
            //     name: 'Thống kê thuốc nhập kho',
            //     path: routesName.reportPharmacyConsume,
            //     isLoginRequired: true,
            // },
            // {
            //     name: 'Thống kê thuốc xuất kho',
            //     path: routesName.reportPharmacyOrder,
            //     isLoginRequired: true,
            // },
            // {
            //     name: 'Thống kê thuốc tồn kho',
            //     path: routesName.reportPharmacyInventory,
            //     isLoginRequired: true,
            // },
        ],
    },

    // {
    //     name: 'Giới thiệu',
    //     path: routesName.introduction,
    //     isLoginRequired: false,
    // },
    // {
    //     name: 'Sản phẩm',
    //     path: routesName.products,
    //     isLoginRequired: false,
    // },
    // {
    //     name: 'Hỗ trợ',
    //     path: routesName.support,
    //     isLoginRequired: false,
    // },
    // {
    //     name: 'Blog',
    //     path: routesName.blog,
    //     isLoginRequired: false,
    // },
];

export const navMenuAdmin: IMenu[] = [
    {
        name: 'Tổng quan',
        path: routesName.profile,
        isLoginRequired: true,
    },
    {
        name: 'Quản lý người dùng',
        path: routesName.profile_userList,
        isLoginRequired: true,
    },
    {
        name: 'Quản lý trang trại',
        path: routesName.profile_allFarmList,
        isLoginRequired: true,
    },
    {
        name: 'Quản lý bệnh lợn',
        path: routesName.profile_sickPigs,
        isLoginRequired: true,
    },
    {
        name: 'Quản lý dữ liệu nhiệt',
        path: '/profile/the-nhiet',
        isLoginRequired: true,
    },
    {
        name: 'Quản lý dữ liệu hành vi',
        path: '/profile/hanh-vi',
        isLoginRequired: true,
    },
    {
        name: 'Quản lý cá thể lợn',
        path: '/profile/ca-the-lon',
        isLoginRequired: true,
    },
    {
        name: 'Quản lý mô hình',
        path: routesName.profile_modal,
        isLoginRequired: true,
    },
    {
        name: 'Quản lý bản tin',
        path: routesName.news_pig,
        isLoginRequired: true,
    },
];

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        modalContent: {
            backgroundColor: 'white',
            width: '60%',
        },
        showDropdownFarm: {
            opacity: '1!important' as '1',
            transform: 'scaleY(1)!important' as 'scaleY(1)',
            visibility: 'visible!important' as 'visible',
            zIndex: '1900!important' as any
        },
        tmp: {
            zIndex: 1
        },
        highZindex: {
            zIndex: '1900!important' as any,
            backgroundColor: 'white'
        },
    }),
);

const customStyles = {
    control: (base: any, state: any) => ({
        ...base,
        borderColor: '#FFFFFF !important',
    }),
};

export const renderMenu = (user?: ILoginUser, menuData = navMenu, menuClassName = '', path = '') => {
    const currentFarm = farmService.getCurrentFarm();
    const dispatch = useDispatchRoot();
    const history = useHistory();


    useEffect(() => {
        console.log(window.location.pathname);
        const divElements = document.querySelectorAll('.underline-menu');
        divElements.forEach((div) => {
            if (div.classList.contains('active')) {
                div.classList.remove('active');
            }
        });

        if (window.location.pathname === '/tong-quan') {
            const divElement = document.getElementById('/tong-quan');
            divElement?.classList.add('active');
        }
        if (window.location.pathname === '/trang-trai' || window.location.pathname === '/quan-ly-chuong' || window.location.pathname === '/chuong-trong' || window.location.pathname === '/tag-pig' || window.location.pathname === '/video') {
            const divElement = document.getElementById('/trang-trai');
            divElement?.classList.add('active');
        }
        if (window.location.pathname === '/benh-lon' || window.location.pathname === '/chan-doan-benh-lon') {
            const divElement = document.getElementById('/benh-lon');
            divElement?.classList.add('active');
        }
        if (window.location.pathname === '/nha-cung-cap' || window.location.pathname === '/danh-muc-thuc-pham' || window.location.pathname === '/quan-ly-nhap-thuc-pham' || window.location.pathname === '/xuat-kho') {
            const divElement = document.getElementById('/nha-cung-cap');
            divElement?.classList.add('active');
        }

        if (window.location.pathname === '/bao-cao-lon-benh'
            || window.location.pathname === '/bao-cao-lon-bat-thuong'
            || window.location.pathname === '/bao-cao-thuc-an'
            || window.location.pathname === '/bao-cao-thuc-an-nhap'
            || window.location.pathname === '/bao-cao-thuc-an-nhap-kho'
            || window.location.pathname === '/bao-cao-thuoc-nhap-kho'
            || window.location.pathname === '/bao-cao-thuoc-xuat-kho'
            || window.location.pathname === '/bao-cao-vaccine-ton-kho'
        ) {
            const divElement = document.getElementById('/bao-cao-lon-benh');
            divElement?.classList.add('active');
        }
        if (window.location.pathname === '/profile') {
            const divElement = document.getElementById('/profile');
            divElement?.classList.add('active');
        }
        if (window.location.pathname.includes('/profile/quan-ly-nguoi-dung')) {
            const divElement = document.getElementById('/profile/quan-ly-nguoi-dung');
            divElement?.classList.add('active');
        }
        if (window.location.pathname === '/profile/quan-ly-tat-ca-trang-trai') {
            const divElement = document.getElementById('/profile/quan-ly-tat-ca-trang-trai');
            divElement?.classList.add('active');
        }
        if (window.location.pathname === '/profile/quan-ly-benh-lon') {
            const divElement = document.getElementById('/profile/quan-ly-benh-lon');
            divElement?.classList.add('active');
        }

        if (window.location.pathname === '/profile/mo-hinh') {
            const divElement = document.getElementById('/profile/mo-hinh');
            divElement?.classList.add('active');
        }

        if (window.location.pathname === '/vaccine-ke-hoach' || window.location.pathname === '/vaccine-danh-sach' || window.location.pathname === '/vaccine-nhap' || window.location.pathname === '/vaccine-xuat' || window.location.pathname === '/vaccine-hang-san-xuat') {
            const divElement = document.getElementById('/vaccine-hang-san-xuat');
            divElement?.classList.add('active');
        }



    }, [window.location.pathname])
    // const { currentProcess, isShowGuideline } = useSelectorRoot(state => state.guideline)
    // const dispatch = useDispatchRoot();
    // const [lstSteps, setLstSteps] = React.useState<IStep[]>(stepProcessData);
    // const [dropdownFarmGuidelineStyle, setDropdownFarmGuidelineStyle] = React.useState<any>({
    //     top: 200,
    //     right: 10,
    //     zIndex: 800
    // });
    // const dropdownFarmMainContentRef = React.useRef<HTMLDivElement>(null);

    // const showAuthModal = () => (e: any) => {
    //     dispatch(setIsShowGuideline(true));
    // }

    // React.useEffect(() => {
    //     const offsetLeft = dropdownFarmMainContentRef.current?.offsetLeft ?? 1;
    //     const offsetHeight = dropdownFarmMainContentRef.current?.offsetHeight ?? 1;
    //     const move = offsetLeft + 150;
    //     const offsetTop = dropdownFarmMainContentRef.current?.offsetTop ? dropdownFarmMainContentRef.current?.offsetTop : 1;
    //     setDropdownFarmGuidelineStyle({
    //         top: offsetTop,
    //         left: move
    //     })
    // }, [])

    // const handleOkInDropdownFarm = () => {
    //     dispatch(setCurrentProcess(AllStepsProcess.CreateANewPen));
    //     history.push('chuong-trong');
    // }
    // const handleOkInGoToActivePens = () => {
    //     history.push('quan-ly-chuong');
    //     dispatch(setIsShowGuideline(false));
    // }
    const hadnleClickLink = () => {
        dispatch(setTagPenId(undefined));
    }

    const [selectedKey, setSelectedKey] = useState('');

    // Update the selected key when the route changes
    useEffect(() => {
        const unlisten = history.listen((location) => {
            setSelectedKey(location.pathname);
        });

        // Initialize the selected key based on the current route
        setSelectedKey(history.location.pathname);

        return unlisten;
    }, [history]);

    return (
        // <div>
        //     <ul className={`${menuClassName} ` + (user?.role === 'ADMIN' && 'Admin')}>
        //         {/* {(isShowGuideline && path === routesName?.farm && location.pathname === routesName?.dashboard && currentProcess === AllStepsProcess.DropdownFarm) && <GuideLine guideLineRenderProps={{
        //             currentProcess: currentProcess, title: '', lstSteps, style: dropdownFarmGuidelineStyle, modalText: 'Bạn cần tạo chuồng để quản lý lợn trong trang trại. Bằng cách chọn mục "Trang trại", tiếp tục chọn mục "Chuồng trống"',
        //             visible: isShowGuideline && currentProcess === AllStepsProcess.DropdownFarm, confirmLoading: true, handleCancel: () => { dispatch(setIsShowGuideline(false)) }, handleOk: handleOkInDropdownFarm
        //         }} />}
        //         {(isShowGuideline && path === routesName?.farm && location.pathname === routesName?.dashboard && currentProcess === AllStepsProcess.GoToActivePens) && <GuideLine guideLineRenderProps={{
        //             currentProcess: currentProcess, title: '', lstSteps, style: dropdownFarmGuidelineStyle, modalText: 'Chuồng nuôi vừa tạo đã được cập nhật trong mục "Chuồng nuôi".',
        //             visible: isShowGuideline && currentProcess === AllStepsProcess.GoToActivePens, confirmLoading: true, handleCancel: () => { dispatch(setIsShowGuideline(false)) }, handleOk: handleOkInGoToActivePens
        //         }} />} */}
        //         {menuData.map((v, i) => {
        //             if ((!v.isLoginRequired && user) || (v.isLoginRequired && !user) || (v.isLoginRequired && !currentFarm))
        //                 return;

        //             return (
        //                 <li key={i}>
        //                     <Link style={{ display: 'flex', alignItems: 'center', gap: 5 }} to={v.path} className={'underline-menu'} id={v.path} onClick={hadnleClickLink}>
        //                         {v.name}
        //                         {v.children &&
        //                             <IoMdArrowDropdown />
        //                         }
        //                     </Link>
        //                     {v.children ? <>{renderMenu(user, v.children, 'sub-menu', v.path)}</> : ''}

        //                 </li>
        //             );
        //         })}
        //     </ul>
        // </div>
        <>
            {(user && user.role !== 'ADMIN') &&
                <Menu mode="horizontal" selectedKeys={[selectedKey]} className='menu-lst' >
                    <SubMenu
                        title="Tổng quan"
                        key={routesName.dashboard}
                        className="custom-submenu tong-quan"
                        onTitleClick={() => history.push(routesName.dashboard)}
                    >
                        {/* <Link to={routesName.dashboard}>Tổng quan</Link> */}
                    </SubMenu>
                    <SubMenu
                        title="Trang trại"
                        className="custom-submenu farm"
                        onTitleClick={() => history.push(routesName.farm)}
                    >
                        <Menu.Item key={routesName.barn}>
                            <Link to={routesName.barn}>Chuồng nuôi</Link>
                        </Menu.Item>
                        <Menu.Item key={routesName.penEmpty}>
                            <Link to={routesName.penEmpty}>Chuồng trống</Link>
                        </Menu.Item>
                        <Menu.Item key={routesName.video}>
                            <Link to={routesName.video}>Video</Link>
                        </Menu.Item>
                        <Menu.Item key={routesName.tagPig}>
                            <Link to={routesName.tagPig}>Thẻ nhiệt</Link>
                        </Menu.Item>
                        <Menu.Item key={routesName.gateway_farms}>
                            <Link to={routesName.gateway_farms}>Danh sách Gateway</Link>
                        </Menu.Item>
                    </SubMenu>
                    <SubMenu
                        title="Bệnh lợn"
                        className="custom-submenu sick-pig"
                        onTitleClick={() => history.push(routesName.sickPig)}
                    >
                        <Menu.Item key={routesName.sickPig}>
                            <Link to={routesName.sickPig}>Danh sách bệnh của lợn</Link>
                        </Menu.Item>
                        <Menu.Item key={routesName.sickPigDiagnosis}>
                            <Link to={routesName.sickPigDiagnosis}>Chẩn đoán bệnh</Link>
                        </Menu.Item>
                    </SubMenu>
                    <SubMenu
                        title="Thức ăn"
                        className="custom-submenu food"
                        onTitleClick={() => history.push(routesName.foodSupplier)}
                    >
                        <Menu.Item key={routesName.foodSupplier}>
                            <Link to={routesName.foodSupplier}>Nhà cung cấp</Link>
                        </Menu.Item>
                        <Menu.Item key={routesName.food}>
                            <Link to={routesName.food}>Danh mục thức ăn</Link>
                        </Menu.Item>
                        <Menu.Item key={routesName.foodOrder}>
                            <Link to={routesName.foodOrder}>Nhập thức ăn</Link>
                        </Menu.Item>
                        <Menu.Item key={routesName.foodConsume}>
                            <Link to={routesName.foodConsume}>Xuất thức ăn</Link>
                        </Menu.Item>
                    </SubMenu>
                    <SubMenu
                        title="Phòng dịch bệnh"
                        className="custom-submenu vaccine"
                        onTitleClick={() => history.push(routesName.vaccine_supplier)}
                    >
                        <Menu.Item key={routesName.vaccine_supplier}>
                            <Link to={routesName.vaccine_supplier}>Hãng sản xuất</Link>
                        </Menu.Item>
                        <Menu.Item key={routesName.vaccine_list}>
                            <Link to={routesName.vaccine_list}>Danh mục Thuốc/Vacxin</Link>
                        </Menu.Item>
                        <Menu.Item key={routesName.vaccine_order}>
                            <Link to={routesName.vaccine_order}>Nhập Thuốc/Vacxin</Link>
                        </Menu.Item>
                        <Menu.Item key={routesName.vaccine_consume}>
                            <Link to={routesName.vaccine_consume}>Xuất Thuốc/Vacxin</Link>
                        </Menu.Item>
                        <Menu.Item key={routesName.vaccine_plan}>
                            <Link to={routesName.vaccine_plan}>Kế hoạch tiêm phòng</Link>
                        </Menu.Item>
                    </SubMenu>
                    <SubMenu
                        title="Báo cáo"
                        className="custom-submenu report"
                        onTitleClick={() => history.push(routesName.reportSickPig)}
                    >
                        <Menu.Item key={routesName.reportSickPig}>
                            <Link to={routesName.reportSickPig}>Danh sách lợn bị bệnh</Link>
                        </Menu.Item>
                        <SubMenu
                            title="Danh sách lợn bất thường"
                            className="custom-submenu sub-title-report"
                            onTitleClick={() => history.push(routesName.reportUnusualPig)}
                        >
                            <Menu.Item key={routesName.reportUnusualPig}>
                                <Link to={routesName.reportUnusualPig}>Danh sách lợn bất thường hành vi</Link>
                            </Menu.Item>
                            <Menu.Item key={routesName.reportTempPig}>
                                <Link to={routesName.reportTempPig}>Danh sách lợn bất thường nhiệt độ</Link>
                            </Menu.Item>

                        </SubMenu>
                        <SubMenu
                            title="Thống kê thức ăn"
                            className="custom-submenu sub-title-report"
                            onTitleClick={() => history.push(routesName.reportFoodConsume)}

                        >
                            <Menu.Item key={routesName.reportFoodConsume}>
                                <Link to={routesName.reportFoodConsume}>Thống kê thức ăn nhập kho</Link>
                            </Menu.Item>
                            <Menu.Item key={routesName.reportFoodOrder}>
                                <Link to={routesName.reportFoodOrder}>Thống kê thức ăn xuất kho</Link>
                            </Menu.Item>
                            <Menu.Item key={routesName.reportFoodInventory}>
                                <Link to={routesName.reportFoodInventory}>Thống kê thức ăn tồn kho</Link>
                            </Menu.Item>
                        </SubMenu>
                        <SubMenu
                            title="Thống kê thuốc/vacxin"
                            className="custom-submenu sub-title-report"
                            onTitleClick={() => history.push(routesName.reportPharmacyConsume)}
                        >
                            <Menu.Item key={routesName.reportPharmacyConsume}>
                                <Link to={routesName.reportPharmacyConsume}>Thống kê thuốc/vacxin nhập kho</Link>
                            </Menu.Item>
                            <Menu.Item key={routesName.reportPharmacyOrder}>
                                <Link to={routesName.reportPharmacyOrder}>Thống kê thuốc/vacxin xuất kho</Link>
                            </Menu.Item>
                            <Menu.Item key={routesName.reportPharmacyInventory}>
                                <Link to={routesName.reportPharmacyInventory}>Thống kê thuốc/vacxin tồn kho</Link>
                            </Menu.Item>
                        </SubMenu>
                    </SubMenu>
                </Menu >
            }
            {(user && user.role === 'ADMIN') &&
                <Menu mode="horizontal" selectedKeys={[selectedKey]} className='menu-lst-admin'>
                    <SubMenu
                        title="Tổng quan"
                        key={routesName.profile}
                        className="custom-submenu tong-quan"
                        onTitleClick={() => history.push(routesName.profile)}
                    />
                    <SubMenu
                        key={routesName.profile_userList}
                        title="Quản lý người dùng"
                        className="custom-submenu profile"
                        onTitleClick={() => history.push(routesName.profile_userList)}
                    />
                    <SubMenu
                        key={routesName.profile_allFarmList}
                        title="Quản lý trang trại"
                        className="custom-submenu profile"
                        onTitleClick={() => history.push(routesName.profile_allFarmList)}
                    />
                    <SubMenu
                        key={'/profile/ca-the-lon'}
                        title="Quản lý cá thể lợn"
                        className="custom-submenu profile"
                        onTitleClick={() => history.push('/profile/ca-the-lon')}
                    />
                    <SubMenu
                        key={routesName.profile_sickPigs}
                        title="Quản lý bệnh lợn"
                        className="custom-submenu profile"
                        onTitleClick={() => history.push(routesName.profile_sickPigs)}
                    />
                    <SubMenu
                        key={'/profile/the-nhiet'}
                        title="Quản lý dữ liệu nhiệt"
                        className="custom-submenu profile"
                        onTitleClick={() => history.push('/profile/the-nhiet')}
                    />
                    <SubMenu
                        key={'/profile/hanh-vi'}
                        title="Quản lý dữ liệu hành vi"
                        className="custom-submenu profile"
                        onTitleClick={() => history.push('/profile/hanh-vi')}
                    />
                    <SubMenu
                        key={routesName.profile_modal}
                        title="Quản lý mô hình"
                        className="custom-submenu profile"
                        onTitleClick={() => history.push(routesName.profile_modal)}
                    />
                    <SubMenu
                        key={routesName.news_pig}
                        title="Quản lý bản tin"
                        className="custom-submenu profile"
                        onTitleClick={() => history.push(routesName.news_pig)}
                    />

                </Menu >
            }
        </>
    );
};
const Header: FC<any> = () => {
    const [farms, setFarms] = useState<Array<any>>([]);
    const [isShowLoginForm, setIsShowLoginForm] = React.useState(true);
    const [authFormType, setAuthFormType] = React.useState(EAuthFormType.Login);

    const [isShowAuthModal, toggleAuthModal] = useToggleModal();
    const { authModalOrigin } = useSelectorRoot(state => state.login)
    const context = React.useContext(Context);

    const user = userServices.get();
    const history = useHistory();
    const dispatch = useDispatchRoot();
    const { lstFarms, showNotification, tokenPublic } = useSelectorRoot(state => state.farm)

    const [isClickAbnormal, setIsClickAbnormal] = React.useState(true);
    const [isClickBehavior, setIsClickBehavior] = React.useState(true);
    const [isClickTemperture, setIsClickTemperture] = React.useState(false);
    const [isClickAi, setIsClickAi] = React.useState(false);
    const [isClickVaccinationSchedule, setIsClickVaccinationSchedule] = React.useState(false);
    const [newMenuData, setNewMenuData] = useState<any>([]);
    const [notificationCount, setNotificationCount] = useState(0);
    const [idClickItemMenu, setIdClickItemMenu] = React.useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [penSelected, setPenSelected] = useState<any>(null);
    const [openCU, setOpenCU] = useState(false);
    const classes = useToolbarStyles();
    const currentFarm = farmService.getCurrentFarm();
    const [openTemp, setOpenTemp] = useState(false);
    const [offset, setOffset] = useState(0); // Vị trí bắt đầu lấy dữ liệu
    const [size, setSize] = useState(1000);
    const [isShowEditUser, setIsShowEditUser] = React.useState(false);
    const [form] = Form.useForm(); // Form thêm bệnh lợn
    const [formChangePassword] = Form.useForm(); // Form thêm bệnh lợn
    const [dataLstFarm, setDataLstFarm] = useState<any>([]);
    const [isShowChangePassword, setIsShowChangePassword] = React.useState(false);
    const [isDeleteUser, setIsDeleteUser] = React.useState(false);

    const [show, setShow] = useState(false);
    const [notificationBrowser, setNotificationBrowser] = useState({ title: '', body: '' });
    const [isTokenFound, setTokenFound] = useState<string>('');

    const [isShowNotificationImage, setIsShowNotificationImage] = useState(false);
    const [itemNotification, setItemNotification] = useState<any>(null);

    useEffect(() => {

        if (user) {
            fetchToken(setTokenFound);
        }
    }, [user?.id]);

    onMessageListener()
        .then(payload => {
            if (!user) return;
            setNotificationBrowser({ title: payload.notification.title, body: payload.notification.body })
            setShow(true);
            console.log(payload);
            notification.open({
                message: payload.data.title,
                description: payload.data.body,
            } as any);
            setTimeout(() => {
                location.reload();
            }, 5000);
        })
        .catch(err => console.log('failed: ', err));

    useEffect(() => {
        console.log(isTokenFound);
        if (isTokenFound && user) {
            dispatch(setTokenPublic(isTokenFound))
        }

    }, [isTokenFound])

    useEffect(() => {
        if (tokenPublic && user) {
            postClientToken(tokenPublic);
        }
    }, [tokenPublic]);

    const postClientToken = async (token: string) => {
        const req = {
            token: token,
            userId: user?.id,
            additionalProp1: {}
        }
        await axios.post(`https://sit.api.pigman.com.vn/clientToken`, req)
            .then(res => {
                console.log(res);

            })
            .catch(err => {
                console.log(err);
            })
    };


    useEffect(() => {
        console.log('lstFarms', lstFarms);
        if (lstFarms?.length > 0) {
            const newArray = lstFarms?.filter((obj: { label: string; }) => obj.label !== farmService?.getCurrentFarm()?.name);
            setDataLstFarm(newArray);
        }
        else {
            setDataLstFarm(lstFarms);
        }

        // console.log('newArray', newArray);
    }, [lstFarms]);

    useEffect(() => {
        fetchData();
        const interval = setInterval(() => {
            fetchData();
        }, 30 * 1000);
        return () => clearInterval(interval);
    }, [currentFarm?.id, isClickBehavior, isClickTemperture, isClickAi, isClickVaccinationSchedule]);

    const fetchData = async () => {
        if (user && user.role !== 'ADMIN' && currentFarm && currentFarm.id) {
            let type = ''
            if (isClickBehavior) {
                type = 'Behaviour';
            }
            else if (isClickTemperture) {
                type = 'Temperature';
            }
            else if (isClickAi) {
                type = 'AISuggest';
            }
            else {
                type = 'VaccinationSchedule';
            }
            console.log(user.id, currentFarm.id, type);

            axios.get(`https://sit.api.pigman.com.vn/notification?userId=${user.id}&farmId=${currentFarm.id}&type=${type}&size=100`)
                .then(res => {
                    console.log(res.data.data[0].items);
                    const tmp = res.data.data[0].items.map((item: any) => {
                        // // Kiểm tra nếu thông báo trước 5 ngày thì không hiển thị
                        // const date = new Date();
                        // const dateNotification = new Date(item.createdDate);
                        // const dateDiff = Math.abs(date.getTime() - dateNotification.getTime());
                        // const days = Math.floor(dateDiff / (1000 * 3600 * 24));
                        // console.log(days);

                        // if (days <= 5) {
                        //     return item;
                        // }
                    });
                    setNewMenuData(res.data.data[0].items);
                })
        }
    }

    const handleOpenDropdown = () => {
        setIsOpen(true);
    };

    const handleCloseDropdown = () => {
        setIsOpen(false);
    };

    // Hàm thực hiện tính toán thời gian
    const handleChangeTime = (time: Date) => {
        // const options = { timeZone: 'Asia/Ho_Chi_Minh' };
        // const currentDateString = new Date().toLocaleString('en-US', options);
        // const timeDateString = new Date(time).toLocaleString('en-US', options);
        // // console.log(timeDateString);
        // // console.log(currentDateString);


        // // Convert the current date to UTC
        // const currentDate = new Date(currentDateString);
        // const timeDate = new Date(timeDateString);
        // console.log('currentDate', currentDate);
        // console.log('timeDate', timeDate);

        // Calculate the time difference in milliseconds
        const timeDiff = Math.abs(new Date().getTime() - time.getTime());
        const seconds = Math.floor(timeDiff / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const dateTimeAgo = moment(time).locale('vi').fromNow();
        return dateTimeAgo;
        // console.log(dateTimeAgo);

        // // Determine the appropriate time unit based on the time difference
        // let timeUnit;
        // let timeValue;

        // if (days > 0) {
        //     timeUnit = 'ngày';
        //     timeValue = days;
        // } else if (hours > 0) {
        //     timeUnit = 'giờ';
        //     timeValue = hours;
        // } else if (minutes > 0) {
        //     timeUnit = 'phút';
        //     timeValue = minutes;
        // } else {
        //     timeUnit = 'giây';
        //     timeValue = seconds;
        // }

        // // Construct the output message based on the time difference
        // const outputMsg = `${timeValue} ${timeUnit} trước`;
        // if (timeValue <= 0) return 'Vừa xong';

        // return outputMsg;
    }

    useEffect(() => {
        if (user && user.role !== 'ADMIN' && currentFarm && currentFarm.id) {
            let type = ''
            if (isClickBehavior) {
                type = 'Behaviour';
            }
            else if (isClickTemperture) {
                type = 'Temperature';
            }
            else if (isClickAi) {
                type = 'AISuggest';
            }
            else {
                type = 'VaccinationSchedule';
            }
            // axios.get(`https://sit.api.pigman.com.vn/notification?userId=${user?.id}&farmId=${currentFarm?.id}&type=${type}&size=1000`)
            //     .then(res => {
            //         setNewMenuData(res.data.data[0].items);
            //     })

            axios.get(`https://sit.api.pigman.com.vn/notification?userId=${user?.id}&farmId=${currentFarm?.id}&size=1000&isRead=false`)
                .then(res => {
                    setNotificationCount(res.data.data[0].items.length);
                })
        }
    }, [isClickBehavior, isClickTemperture, isClickAi, isClickVaccinationSchedule]);

    const handleClickMenuItem = async (item: any) => {
        setItemNotification(item);
        if (isClickBehavior || isClickTemperture) {
            setIsShowNotificationImage(true);
            setIsOpen(false);
            await axios.put(`https://sit.api.pigman.com.vn/notification/${item.id}/read`)
                .then(async res => {
                    if (user && currentFarm && currentFarm.id) {
                        let type = ''
                        if (isClickBehavior) {
                            type = 'Behaviour';
                        }
                        else if (isClickTemperture) {
                            type = 'Temperature';
                        }
                        else if (isClickAi) {
                            type = 'AISuggest';
                        }
                        else {
                            type = 'VaccinationSchedule';
                        }
                        await axios.get(`https://sit.api.pigman.com.vn/notification?userId=${user.id}&farmId=${currentFarm?.id}&type=${type}&size=1000`)
                            .then(res => {
                                setNewMenuData(res.data.data[0].items);
                            })


                        // Lấy ra số thông báo chưa đọc
                        await axios.get(`https://sit.api.pigman.com.vn/notification?userId=${user.id}&farmId=${currentFarm?.id}&size=1000&isRead=false`)
                            .then(res => {
                                console.log(res.data.data);

                                setNotificationCount(res.data.data[0].items.length);
                            })

                    }
                })
        }
        else {
            axios.put(`https://sit.api.pigman.com.vn/notification/${item.id}/read`)
                .then(res => {
                    if (user && currentFarm && currentFarm.id) {
                        let type = ''
                        if (isClickBehavior) {
                            type = 'Behaviour';
                        }
                        else if (isClickTemperture) {
                            type = 'Temperature';
                        }
                        else if (isClickAi) {
                            type = 'AISuggest';
                        }
                        else {
                            type = 'VaccinationSchedule';
                        }
                        axios.get(`https://sit.api.pigman.com.vn/notification?userId=${user.id}&farmId=${currentFarm?.id}&type=${type}&size=1000`)
                            .then(res => {
                                setNewMenuData(res.data.data[0].items);
                            })


                        // Lấy ra số thông báo chưa đọc
                        axios.get(`https://sit.api.pigman.com.vn/notification?userId=${user.id}&farmId=${currentFarm?.id}&size=1000&isRead=false`)
                            .then(res => {
                                console.log(res.data.data);

                                setNotificationCount(res.data.data[0].items.length);
                            })

                        console.log(item.tagIds);

                        if (type === 'VaccinationSchedule') {
                            history.push(`/vaccine-ke-hoach`);
                        }
                        else {

                            if (item.tagIds.length > 0) {
                                if (item.penId === '000000000000000000000000' || !item.penId) {
                                    notification.warning({
                                        message: 'Thông báo',
                                        description: 'Thẻ lợn này chưa được gán vào trong chuồng',
                                    })
                                    setIsOpen(false);
                                    history.push(`/tag-pig`)
                                }
                                else {
                                    console.log(item);
                                    getPenSelect(item);
                                    const req = {
                                        id: item.temperatureTagIds[0],
                                        tagId: item.tagIds[0],
                                    }
                                    dispatch(setTempPigId(req));
                                    loadTempTagByPen(item.penId);
                                    setIsOpen(false);
                                    history.push(`/quan-ly-chuong`)
                                }
                            }
                            else {
                                const req = {
                                    offset: 0,
                                    size: 100,
                                    farmId: currentFarm.id,
                                    additionalProp1: {}
                                }
                                // Lấy ra chuồng theo id
                                axios.post(`https://sit.api.pigman.com.vn/pens/all`, req)
                                    .then(res => {
                                        console.log();
                                        const dataItem = res.data.data.items.find((pen: { id: any; }) => pen.id === item.penId);
                                        setPenSelected(dataItem);
                                    })
                            }
                        }

                    }
                })

        }

    }

    const handleClickNotificationImage = (item: any) => {
        axios.put(`https://sit.api.pigman.com.vn/notification/${item.id}/read`)
            .then(res => {
                if (user && currentFarm && currentFarm.id) {
                    let type = ''
                    if (isClickBehavior) {
                        type = 'Behaviour';
                    }
                    else if (isClickTemperture) {
                        type = 'Temperature';
                    }
                    else if (isClickAi) {
                        type = 'AISuggest';
                    }
                    else {
                        type = 'VaccinationSchedule';
                    }
                    axios.get(`https://sit.api.pigman.com.vn/notification?userId=${user.id}&farmId=${currentFarm?.id}&type=${type}&size=1000`)
                        .then(res => {
                            setNewMenuData(res.data.data[0].items);
                        })


                    // Lấy ra số thông báo chưa đọc
                    axios.get(`https://sit.api.pigman.com.vn/notification?userId=${user.id}&farmId=${currentFarm?.id}&size=1000&isRead=false`)
                        .then(res => {
                            console.log(res.data.data);

                            setNotificationCount(res.data.data[0].items.length);
                        })

                    console.log(item.tagIds);

                    if (type === 'VaccinationSchedule') {
                        history.push(`/vaccine-ke-hoach`);
                    }
                    else {

                        if (item.tagIds.length > 0) {
                            if (item.penId === '000000000000000000000000' || !item.penId) {
                                notification.warning({
                                    message: 'Thông báo',
                                    description: 'Thẻ lợn này chưa được gán vào trong chuồng',
                                })
                                setIsOpen(false);
                                history.push(`/tag-pig`)
                            }
                            else {
                                console.log(item);
                                getPenSelect(item);
                                const req = {
                                    id: item.temperatureTagIds[0],
                                    tagId: item.tagIds[0],
                                }
                                dispatch(setTempPigId(req));
                                loadTempTagByPen(item.penId);
                                setIsOpen(false);
                                history.push(`/quan-ly-chuong`)
                            }
                        }
                        else {
                            const req = {
                                offset: 0,
                                size: 100,
                                farmId: currentFarm.id,
                                additionalProp1: {}
                            }
                            // Lấy ra chuồng theo id
                            axios.post(`https://sit.api.pigman.com.vn/pens/all`, req)
                                .then(res => {
                                    console.log();

                                    const dataItem = res.data.data.items.find((pen: { id: any; }) => pen.id === item.penId);
                                    setPenSelected(dataItem);
                                })
                        }
                    }

                }
            })
    }

    const getPenSelect = (item: any) => {
        const req = {
            offset: 0,
            size: 100,
            farmId: currentFarm.id,
            additionalProp1: {}
        }

        if (isClickBehavior) {
            // dispatch(setClickNotificationType('abnormal'));
            dispatch(setClickNotificationType('behavior'));
        }
        else if (isClickTemperture) {
            dispatch(setClickNotificationType('temperture'));
        }
        else if (isClickAi) {
            dispatch(setClickNotificationType('ai'));
        }
        else {
            dispatch(setClickNotificationType('vaccination-schedule'));
        }
        // Lấy ra chuồng theo id
        axios.post(`https://sit.api.pigman.com.vn/pens/all`, req)
            .then(res => {
                const dataItem = res.data.data.items.find((pen: { id: any; }) => pen.id === item.penId);
                dispatch(setCpenSelected(dataItem));
            })
    }

    const loadTempTagByPen = async (id: string) => {
        axios.get(`https://sit.api.pigman.com.vn/temperatureTag/penId/${id}?offset=${offset}&size=${size}`)
            .then((res: any) => {
                console.log(res.data.data.items);
                if (res.data.data.items !== null && res.data.data.items.length > 0 && res.data.data.items[0] !== null) {
                    // Gán dữ liệu vào biến dataTagPigs
                    dispatch(setDataTagPigSick(res.data.data.items));
                }
                else {
                    notification['error']({
                        message: 'Không tìm thấy thẻ nhiệt',
                        description:
                            'Chưa có thẻ lợn nào được gắn vào chuồng này',
                    });
                    dispatch(setDataTagPigSick([]));
                }
            })
    }

    const handleRealAllNoti = async () => {
        let type = ''
        if (isClickBehavior) {
            type = 'Behaviour';
        }
        else if (isClickTemperture) {
            type = 'Temperature';
        }
        else if (isClickAi) {
            type = 'AISuggest';
        }
        else {
            type = 'VaccinationSchedule';
        }
        console.log(type);
        await axios.get(`https://sit.api.pigman.com.vn/notification/readAllNotification/${currentFarm?.id}?userId=${user?.id}&type=${type}&size=1000`)
            .then(res => {
                console.log(res);
                if (user && currentFarm && currentFarm.id) {
                    axios.get(`https://sit.api.pigman.com.vn/notification?userId=${user.id}&farmId=${currentFarm?.id}&type=${type}&size=1000`)
                        .then(res => {
                            setNewMenuData(res.data.data[0].items);
                        })

                    // Đánh dấu số thông báo đã đọc
                    axios.get(`https://sit.api.pigman.com.vn/notification?userId=${user.id}&farmId=${currentFarm?.id}&size=1000&isRead=false`)
                        .then(res => {
                            console.log(res.data.data);

                            setNotificationCount(res.data.data[0].items.length);
                        })
                }
            })
            .catch(err => {
                console.log(err);
            })


    }



    const notificationMenu = (
        <Menu>
            <div className='title-menu'>
                <div className='title-text'>Thông báo</div>
                <Tooltip title='Đánh dấu tất cả đã đọc'>
                    <FiMoreHorizontal className='' style={{ cursor: 'pointer' }} onClick={handleRealAllNoti} />
                </Tooltip>
            </div>
            <div className='action-groud'>
                {/* <Button className={isClickViewAll ? 'action' : ''} onClick={() => handleClickActionMenu('view-all')}>Tất cả</Button>
                <Button className={isClickNotSeen ? 'action' : ''} onClick={() => handleClickActionMenu('not-seen')}>Chưa xem</Button> */}
                <Button className={isClickBehavior ? 'action' : ''} onClick={() => handleClickActionMenu('behavior')}>Bất thường hành vi</Button>
                <Button className={isClickTemperture ? 'action' : ''} onClick={() => handleClickActionMenu('temperture')}>Bất thường nhiệt</Button>
                <Button className={isClickAi ? 'action' : ''} onClick={() => handleClickActionMenu('Ai')}>AI</Button>
                <Button className={isClickVaccinationSchedule ? 'action' : ''} onClick={() => handleClickActionMenu('vaccination-schedule')}>Lịch tiêm chủng</Button>
            </div>

            <div className='view-all-notification'>
                {/* <div className='title-view-all'>Mới nhất</div> */}
                <div className='view-all-content'>
                    {newMenuData && newMenuData.map((item: any, index: any) => (
                        <>
                            <div key={index} className={`view-all-item` + (item?.isRead ? ' active' : '')} onClick={() => handleClickMenuItem(item)}>
                                <div className={isClickTemperture ? 'avatar-temp' : 'avatar'}>
                                    <img src={isClickTemperture ? TempIcon : PigIcon} alt="" />
                                </div>
                                <div className='content'>
                                    <div className='title'>{item?.title}</div>
                                    <div className='description' title={item?.message}>{item?.message}</div>
                                    <div className='time'>{handleChangeTime(new Date(item?.createdDate))}</div>
                                </div>
                                <div className='icon'></div>
                            </div >
                        </>
                    ))
                    }
                </div>
                {/* <div className='title-view-all'>Cũ nhất</div>
                <div className='view-all-content'>
                    {OldMenuData && OldMenuData.map((item, index) => (
                        <>
                            <div key={index} id={`menu-${item.id}`} className={`view-all-item`} onClick={() => setIdClickItemMenu(item.id)}>
                                <div className='avatar'>
                                    <img src={PigIcon} alt="" />
                                </div>
                                <div className='content'>
                                    <div className='title'>{item.title}</div>
                                    <div className='description'>{item.description}</div>
                                    <div className='time'>{item.time}</div>
                                </div>
                                <div className='icon'></div>
                            </div >
                        </>
                    ))
                    }
                </div> */}
            </div>
        </Menu >
    );

    useEffect(() => {
        if (user && user.role !== 'ADMIN')
            getAllFarms();
    }, []);

    useEffect(() => {
        console.log('showNotification', showNotification);

        showNotification && setIsOpen(true)
    }, [showNotification])

    useEffect(() => {
        !isOpen && dispatch(setShowNotification(false))
    }, [isOpen])

    const getAllFarms = async () => {
        const data = {
            offset: 0,
            size: 100,
            ownerId: user?.id,
        };

        try {
            const farmList: any = [];
            const fetchFarm = (await apiFarm.getAll(data)) as any;
            fetchFarm.items.map((farm: any) => {
                farmList.push({ value: farm, label: farm.name });
            });
            setFarms(farmList);
            dispatch(updateFarmList(farmList));
        } catch (err) {
            console.log('err:' + JSON.stringify(err));
        }
    };

    const userLogout = () => {
        userServices.logout();
        farmService.removeCurrentFarm();
        history.push('/');
        deleteClientToken();
        location.reload();
        // dispatch(setIsCloseWarning(true));
        // toggleAuthModal(false)();
    };

    const deleteClientToken = async () => {
        await axios.delete(`https://sit.api.pigman.com.vn/clientToken?userId=${user?.id}&clientToken=${tokenPublic}`)

    }

    const handleInputChangeFarm = (selectedOption: any) => {
        console.log(selectedOption);
        const farm = lstFarms?.find((farm: any) => farm.value.id === selectedOption);
        if (!farm) return;
        const value = farm.value;
        dispatch(changeSelectedFarm(value))
        const currentFarmCookies: IFarmCookiesData = {
            address: value.address,
            id: value.id,
            name: value.name,
            ownerId: value?.ownerId
        }
        farmService.setCurrentFarm(currentFarmCookies);
        farmService.setCurrentFarm(value);
        value.pens && dispatch(fetchLstEmptyPens(value.pens))
        console.log(value)
        // location.reload();
        // updateLocation();
        context.changeLocation.onChangeLocation();

        const newArray = lstFarms?.filter((obj: any) => obj.value.id !== value.id);
        console.log('newArray', newArray);
        setDataLstFarm(newArray);

        location.reload();
    };

    const toggleAuthForm =
        (isShowLoginForm: boolean, formType: EAuthFormType, isToggleAuthForm?: boolean) => (e: any) => {
            setIsShowLoginForm(isShowLoginForm);
            setAuthFormType(formType);

            if (isToggleAuthForm) {
                toggleAuthModal(true)();
            }
        };

    const [userAvatar, setUserAvatar] = useState<any>(user ? `https://sit.api.pigman.com.vn/users/avatar/${user.id}` : defaultUser);

    const renderLogMenu = () => {
        let auth = (
            <>
                <div className="btn login-btn" onClick={toggleAuthForm(true, EAuthFormType.Login, true)}>
                    <p>Đăng nhập</p>
                </div>
                <div className="btn register-btn" onClick={toggleAuthForm(false, EAuthFormType.Register, true)}>
                    <p>Đăng ký</p>
                </div>
            </>
        );

        if (user) {
            auth = (
                <>
                    <div id="user">
                        <img className='user-avatar' src={userAvatar || defaultUser} alt="user-avatar" />
                        <div id="log-menu">
                            <ul>
                                <li>
                                    <div onClick={showEditUser}> Tài khoản </div>{' '}
                                </li>
                                <li>
                                    <span onClick={userChangePassword}>Đổi mật khẩu</span>{' '}
                                </li>
                                {user.role !== 'ADMIN' &&
                                    <li style={{ pointerEvents: 'none' }}>
                                        <span onClick={userDelete}> Xóa tài khoản </span>{' '}
                                    </li>
                                }
                                <li>
                                    <span onClick={userLogout}> Đăng xuất </span>{' '}
                                </li>

                            </ul>
                        </div>
                    </div>
                </>
            );
        }

        return auth;
    };

    useEffect(() => {
        const user = userServices.get();
        if (authModalOrigin === AuthPopupOrigin.GetStarted) {
            dispatch(setAuthPopupOrigin(AuthPopupOrigin.None))
            if (!user) {
                toggleAuthForm(false, EAuthFormType.Register, true)("");
            }
            else {
                history.push(routesName.selectFarm);
            }
        }
    }, [authModalOrigin])

    // useEffect(() => {
    //     setRerender(!rerender)
    // }, [currentProcess])

    const onLogoClick = () => {
        if (user.role === 'ADMIN') {
            history.push(routesName.profile);
        }
        else {
            history.push('/');
        }
    }

    const handleClickActionMenu = (type: string) => {
        switch (type) {
            case 'behavior':
                setIsClickBehavior(true);
                setIsClickTemperture(false);
                setIsClickAi(false);
                setIsClickVaccinationSchedule(false);
                break;

            case 'temperture':
                setIsClickBehavior(false);
                setIsClickTemperture(true);
                setIsClickAi(false);
                setIsClickVaccinationSchedule(false);
                break;
            case 'Ai':
                setIsClickBehavior(false);
                setIsClickTemperture(false);
                setIsClickAi(true);
                setIsClickVaccinationSchedule(false);
                break;
            case 'vaccination-schedule':
                setIsClickBehavior(false);
                setIsClickTemperture(false);
                setIsClickAi(false);
                setIsClickVaccinationSchedule(true);
        }

    }

    // const checkMenuItemIsActive = (id: string) => () => {
    //     const divElement = document.getElementById(`menu-${id}`) as HTMLElement;
    //     console.log(id);
    //     if (divElement.classList.contains('active')) {
    //         return true;
    //     }
    //     return false;
    // }

    useEffect(() => {
        if (idClickItemMenu === '') return;
        const divElement = document.getElementById(`menu-${idClickItemMenu}`) as HTMLElement;
        console.log(idClickItemMenu);

        if (!divElement.classList.contains('active')) {
            divElement.classList.add('active');
        }
    }, [idClickItemMenu]);

    const handleCloseCU = () => {
        setOpenCU(false);
    };

    const handleCancel = () => {
        setIsShowEditUser(false);
        setIsShowChangePassword(false);
        setIsDeleteUser(false);
        setIsShowNotificationImage(false);
    }

    const handleOk = () => {
        setIsShowEditUser(false);
        setIsShowChangePassword(false);
        setIsDeleteUser(false);
        setIsShowNotificationImage(false);

    }

    const onFinish = (values: any) => {
        console.log(values.userImage.file);

        const req = {
            address: values.address,
            dob: values.dob ? values.dob.format('YYYY-MM-DD') : '',
            fullName: values.userName,
            phone: values.phoneNumber,
            defaultFarm: '',
            gender: 0,
            identityCode: 'string',
            additionalProp1: {}
        }
        console.log(req);

        axios.put(`https://sit.api.pigman.com.vn/users/${user.id}`, req)
            .then(async res => {
                console.log(res.data);
                if (res.data.statusCode === "OK") {

                    if (values.userImage.file) {
                        const formData = new FormData();
                        formData.append('userId', user.id);
                        formData.append('profilePhoto', values.userImage.file);
                        try {
                            const res = await fetch('https://sit.api.pigman.com.vn/users/uploadAvatar', {
                                method: 'POST',
                                body: formData
                            })

                            const data = await res.json();

                            console.log(data);

                            if (data.statusCode === "OK") {
                                setUserAvatar(`https://sit.api.pigman.com.vn/users/avatar/${user.id}`);
                            }
                        }
                        catch (err) {
                            console.log(err);
                        }
                    }
                }

                notification.success({
                    message: 'Cập nhật thông tin thành công',
                })
                setIsShowEditUser(false);
                window.location.reload();

            })
    }

    const onFinishChangePassword = (values: any) => {
        console.log(values);
        const req = {
            oldPassword: values.oldPassword,
            password: values.newPassword,
            passwordConfirmation: values.confirmPassword,
            additionalProp1: {}
        }

        axios.put(`https://sit.api.pigman.com.vn/users/changePassword/${user.id}`, req)
            .then(res => {
                console.log(res.data);
                if (res.data.statusCode === "OK") {
                    notification.success({
                        message: 'Đổi mật khẩu thành công',
                    })
                    setIsShowChangePassword(false);
                }
                else {
                    notification.error({
                        message: res.data.message,
                    })
                }
            })
    }

    const showEditUser = () => {

        axios.get(`https://sit.api.pigman.com.vn/users/${user.id}`)
            .then(res => {
                console.log(res.data.data[0]);
                const user = res.data.data[0];
                form.setFieldsValue({
                    userName: user.fullName,
                    phoneNumber: user.phone,
                    address: user.address ? user.address : '',
                    dob: user.dob ? moment(user.dob) : '',
                });
            })

        setIsShowEditUser(true);
    }

    const userChangePassword = () => {
        setIsShowChangePassword(true);
    }
    const userDelete = () => {
        setIsDeleteUser(true);
    }

    const handleDeleteUser = async () => {
        setIsDeleteUser(false);
        await axios.delete(`https://sit.api.pigman.com.vn/users/{userId}?userId=${user.id}`)
            .then((response) => {
                setTimeout(() => {
                    notification.success({
                        message: 'Thành công',
                        description: 'Xóa người dùng thành công',
                    });
                    userServices.logout();
                    farmService.removeCurrentFarm();
                    history.push('/');
                }, 1000);
            })
            .catch((error) => {
                notification.error({
                    message: 'Thất bại',
                    description: 'Xóa người dùng thất bại',
                });
            })

    }



    const [openCS, setOpenCS] = useState(false);
    const [tempPen, setTempPen] = useState<any[]>([]); // Danh sách chuồng
    const [eatingPeriod, setEatingPeriod] = React.useState<any[]>([{ start: '9:00', end: '10:00', id: 0 }]);
    const [lyingPeriod, setLyingPeriod] = React.useState<any[]>([{ start: '12:00', end: '13:00', id: 0 }]);
    const [playingPeriod, setPlayingPeriod] = React.useState<any[]>([{ start: '15:00', end: '16:00', id: 0 }]);
    const [tempTagByPen, setTempTagByPen] = useState<any[]>([]); // Danh sách chuồng
    const [behaviorPen, setBehaviorPen] = useState<IBehaviorPen[]>([]); // Danh sách chuồng

    useEffect(() => {
        console.log('penSelected', penSelected);
        if (penSelected) {
            if (isClickBehavior || isClickTemperture) {
                // history.push(`/quan-ly-chuong`)
                handleOpenCU(penSelected);
                // setOpenCU(true);
            }
            else if (isClickAi) {
                loadTempPen(penSelected?.id);
                handleOpenCS(true, penSelected);
            }
        }

    }, [penSelected])
    const getAllPens = (isDelete = false) => {
        const params = {
            offset: 0,
            size: 200,
            farmId: currentFarm?.id,
            name: '',
            area: 0,
            weightTypeId: '',
        };

        apiPen
            .getAllPen(params)
            .then((res: any) => {
                console.log(res.items);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleOpenCS = (isUpdate: boolean, penSelected: IPenData) => {
        // handleClickLstTemp(penSelected?.id);
        loadTempPen(penSelected?.id);
        loadBehaviorPenValue(penSelected?.id);
        dispatch(setCpenSelected(undefined))
        dispatch(setDataTagPigSick([]))
        loadTempByPenId(penSelected);
    }

    const loadBehaviorPenValue = async (id: string | undefined) => {
        await axios.get(`https://sit.api.pigman.com.vn/behaviourForPen/{penId}?penId=${id}`)
            .then((res: any) => {
                const behaviorPen = res.data.data;
                let indexEat = 0;
                let indexLie = 0;
                let indexPlay = 0;
                const lstEating: React.SetStateAction<any[]> = [];
                const lstLying: React.SetStateAction<any[]> = [];
                const lstPlaying: React.SetStateAction<any[]> = [];
                behaviorPen?.map((item: any) => {
                    const start = decimalToTime(item.start);
                    const end = decimalToTime(item.end);
                    if (item.behaviourType === 'Eat') {
                        lstEating.push({ start: start, end: end, id: indexEat++ });
                    }
                    if (item.behaviourType === 'Lie') {
                        lstLying.push({ start: start, end: end, id: indexLie++ });
                    }
                    if (item.behaviourType === 'Play') {
                        lstPlaying.push({ start: start, end: end, id: indexPlay++ });
                    }
                })
                console.log('lstEating', lstEating);

                setEatingPeriod(lstEating);
                setLyingPeriod(lstLying);
                setPlayingPeriod(lstPlaying);
            })
    }
    const decimalToTime = (decimalValue: any) => {
        const hours = Math.floor(decimalValue);
        const minutes = (decimalValue - hours) * 60;
        const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')}`;
        return formattedTime;
    };
    // Hàm thực hiện lấy dữ liệu chuồng
    const loadTempByPenId = async (penSelected: IPenData) => {
        await axios.get(`https://sit.api.pigman.com.vn/temperatureTag/penId/${penSelected.id}?size=1000`)
            .then(res => {
                const tmp: any = [];
                res.data.data.items.forEach((item: any) => {
                    tmp.push({ value: item.id, label: item.tagId.toString() })
                });
                setTempTagByPen(tmp);
            })
    }
    // Hàm thực hiện lấy dữ liệu chuồng
    const loadTempPen = async (penId: any) => {
        await axios.get(`https://sit.api.pigman.com.vn/temperatureTag/farm/${currentFarm.id}?size=1000`)
            .then(res => {
                const tmp: any = [];
                console.log(res.data?.data?.items);

                res.data?.data?.items.forEach((item: any) => {
                    if (!item.penId || item.penId === penId)
                        tmp.push({ value: item.id, label: item.tagId.toString() })
                });
                console.log(tmp);

                setTempPen(tmp);
            })
    }
    const handleCloseCS = () => {
        setOpenCS(false);
    }
    const handleCloseCUWithoutCancelStream = () => {
        setOpenCS(false);
        getAllPens();
    }
    useEffect(() => {
        if (penSelected) {
            setOpenCS(true);
        }
    }, [playingPeriod]);

    useEffect(() => {
        if (penSelected) {
            setOpenCU(true);
        }
    }, [behaviorPen]);

    const handleOpenCU = (penSelected?: IPenData) => {
        loadBehaviorPen(penSelected?.id);
        dispatch(setCpenSelected(undefined))
        dispatch(setDataTagPigSick([]))
    };
    const loadBehaviorPen = async (id: string | undefined) => {
        await axios.get(`https://sit.api.pigman.com.vn/behaviourForPen/{penId}?penId=${id}`)
            .then((res: any) => {
                console.log(res.data.data);
                setBehaviorPen(res.data.data);
            })
    }

    const customizeRenderEmpty = () => (
        <div style={{ textAlign: 'center' }}>
            <p>Không còn trang trại nào</p>
        </div>
    );

    const validatePassword = (_: any, value: string | any[]) => {
        if (!value) {
            return Promise.reject('Vui lòng nhập mật khẩu');
        }
        if (value.length < 6) {
            return Promise.reject('Mật khẩu phải từ 6 ký tự');
        }
        return Promise.resolve();
    };

    const [imageFile, setImageFile] = useState(null);

    const beforeUpload = (file: any) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('Chỉ có thể tải lên tệp JPG / PNG!');
        }
        const isLt2M = file.size / 1024 / 1024 < 1;
        if (!isLt2M) {
            message.error('Ảnh không được lớn hơn 1MB!');
        }
        setImageFile(isJpgOrPng && isLt2M ? file : null);
        return false;
    };
    const onRemove = () => {
        setImageFile(null);
    };

    return (
        <div id="wrapper-header">
            <div className="main-content" id="header">
                <div id="header-logo">
                    <div onClick={onLogoClick}>
                        <img src={logo} alt="logo" />
                    </div>
                </div>
                <div id="header-menu">
                    {user &&
                        <div className={'nav-menu'}>{renderMenu(user, user?.role === 'ADMIN' ? navMenuAdmin : navMenu, 'center-menu')}</div>
                    }
                    {
                        !user
                        &&
                        <div></div>
                    }
                    <div id="log-menu">
                        {(user && farmService.getCurrentFarm() && (user.role !== 'ADMIN')) &&
                            <div className='div-select-header-farm'>
                                <ConfigProvider renderEmpty={customizeRenderEmpty}>
                                    <Select
                                        style={{ width: 200 }}
                                        defaultValue={farmService.getCurrentFarm().name}
                                        onChange={handleInputChangeFarm}
                                        className='select-header-farm'
                                    >
                                        {dataLstFarm && dataLstFarm.map((item: any, index: any) => (
                                            <Select.Option
                                                key={index}
                                                value={item.value.id}
                                            >{item.value.name}</Select.Option>
                                        ))}
                                    </Select>
                                </ConfigProvider>
                            </div>


                        }
                        {(user && user.role !== 'ADMIN') &&
                            <Dropdown
                                className={isOpen ? 'notification-menu-bell active' : 'notification-menu-bell'}
                                overlay={notificationMenu}
                                trigger={['click']}
                                onVisibleChange={isOpen ? handleCloseDropdown : handleOpenDropdown}
                                visible={isOpen}
                            >
                                <div className='notification-menu' style={{ marginRight: 20 }}>
                                    <Badge count={notificationCount}>
                                        <BellOutlined style={{ fontSize: '24px' }} rev={undefined} />
                                    </Badge>
                                </div>
                            </Dropdown>
                        }
                        {renderLogMenu()}
                    </div>
                </div>
            </div>

            <AuthModal
                isShowModal={isShowAuthModal}
                toggleModal={toggleAuthModal}
                isShowLoginForm={isShowLoginForm}
                toggleChangeAuthForm={toggleAuthForm}
                formType={authFormType}
            />

            <Modal
                open={openCU}
                onClose={handleCloseCU}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className='makeStyles-modal-10'
            >
                <div className='makeStyles-modalContentCreate-11'>
                    <ModalHeader title={`${penSelected?.name}`} closeButton={handleCloseCU} />
                    <PenDetail
                        penSelected={penSelected}
                        behaviourPen={behaviorPen}
                    />
                </div>
            </Modal>

            <ModalAntd
                visible={isShowEditUser}
                title={'Chỉnh sửa thông tin người dùng'}
                onCancel={handleCancel}
                onOk={handleOk}
                maskClosable={true}
                footer={false}
            >

                <Form
                    layout="horizontal"
                    onFinish={onFinish}
                    form={form}
                >
                    <Form.Item label="Email" >
                        <Input placeholder='Nhập họ và tên' defaultValue={user?.email} disabled />
                    </Form.Item>
                    <Form.Item
                        name="userName"
                        label={(
                            <span>
                                Họ tên <span style={{ color: 'red' }}>*</span>
                            </span>
                        )}
                        rules={[{ required: true, message: 'Vui lòng nhập họ và tên' }]}>
                        <Input placeholder='Nhập họ và tên' />
                    </Form.Item>
                    <Form.Item
                        name="phoneNumber"
                        label={(
                            <span>
                                Số điện thoại <span style={{ color: 'red' }}>*</span>
                            </span>
                        )}
                        rules={[{ required: true, message: 'Vui lòng nhập số điện thoại' }]}>
                        <Input
                            placeholder="Nhập số điện thoại"
                            type='number'
                        />
                    </Form.Item>
                    <Form.Item
                        name="address"
                        label={(
                            <span>
                                Địa chỉ <span style={{ color: 'red' }}>*</span>
                            </span>
                        )}
                        rules={[{ required: true, message: 'Vui lòng nhập địa chỉ' }]}>
                        <Input placeholder='Nhập địa chỉ' />
                    </Form.Item>
                    <Form.Item
                        name="dob"
                        label={(
                            <span>
                                Ngày sinh <span style={{ color: 'red' }}>*</span>
                            </span>
                        )}
                        rules={[{ required: true, message: 'Vui lòng nhập ngày sinh' }]}>
                        <DatePicker placeholder='Nhập ngày sinh' />
                    </Form.Item>
                    <Form.Item
                        name="userImage"
                        label="Ảnh đại diện"
                    >
                        <Upload
                            name="userImage"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                            onRemove={onRemove}
                        >
                            {imageFile ?
                                <img src={URL.createObjectURL(imageFile)} alt="avatar" style={{ width: '100%' }} /> :
                                <>
                                    {userAvatar !== defaultUser ?
                                        <img src={userAvatar} alt='' />
                                        :
                                        <div>Tải ảnh</div>
                                    }
                                </>
                            }
                        </Upload>

                    </Form.Item>
                    <div className='noti-image'>
                        Ảnh không được lớn hơn 1MB
                    </div>
                    <div className='action-btn'>
                        <Button className='cancel-btn' type="primary" onClick={() => setIsShowEditUser(false)}>
                            Hủy bỏ
                        </Button>
                        <Button className='summit-btn' type="primary" htmlType="submit">
                            Xác nhận
                        </Button>

                    </div>
                </Form>
            </ModalAntd>

            <ModalAntd
                visible={isShowChangePassword}
                title={'Thay đổi mật khẩu'}
                onCancel={handleCancel}
                onOk={handleOk}
                maskClosable={true}
                footer={false}

            >

                <Form layout="horizontal" onFinish={onFinishChangePassword} form={formChangePassword}>
                    <Form.Item
                        name="oldPassword"
                        label={(
                            <span>
                                Mật khẩu cũ <span style={{ color: 'red' }}>*</span>
                            </span>
                        )}
                        rules={[
                            { required: true, message: 'Vui lòng nhập mật khẩu cũ' }

                        ]}>
                        <Input.Password placeholder='Nhập mật khẩu cũ' />
                    </Form.Item>
                    <Form.Item
                        name="newPassword"
                        label={(
                            <span>
                                Mật khẩu mới <span style={{ color: 'red' }}>*</span>
                            </span>
                        )}
                        rules={[
                            { validator: validatePassword }
                        ]}>
                        <Input.Password
                            placeholder="Nhập mật khẩu mới"
                        />
                    </Form.Item>
                    <Form.Item
                        name="confirmPassword"
                        label={(
                            <span>
                                Mật khẩu xác nhận <span style={{ color: 'red' }}>*</span>
                            </span>
                        )}
                        dependencies={['newPassword']}
                        rules={[
                            { required: true, message: 'Vui lòng nhập mật khẩu xác nhận' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('newPassword') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Mật khẩu xác nhận không trùng với mật khẩu mới!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password placeholder='Nhập mật khẩu xác nhận' />
                    </Form.Item>
                    <div className='action-btn'>
                        <Button className='cancel-btn' type="primary" onClick={() => {
                            setIsShowChangePassword(false)
                        }}>
                            Hủy bỏ
                        </Button>
                        <Button className='summit-btn' type="primary" htmlType="submit">
                            Xác nhận
                        </Button>

                    </div>
                </Form>
            </ModalAntd>

            <ModalAntd
                visible={isDeleteUser}
                title={'Xóa tài khoản'}
                onCancel={handleCancel}
                onOk={handleOk}
                footer={false}
            >
                <div className='delete-user'>
                    <div className='title'>Bạn có chắc chắn muốn xóa tài khoản này không?</div>
                    <div className='action-btn' style={{ marginTop: 20 }}>
                        <Button className='cancel-btn' type="primary" onClick={() => {
                            setIsDeleteUser(false)
                        }}>
                            Hủy bỏ
                        </Button>
                        <Button className='summit-btn' type="primary" onClick={() => {
                            handleDeleteUser();
                        }}>
                            Xác nhận
                        </Button>
                    </div>
                </div>
            </ModalAntd>

            <ModalAntd
                visible={isShowNotificationImage}
                title={'Thông báo bất thường'}
                onCancel={handleCancel}
                onOk={handleOk}
                footer={false}
            >
                <div className='delete-user' style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                    <div className='title'>{itemNotification?.message}</div>
                    {itemNotification?.cameraId &&
                        <img src={`https://sit.streaming.pigman.com.vn/warningImages/${itemNotification?.cameraId}`} alt="" />
                    }
                    <Button type="primary" style={{ display: 'flex', justifyContent: 'center', width: '100%' }} onClick={() => {
                        handleClickNotificationImage(itemNotification);
                        handleCancel();
                    }}>Đến chuồng bị bất thường ?</Button>

                </div>
            </ModalAntd >

            <Modal
                open={openCS}
                onClose={handleCloseCS}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={'classes-modal'}>
                <div className={'classes-modalContentCreate'}>
                    <ModalHeader title={true ? 'Cập nhật chuồng' : 'Tạo chuồng'} closeButton={handleCloseCS} />
                    <div style={{ zIndex: 2000 }}>
                        <PenCreate
                            onClose={handleCloseCS}
                            onRefresh={getAllPens}
                            penData={penSelected}
                            isUpdate={true}
                            onCloseWithoutCancelStream={handleCloseCUWithoutCancelStream}
                            tempPen={tempPen}
                            dataTagPigsByPen={tempTagByPen}
                            eatingPeriodLst={eatingPeriod}
                            lyingPeriodLst={lyingPeriod}
                            playingPeriodLst={playingPeriod}
                        />
                    </div>
                </div>
            </Modal>
        </div >
    );
};

export default Header;
