/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from 'react-select';

import { apiFoodType } from '@api/food-type';
import { apiFoodUnit } from '@api/food-unit';
import { apiFood } from '@api/food';
import farmService from '@services/farm';
import userServices from '@services/user';
import { IFoodData } from '../../types/food';
import openNotification, { NotificationType } from '../common/notification/Notification';
import { IFoodSupplierData } from '../../types/food-supplier';
import axios from 'axios';
import { Button } from 'antd';

type FoodCreateProps = {
    onClose: any;
    onRefresh: any;
    foodData: IFoodData;
    isUpdate: boolean;
    foodSuppliers: any[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        notchedOutline: {
            borderWidth: "1px",
            borderColor: "#FFDADE !important"
        },
        btn: {
            display: 'flex',
            justifyContent: 'end',
            gap: '5px'
        },
        btnSave: {
            fontSize: '16px',
            textTransform: 'none',
        },
        btnCanel: {
            fontSize: '14px',
            textTransform: 'none',
        },
        circularProgress: {
            marginLeft: 0,
            marginRight: '10px',
            color: 'white'
        }
    }),
);

const customStyles = {
    control: (base: any, state: any) => ({
        ...base,
        borderColor: "#FFDADE !important"
    })
};

const FoodCreate: FC<FoodCreateProps> = ({ onClose, onRefresh, foodData, isUpdate, foodSuppliers }: FoodCreateProps) => {
    const classes = useStyles();
    const [name, setName] = useState<string>(foodData?.name);
    const [foodTypeId, setFoodTypeId] = useState<string>(foodData?.foodType.id);
    const [foodTypeName, setFoodTypeName] = useState<string>(foodData?.foodType.name);
    const [foodTypes, setFoodTypes] = useState<Array<any>>([]);
    const [foodUnitId, setFoodUnitId] = useState<string>(foodData?.foodUnit.id);
    const [foodUnitName, setFoodUnitName] = useState<string>(foodData?.foodUnit.name);
    const [foodSupplierId, setFoodSupplierId] = useState<string>(foodData?.foodSupplier.id);
    const [foodSupplierName, setFoodSupplierName] = useState<string>(foodData?.foodSupplier.name);
    const [foodUnits, setFoodUnits] = useState<Array<any>>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [optionsFoodSupplier, setOptionsFoodSupplier] = useState<any[]>([]); // [
    const currentFarm = farmService.getCurrentFarm();
    const user = userServices.get();
    const userId = user.id;


    useEffect(() => {
        getAllFoodTypes();
        getAllFoodUnits();

        console.log(foodSuppliers);
        const tmp = foodSuppliers.map((foodSupplier: IFoodSupplierData) => {
            return {
                value: foodSupplier.id,
                label: foodSupplier.name,
            }
        });

        setOptionsFoodSupplier(tmp);


    }, []);

    const getAllFoodTypes = () => {
        apiFoodType.getAll()
            .then((res: any) => {
                const foodTypeList: any = [];
                res.map((foodType: any) => {
                    foodTypeList.push({ value: foodType.id, label: foodType.name });
                })
                setFoodTypes(foodTypeList);
            })
            .catch((err) => {
                console.log("err:" + JSON.stringify(err));
            })
    }

    const getAllFoodUnits = () => {
        apiFoodUnit.getAll()
            .then((res: any) => {
                const foodUnitList: any = [];
                res.map((foodUnit: any) => {
                    foodUnitList.push({ value: foodUnit.id, label: foodUnit.name });
                })
                setFoodUnits(foodUnitList);
            })
            .catch((err) => {
                console.log("err:" + JSON.stringify(err));
            })
    }

    const doSave = async () => {
        const data = {
            name,
            foodTypeId,
            foodUnitId,
            foodSupplierId,
            farmId: currentFarm.id,
        }

        console.log(data);

        setIsLoading(true);

        if (isUpdate) {
            await axios.put(`https://sit.api.pigman.com.vn/food/${foodData.id}`, data)
                .then((res) => {
                    onRefresh();
                    onClose();
                    openNotification(NotificationType.Info, 'topRight', `PIGMAN`, `Cập nhật thức ăn thành công.`);
                })
                .catch((err) => {
                    setIsLoading(false);
                    console.log("err:" + JSON.stringify(err));
                });
        } else {
            await axios.post(`https://sit.api.pigman.com.vn/food`, data)
                .then((res) => {
                    onRefresh();
                    onClose();
                    openNotification(NotificationType.Info, 'topRight', `PIGMAN`, `Thêm mới thức ăn thành công.`);
                })
                .catch((err) => {
                    setIsLoading(false);
                    console.log("err:" + JSON.stringify(err));
                });
        }
    };

    const handleInputChangeEventName = (evt: ChangeEvent<HTMLInputElement>) => {
        const { value } = evt.target;
        setName(value);
    };

    const handleInputChangeFoodType = (selectedOption: any) => {
        const { value } = selectedOption;
        setFoodTypeId(value);
    };

    const handleInputChangeFoodUnit = (selectedOption: any) => {
        const { value } = selectedOption;
        setFoodUnitId(value);
    };

    const handleInputChangeFoodSupplier = (selectedOption: any) => {
        const { value } = selectedOption;
        setFoodSupplierId(value);
    }

    return (
        <div>
            <Grid container>
                <Grid item xs={10} sm={4}>
                    <Box display="flex" justifyContent="flex-start" m={1} ml={2} p={1}>
                        Tên thức ăn <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box display="flex" justifyContent="flex-start" p={1} >
                        <TextField
                            fullWidth={true}
                            id="name"
                            name="name"
                            label=""
                            variant="outlined"
                            size="small"
                            placeholder="Nhập tên thức ăn"
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                            value={name}
                            onChange={handleInputChangeEventName}
                        />
                    </Box>
                </Grid>
                <Grid item xs={10} sm={4}>
                    <Box display="flex" justifyContent="flex-start" m={1} ml={2} p={1}>
                        Loại thức ăn <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box p={1} >
                        <Select
                            name="foodType"
                            options={foodTypes}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            styles={customStyles}
                            placeholder="Chọn loại thức ăn"
                            defaultValue={(foodTypeId && foodTypeName) ? { value: foodTypeId, label: foodTypeName } : { value: '', label: 'Chọn loại thức ăn' }}
                            onChange={handleInputChangeFoodType}
                        />
                    </Box>
                </Grid>
                <Grid item xs={10} sm={4}>
                    <Box display="flex" justifyContent="flex-start" m={1} ml={2} p={1}>
                        Đơn vị <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box p={1}>
                        <Select
                            name="foodUnit"
                            options={foodUnits}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            styles={customStyles}
                            placeholder="Chọn đơn vị"
                            defaultValue={(foodUnitId && foodUnitName) ? { value: foodUnitId, label: foodUnitName } : { value: '', label: 'Chọn đơn vị' }}
                            onChange={handleInputChangeFoodUnit}
                        />
                    </Box>
                </Grid>
                <Grid item xs={10} sm={4}>
                    <Box display="flex" justifyContent="flex-start" m={1} ml={2} p={1}>
                        Nhà cung cấp <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box p={1} >
                        <Select
                            name="foodUnit"
                            options={optionsFoodSupplier}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            styles={customStyles}
                            placeholder="Chọn nhà cung cấp"
                            defaultValue={(foodSupplierId && foodSupplierName) ? { value: foodSupplierId, label: foodSupplierName } : { value: '', label: 'Chọn nhà cung cấp' }}
                            onChange={handleInputChangeFoodSupplier}
                        />
                    </Box>
                </Grid>
                <Grid item xs={10} sm={4}>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box display="flex" justifyContent="flex-start" p={1} mb={5} className={classes.btn}>
                        <Button onClick={onClose}>
                            Hủy bỏ
                        </Button>
                        <Button
                            onClick={doSave}
                            disabled={(name && foodTypeId && foodUnitId) ? false : true}
                            type="primary" htmlType="submit">
                            {
                                isLoading && (
                                    <CircularProgress className={classes.circularProgress} size={20} />
                                )
                            }
                            {isUpdate ? "Cập nhật" : "Lưu"}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

export default FoodCreate;
