/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { FC, useEffect } from 'react';
import { ICam } from '@type/cam';

import { withStyles, createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { v4 as uuidv4 } from 'uuid';
import PigItem from '@common/pig-item/pig-item';

import * as camApi from '@api/cam';
import { IPigData } from '@src/src/app/types/pig';
import CImageLoading from '../../video/CImageLoading';
import StreamAPI from '@src/src/app/api/camera/streaming.api';

interface ICamList {
    penId: string,
    camSelected: any,
    camSelectedData: ICam,
    pigs: IPigData[],
    savedCams: ICam[]
}

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: '#FFDADE',
            color: '#18191F',
            fontWeight: 'bold',
            fontSize: '14px',
            textAlign: 'center'
        },
        body: {
            fontSize: '14px',
            border: 'none',
            textAlign: 'center'
        },
    }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:nth-of-type(even)': {
                backgroundColor: '#FFDADE',
            },
        },
    }),
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '80%'
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        warning: {
            color: 'red',
        },
        normal: {
            color: '#18191F',
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        modalContentCreate: {
            backgroundColor: 'white',
            width: '60%'
        },
        modalContentAlert: {
            width: '30%'
        },
        checkbox: {
            color: '#ABABAB'
        },
        tablePagination: {
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            flexDirection: 'row',
            marginTop: '20px'
        },
        videoItem: {
            marginTop: '7px',
            marginBottom: '20px',
            position: 'relative'
        },
        videoItemBorder: {
            border: '2px solid black'
        },
        videoItemSelectedBorder: {
            border: '2px solid #2CD9C5'
        },
        camName: {
            display: 'block',
            position: 'absolute',
            left: 10,
            top: 10,
            backgroundColor: '#FF5A80',
            color: 'white',
            fontSize: '12px',
            paddingLeft: '5px',
            paddingRight: '5px'
        },
        camNameSelected: {
            display: 'block',
            position: 'absolute',
            left: 10,
            top: 10,
            backgroundColor: '#2CD9C5',
            color: 'white',
            fontSize: '12px',
            paddingLeft: '5px',
            paddingRight: '5px'
        },
        selectedHelpText: {
            display: 'block',
            position: 'absolute',
            left: 10,
            top: 30,
            backgroundColor: '#FF5A80',
            color: 'white',
            fontSize: '12px',
            paddingLeft: '5px',
            paddingRight: '5px'
        }
    }),
);

const AddPig: FC<ICamList> = ({ penId, camSelected, camSelectedData, pigs, savedCams }: ICamList) => {
    const classes = useStyles();
    const [cams, setCams] = React.useState<ICam[]>([]);
    const [uuId, updateUuId] = React.useState<string>(uuidv4());

    // const showAuthModal = () => (e: any) => {
    //     dispatch(setIsShowGuideline(true));
    // }

    // React.useEffect(() => {
    //     const offsetLeft = window.innerWidth - (selectPensGuidelineStyleMainContentRef.current?.offsetLeft ?? 1);
    //     const offsetWidth = selectPensGuidelineStyleMainContentRef.current?.offsetWidth ?? 1;
    //     const move = offsetLeft - 130;
    //     const offsetTop = selectPensGuidelineStyleMainContentRef.current?.offsetTop ? selectPensGuidelineStyleMainContentRef.current?.offsetTop : 1;
    //     setSelectPensGuidelineStyle({
    //         top: 20
    //     })
    // }, [])

    useEffect(() => {
        getAllCams();
        console.log(savedCams);
    }, [savedCams]);

    useEffect(() => {
        console.log(cams);
    }, [cams]);


    const getAllCams = () => {
        camApi.getAll(penId)
            .then((res: any) => {
                setCams(res);
            })
            .catch((err) => {
                console.log(err);
            })
    };


    // const handleOkInFillInNewCamera = () => {
    //     dispatch(setCurrentProcess(AllStepsProcess.NoneInSelectCam));
    // }

    return (
        <div style={{ margin: '8px 0' }}>
            {/* {(location.pathname === routeNames.penEmpty && isShowGuideline && currentProcess === AllStepsProcess.SelectPenToImportPigs) && <GuideLine guideLineRenderProps={{
                currentProcess: currentProcess, title: '', lstSteps, style: selectPensGuidelineStyle, modalText: 'Lựa chọn camera để nhập lợn và chờ hệ thống xử lý.',
                visible: isShowGuideline && currentProcess === AllStepsProcess.SelectPenToImportPigs, confirmLoading: true, handleCancel: () => { dispatch(setIsShowGuideline(false)) }, handleOk: handleOkInFillInNewCamera
            }} />} */}
            <TableContainer>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={'small'}
                    aria-label="enhanced table"
                >

                    <TableHead>
                        <TableRow>
                            <StyledTableCell>
                                Danh sách cam
                            </StyledTableCell>

                            <StyledTableCell>
                                Danh sách lợn
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row" padding="none" style={{ width: '400px', verticalAlign: 'top' }}>
                                {
                                    cams.map((cam, index) => {
                                        return (
                                            <div key={cam.id + cam.linkStreaming} className={[classes.videoItem, cam.id == camSelectedData?.id ? classes.videoItemSelectedBorder : classes.videoItemBorder].join(' ')} onClick={() => camSelected(cam)}>
                                                <div className={classes.selectedHelpText}>{cam.id == camSelectedData?.id ? "Đã chọn" : "Click vào video để chọn cam"}</div>
                                                <div className={cam.id == camSelectedData?.id ? classes.camNameSelected : classes.camName}>{cam.camName}</div>
                                                {/* <VideoItem uuId={uuidv4()} camId={cam.id} width='300' height='300' /> */}
                                                <div className="cursor-pointer">
                                                    <CImageLoading
                                                        src={StreamAPI.getStream(cam.id)}
                                                        className="h-60"
                                                        uuid={uuId}
                                                        // onClick={() =>
                                                        //     handleShowExpandMenu(true, item)
                                                        // }
                                                        isHiddenCamera={cam?.id ? true : false}
                                                        idCamera={cam?.id}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </StyledTableCell>
                            <StyledTableCell align="right" style={{ verticalAlign: 'top' }}>
                                {
                                    (pigs.length > 0) && (
                                        pigs?.map((pig, index) => {
                                            return (
                                                <PigItem
                                                    key={index}
                                                    runningTime={`${pig.behaviour?.runningTimes ? pig.behaviour.runningTimes : 0} phút`}
                                                    eatingTime={`${pig.behaviour?.eatingTimes ? pig.behaviour?.eatingTimes : 0} phút`}
                                                    lieDownTime={`${pig.behaviour?.lieDownTimes ? pig.behaviour?.lieDownTimes : 0} phút`}
                                                    standingTime={`${pig.behaviour?.standingTimes ? pig.behaviour?.standingTimes : 0} phút`}
                                                    pigAIId={`pig-${pig.pigAIId}`}
                                                    pigSelected={false}
                                                    pigInfo={pig}
                                                />
                                            )
                                        })
                                    )
                                }
                                {
                                    (pigs.length == 0) && (
                                        <div>
                                            <p>Hiện tại chưa có lợn trong chuồng.</p>
                                            <p>Vui lòng chọn cam sau đó chọn <b>Đánh số</b>, hệ thống AI sẽ tự động nhận diện lợn và nhập vào chuồng!</p>
                                        </div>
                                    )
                                }
                            </StyledTableCell>
                        </StyledTableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default AddPig;