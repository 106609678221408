/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { FC, useEffect, useRef, useState } from 'react';
import bannerPig from '@assets/thu11.png';
import pigNose from '@assets/pigNose.png';
import './home.scss';
import { Button, Card, Spin } from 'antd';
import { AuthPopupOrigin } from '../../utils/global';
import { useDispatchRoot, useSelectorRoot } from '../../redux/store';
import { setAuthPopupOrigin } from '../../redux/controller';
import GuideLine from '../common/guidelines/guideline';
import { IStep } from '../common/guidelines/customStep';
import { stepProcessData } from '../common/guidelines/guideline.types';
import { NotificationType } from '../common/notification/Notification';
import { setIsCloseWarning } from '../../redux/controller/farm.slice';
import openNotification from '../common/notification/Notification';
import userServices from '@services/user';
import IconHome1 from '@assets/icon-home-1.png';
import IconHome2 from '@assets/icon-home-2.png';
import IconHome3 from '@assets/icon-home-3.png';
import IconHome4 from '@assets/icon-home-4.png';
import IconHome5 from '@assets/icon-home-5.png';
import IconHome6 from '@assets/icon-home-6.png';
import { TiTick } from 'react-icons/ti';
import { GoogleMap, useLoadScript, Marker, LoadScript } from "@react-google-maps/api";
import { number } from 'yargs';
import axios from 'axios';
import ReactPlayer from 'react-player';
import Video from '@assets/video.mp4';
import { CheckOutlined } from '@material-ui/icons';
import IMAGE1 from '@assets/image1.png';
import IMAGE2 from '@assets/image2.png';
import IMAGE3 from '@assets/image3.png';
import IMAGE4 from '@assets/image4.png';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const newsItems = [
    {
        title: 'News Title 1',
        content: 'This is the content for news item 1.',
        image: 'https://example.com/image1.jpg',
    },
    {
        title: 'News Title 2',
        content: 'This is the content for news item 2.',
        image: 'https://example.com/image2.jpg',
    },
    {
        title: 'News Title 3',
        content: 'This is the content for news item 3.',
        image: 'https://example.com/image3.jpg',
    },
];

const HomePage: FC = () => {
    const dispatch = useDispatchRoot();
    const { nameOfPensWarning, isCloseWarning } = useSelectorRoot(state => state.farm)
    const showAuthModal = () => (e: any) => {
        dispatch(setAuthPopupOrigin(AuthPopupOrigin.GetStarted))
        //setVisibleGuideLine(true);
    }
    const demoData: IStep[] = stepProcessData;
    const [lstSteps, setLstSteps] = React.useState<IStep[]>(demoData);
    const [visibleGuideLine, setVisibleGuideLine] = React.useState<boolean>(false);
    const guideLineStyle = {
        top: 20
        // right: 10
    }
    const mapRef = useRef<any>(null);
    const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);
    const [image, setImage] = useState<any>(IMAGE1);
    const [width, setWidth] = useState<number>(0);

    const [newsPig, setNewsPig] = useState<any[]>([]);
    useEffect(() => {
        getAllNewsPig();
    }, []);

    const getAllNewsPig = async () => {
        await axios.get(`https://sit.api.pigman.com.vn/pigArticle`)
            .then(res => {
                console.log(res.data.data);

                const sortedData = res.data.data.sort((a: any, b: any) => {
                    return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
                });

                setNewsPig(sortedData);
            })
            .catch(err => {
                console.log(err);
            })
    }

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener('resize', handleWindowResize);
        if (window.innerWidth > 1024) {
            setImage(IMAGE1);
            setWidth(700);
        }
        if (window.innerWidth <= 1024) {
            setImage(IMAGE2);
            setWidth(500);
        }
        if (window.innerWidth <= 768) {
            setImage(IMAGE3);
            setWidth(300);
        }
        if (window.innerWidth <= 425) {
            setImage(IMAGE4);
            setWidth(200);
        }
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });
    useEffect(() => {
        if (mapRef.current) {
            const mapDoc = mapRef.current.contentWindow?.document;
            if (mapDoc) {
                const placeCard = mapDoc.querySelector('.place-card');
                if (placeCard) {
                    placeCard.style.backgroundColor = 'red';
                }
            }
        }
    }, [mapRef]);
    const onCloseWarning = () => {
        dispatch(setIsCloseWarning(true))
    }
    useEffect(() => {
        if (nameOfPensWarning.length > 0 && userServices.get()) {
            let lstPenName = '';
            nameOfPensWarning.map(item => {
                lstPenName += item + ','
            })
            lstPenName = lstPenName.substring(0, lstPenName.length - 1)
            openNotification(NotificationType.Warning, 'topRight', `Cảnh báo hệ thống`, `Có ${nameOfPensWarning.length} chuồng nuôi đã nhập lợn nhưng chưa được hệ thống theo dõi. Bao gồm: ${lstPenName}.`, onCloseWarning)
        }
    }, [isCloseWarning, nameOfPensWarning])
    const [address, setAddress] = useState<any[]>([]);
    const [coordinates, setCoordinates] = useState<any>([{ lat: 0, lon: 0 }]);
    const [isChange, setIsChange] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [farmName, setFarmName] = useState<any[]>([]);
    const [locationData, setLocationData] = useState<any[]>([]);



    useEffect(() => {
        setLoading(true);

        getAllFarm();
    }, []);

    const getAllFarm = async () => {
        await axios.get(`https://sit.api.pigman.com.vn/farms/getAllFarms/getAllFarms`)
            .then(res => {
                setAddress(res.data.data);
            })
    }
    function capitalizeFirstLetter(string: any) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const handleGetCoordinates = async (addressItem: any) => {
        let address = capitalizeFirstLetter(addressItem.address);
        console.log(address);
        // Nếu địa chỉ không có chữ việt nam ở cuối và cách nhau bởi dấu phẩy, thêm vào ở cuối chữ ", Việt Nam"
        if (!address.includes('Việt Nam') && !address.includes('Vietnam') && !address.includes('vietnam') && !address.includes('viet nam') && !address.includes('viet Nam')) {
            if (address.includes(',')) {
                address += ', Việt Nam';
            }
            else if (address.includes('-')) {
                address += '';
            }
            else {
                address += ' Việt Nam';
            }
        }
        console.log('====================================');
        console.log(address);
        console.log('====================================');
        await axios.get(`https://api.geoapify.com/v1/geocode/search?text="${address}"&lang=vi&limit=1&format=json&apiKey=5de6046ee5e843169fd8269c94f5e89f`)
            .then(res => {
                const location = res.data.results[0];
                if (!location) return;
                console.log(location);

                setCoordinates((coordinates: any) => [...coordinates, { lat: location.lat, lon: location.lon, name: addressItem.name, address: addressItem.address }]);
            });
        // await axios.get(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(addressItem)}`)
        //     .then(res => {
        //         console.log(res.data);
        //         const location = res.data[0];
        //         // setCoordinates({ lat: location.lat, lon: location.lon });
        //         if (!location) return;
        //         setCoordinates((coordinates: any) => [...coordinates, { lat: location.lat, lon: location.lon }]);
        //         console.log(coordinates);
        //     })
    };
    useEffect(() => {
        if (address.length > 0) {
            setCoordinates([]);
            address.map(item => {
                handleGetCoordinates(item);
            })
            setTimeout(() => {
                setIsChange(true);
            }, 5000);
        }
    }, [address]);

    useEffect(() => {
        if (!isChange) return;
        mapboxgl.accessToken = 'pk.eyJ1IjoiYmFrYXBlcmkiLCJhIjoiY2xsa2t6b2hzMHlncDNybHd6dGMzYzBrYyJ9.XtCcCrCBL4dIpZv1Mwdlvg';
        const map = new mapboxgl.Map({
            container: 'map',
            // Choose from Mapbox's core styles, or make your own style with Mapbox Studio
            style: 'mapbox://styles/mapbox/light-v10',
            center: [105.648108, 16.161921],
            zoom: 3.9,
            // pitch: 50, // Tilt the map by 30%

        });
        // Create a custom elevation source using Terrain-RGB data
        const elevationSource = {
            type: 'raster-dem',
            url: 'mapbox://mapbox.terrain-rgb', // Terrain-RGB data source
            tileSize: 256
        };
        map.on('load', () => {
            map.addSource('custom-elevation', elevationSource);

            map.addLayer({
                id: 'country-fills',
                source: {
                    type: 'vector',
                    url: 'mapbox://mapbox.country-boundaries-v1',
                },
                'source-layer': 'country_boundaries',
                type: 'fill',
                paint: {
                    'fill-color': [
                        'match',
                        ['get', 'iso_3166_1_alpha_3'],
                        'VNM', // ISO 3166-1 alpha-3 code for Vietnam
                        '#0097d0', // White color for Vietnam
                        '#ffffff', // Black color for other countries
                    ],

                    'fill-opacity': 0.5,
                },
            });
        });
        const tmp: any[] = [];
        if (coordinates.length <= 0) return;
        for (let index = 0; index < coordinates.length; index++) {
            console.log(coordinates[index]);

            tmp.push({
                'type': 'Feature',
                'properties': {
                    'description':
                        `<strong>${coordinates[index].name}</strong><p>${coordinates[index].address}</p>`
                },
                'geometry': {
                    'type': 'Point',
                    'coordinates': [coordinates[index].lon, coordinates[index].lat]
                }
            })
        }
        map.on('style.load', function () {
            map.setPaintProperty('water', 'fill-color', '#e8f8ff'); // Replace with the desired color
        });
        map.on('load', () => {
            map.addSource('places', {
                'type': 'geojson',
                'data': {
                    'type': 'FeatureCollection',
                    'features': tmp,
                }
            });
            // Add a layer showing the places.
            map.addLayer({
                'id': 'places',
                'type': 'circle',
                'source': 'places',
                'paint': {
                    'circle-color': '#f5b247',
                    'circle-radius': 6,
                    'circle-stroke-width': 2,
                    'circle-stroke-color': '#ffffff'
                }
            });

            // Create a popup, but don't add it to the map yet.
            const popup = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false
            });

            map.on('mouseenter', 'places', (e) => {
                // Change the cursor style as a UI indicator.
                map.getCanvas().style.cursor = 'pointer';

                // Copy coordinates array.
                const coordinates = e.features[0].geometry.coordinates.slice();
                const description = e.features[0].properties.description;

                // Ensure that if the map is zoomed out such that multiple
                // copies of the feature are visible, the popup appears
                // over the copy being pointed to.
                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }

                // Populate the popup and set its coordinates
                // based on the feature found.
                popup.setLngLat(coordinates).setHTML(description).addTo(map);
            });

            map.on('mouseleave', 'places', () => {
                map.getCanvas().style.cursor = '';
                popup.remove();
            });
        });
        setLoading(false);

    }, [isChange]);

    const handleClickToLink = (link: string) => {
        window.open(link, '_blank');
    }

    return (
        <div>
            <GuideLine guideLineRenderProps={{ currentProcess: 1, title: 'Chào mừng bạn đến với PIGMAN!', lstSteps, style: guideLineStyle, modalText: "Bắt đầu trải nghiệp quản lý dự án lợn trong trang trại sử dụng công nghệ AI", visible: visibleGuideLine, confirmLoading: true, handleCancel: () => { setVisibleGuideLine(false) }, handleOk: () => { setVisibleGuideLine(false) } }} />
            <div id="banner">
                <div id="banner-content">
                    <div>
                        <h1 id="banner-title"> Giải pháp công nghệ 4.0 trong giám sát hành vi, sức khỏe  của lợn nhằm phòng chống và cảnh báo sớm dịch bệnh</h1>
                        {/* <p id="banner-desc">
                            {' '}
                            Phần mềm áp dụng công nghệ AI để quản lý tới từng đối tượng lợn trong trang trại của bạn.{' '}
                        </p> */}
                        {/* <Button id="banner-started_btn" className="btn" onClick={showAuthModal()}>
                            Khám phá
                        </Button> */}
                    </div>
                    {/* <div className='map-user'>
                        <div className="map-title">Bản đồ người sử dụng V.innovate</div>
                        <LoadScript googleMapsApiKey="AIzaSyDYp8Vpy77rRiKFpjJVzhGtPfRO9Cyv_Ug">
                            <GoogleMap
                                mapContainerStyle={containerStyle}
                                center={center}
                                zoom={4}
                            >
                                {locations.map(location => (
                                    <Marker
                                        key={location.id}
                                        position={{ lat: location.lat, lng: location.lng }}
                                        title={location.name}
                                    />
                                ))}

                            </GoogleMap>
                        </LoadScript>

                    </div> */}
                    <div className='main-map'>
                        {loading &&
                            <>
                                <span className='span-map'>Đang lấy dữ liệu bản đồ</span>
                                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            </>

                        }
                        <div id="map">
                            {!loading &&
                                <div className='span-map-noti'>Hoàng Sa Trường Sa là của Việt Nam</div>
                            }
                        </div>
                    </div>
                    <div className="video-player">
                        <div className='text-video'>Phần mềm áp dụng công nghệ AI để quản lý tới từng đối tượng lợn <br /> trong trang trại của bạn</div>
                        <ReactPlayer url={Video} controls />
                    </div>

                    {/* <img src={bannerPig} alt="bannerPig" id="banner-pig" /> */}
                </div>
            </div>

            <div className='news-pigs'>
                <div className='news-title'>
                    Tin tức về lợn
                </div>
                <div className='lst-news'>

                    {
                        newsPig.map((item, index) => (
                            <>
                                <div className='item-news' onClick={() => handleClickToLink(item.description)}>
                                    <img src={`https://sit.api.pigman.com.vn/pigArticle/image/{imageName}?imageName=${item.image}`} alt="news-pig" className='image-news' />
                                    <div className='content-news'>
                                        <h2 title={item.title}>{item.title}</h2>
                                        <p title={item.content}>{item.content}</p>
                                    </div>
                                </div>
                            </>
                        ))
                    }
                </div>

            </div>

            <div id="management">
                <div className="">
                    <div id="management-intro">
                        <h1 id="intro-title">Quản lý trang trại hiệu quả</h1>
                        <p id="intro-desc">
                            Không còn phải lo lắng về việc khó khăn trong quản lý tình trạng của từng con lợn, PigMan
                            giúp bạn quản lý dễ dàng hơn với công cụ AI
                        </p>
                    </div>

                    <div id="wrapper-content">

                        <div className="management-content m-content5">
                            <img src={IconHome1} alt="management-icon" className="management-icon" />
                            <p className="content-title">Quản lý trang trại hiệu quả</p>
                            <p className="content-desc">
                                Sử dụng AI nhận diện, theo dõi, giám sát và lưu trữ hồ sơ toàn bộ thông tin về quy mô chuồng trại và diễn biến sức khỏe của từng cá thể lợn trong trang trại                            </p>
                        </div>

                        <div className="management-content m-content5">
                            <img src={IconHome2} alt="management-icon" className="management-icon" />
                            <p className="content-title">Cảnh báo bất thường</p>
                            <p className="content-desc">
                                AI giám sát và thu thập dữ liệu về hành vi và thân nhiệt của từng cá thể trong trang trại và cảnh báo kịp thời khi có bất thường                            </p>
                        </div>

                        <div className="management-content m-content5">
                            <img src={IconHome3} alt="management-icon" className="management-icon" />
                            <p className="content-title">Phòng ngừa dịch bệnh</p>
                            <p className="content-desc">
                                AI nhận diện, theo dõi, giám sát và cảnh báo các bất thường của từng cá thể nhằm phát hiện sớm các bất thường về sức khỏe hoặc các dịch bệnh có thể xảy ra, giảm nhẹ rủi ro về kinh tế cho người chăn nuôi                            </p>
                        </div>
                        <div className="management-content m-content5">
                            <img src={IconHome4} alt="management-icon" className="management-icon" />
                            <p className="content-title">Công cụ chẩn đoán bệnh lợn</p>
                            <p className="content-desc">
                                Pigman cung cấp sổ tay các bệnh thường gặp ở lợn và một công cụ chẩn đoán hữu ích đơn giản nhằm dự đoán bệnh lợn chính xác trong thời gian ngắn                            </p>
                        </div>
                        <div className="management-content m-content5">
                            <img src={IconHome5} alt="management-icon" className="management-icon" />
                            <p className="content-title">Quản lý thức ăn/ thuốc/vacxin</p>
                            <p className="content-desc">
                                Dễ dàng theo dõi và quản lý các hạng mục nhập/xuất thức ăn/thuốc/vaccine/vật tư của trang trại và thống kê tồn kho một cách chính xác                            </p>
                        </div>
                        <div className="management-content m-content5">
                            <img src={IconHome6} alt="management-icon" className="management-icon" />
                            <p className="content-title"> Trích xuất và lưu trữ báo cáo</p>
                            <p className="content-desc">
                                Người dùng dễ dàng thống kê các dữ liệu liên quan chuồng trại và hệ thống cho phép người dùng trích xuất/in báo cáo nhằm nâng cao hiệu quả hoạt động quản lý trang trại.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div id="story">
                <div className="main-content-home">
                    <img src={bannerPig} alt="bannerPig" id="banner-pig" />
                    <div className='content'>
                        <div className='title-content'>
                            Hệ thống Pigman là sản phẩm của đề tài
                            <br /> “Nghiên cứu giải pháp công nghệ 4.0 trong giám sát hành vi, sức khỏe của lợn nhằm phòng chống và cảnh báo sớm dịch bệnh”
                            <br /> mã số đề tài: CN4000.01/21-23.
                        </div>
                        <div className='lst-content'>
                            <div className='content-item'>
                                <CheckOutlined />  Hệ thống giám sát nhiệt độ tai lợn, hành vi và cảnh báo bất thường về sức khỏe của lợn theo thời gian thực.
                            </div>
                            <div className='content-item'>
                                <CheckOutlined />  Cụm cảm biến nhiệt độ gắn trên tai để đo nhiệt độ và nhận mã định danh lợn, cảm biến môi trường chuồng trại, camera theo dõi hành vi của lợn và bộ nhận, truyền, quản lý và xử lý dữ liệu thu được
                            </div>
                            <div className='content-item'>
                                <CheckOutlined /> Sử dụng trí tuệ nhân tạo thông minh để phân tích và xử lý dữ liệu thu được, giúp cảnh báo sớm tình trạng của lợn nhằm cách ly, chữa trị và tách đàn sớm
                            </div>
                            <div className='content-item'>
                                <CheckOutlined />  Người được phân quyền có thể xem thông tin về tình trạng sức khỏe của từng con lợn qua website hoặc thiết bị di động, giúp quản lý và chăm sóc lợn một cách dễ dàng và thuận tiện.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomePage;
