/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import routers from '@routes/.';
import 'antd/dist/antd.css';
import '@public/css/core.scss';
import { Provider } from 'react-redux';
import store from './redux/store';
import { notification } from 'antd';
import userServices from '@services/user';

function App() {
    // const [notificationPermission, setNotificationPermission] = useState(Notification.permission);
    const user = userServices.get();

    // useEffect(() => {
    //     console.log('notificationPermission', notificationPermission);
    //     const isEdgeBrowser = /Edg/i.test(window.navigator.userAgent);
    //     console.log(isEdgeBrowser);

    //     if (notificationPermission !== 'granted' && isEdgeBrowser && user?.id) {
    //         notification.warning({
    //             message: 'Bạn đang dùng trình duyệt Edge',
    //             description: 'Để nhận được thông báo, vui lòng chấp nhận thông báo trên trình duyệt',
    //         })
    //     }
    // }, []);
    return (
        <Provider store={store}>
            <Router>
                <Switch>
                    {routers.map(({
                        exact,
                        path,
                        component: Component,
                        layout: Layout,
                        routes: nestedRoutes,
                        isLoginRequired
                    }, i) => {
                        return (
                            <Route
                                key={i}
                                exact={exact}
                                path={path}
                                render={(props) => {
                                    return (
                                        <Layout isLoginRequired={isLoginRequired} routesProps={props}>
                                            <Component {...props} routes={nestedRoutes} />
                                        </Layout>
                                    );
                                }}
                            />
                        );
                    })}
                </Switch>
            </Router>
        </Provider>
    );
}

export default App;
