/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { Theme, createStyles, lighten, makeStyles, withStyles } from '@material-ui/core/styles';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import Pagination from '@material-ui/lab/Pagination';
import TableAntd from 'antd/lib/table';
import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';

import CustomAlert from '../../common/alert/custom-alert';
import ModalHeader from '../../common/modal/modal-header';
import PageHeader from '../../common/page-header/page-header';
import PenDetail from './pen-detail';

import { apiPen } from '@api/pen';
import { Context } from '@components/context/context';
import { CFG_SIZE_PAGINATION } from '@constants/config';
import farmService from '@services/farm';
import { importPigFromAI } from '@src/src/app/api/pig';
import { restartAnalysisCameraReq } from '@src/src/app/redux/controller/camera.slice';
import { useDispatchRoot, useSelectorRoot } from '@src/src/app/redux/store';
import { IImportPig, IImportPigExpanded } from '@src/src/app/types/pig';
import { IBehaviorPen, IPenData } from '@type/pen';
import { UtilPage } from '@utils/page';
import { Breadcrumb, Button, Space, Spin, notification } from 'antd';
import axios from 'axios';
import { FaEdit, FaHistory, FaTemperatureHigh, FaRegListAlt, FaTrashAlt } from "react-icons/fa";
import openNotification, { NotificationType } from '../../common/notification/Notification';
import PenEmptyCreat from '../pen-empty/pen-empty-create';
import './style.pen.scss';
import PenCreate from './pen-create';
import { useHistory } from 'react-router-dom';
import { setCpenSelected, setDataTagPigSick, setTagPenId } from '@src/src/app/redux/controller/farm.slice';
import TagPigsPen from '../../tag-pigs/TagPigPen/TagPigsPen';
import { Modal as ModalAntd } from 'antd';
import moment from 'moment';
type Order = 'asc' | 'desc';

interface HeadCell {
    disablePadding: boolean;
    id: any;
    label: string;
    numeric: boolean;
}

const headCells: HeadCell[] = [
    { id: 'STT', numeric: false, disablePadding: true, label: 'STT' },
    { id: 'id', numeric: false, disablePadding: true, label: 'Tên chuồng' },
    { id: 'numberOfPig', numeric: false, disablePadding: false, label: 'Số lượng lợn (con)' },
    { id: 'pigType', numeric: false, disablePadding: false, label: 'Loại lợn' },
    { id: 'weightType', numeric: false, disablePadding: false, label: 'Cân nặng' },
    { id: 'pigStatus', numeric: false, disablePadding: false, label: 'Tình trạng' },
    { id: 'event', numeric: false, disablePadding: false, label: 'Diện tích (m²)' },
    { id: 'temperature', numeric: false, disablePadding: false, label: 'Nhiệt độ môi trường (°C)' },
    { id: 'humidity', numeric: false, disablePadding: false, label: 'Độ ẩm môi trường(%)' },
    { id: 'pressure', numeric: false, disablePadding: false, label: 'Áp suất môi trường(hPa)' },
    { id: 'gatewaycode', numeric: false, disablePadding: false, label: 'Gateway' },
    { id: 'lastSyncAt', numeric: false, disablePadding: false, label: 'Thời gian đo' },
    { id: 'action', numeric: false, disablePadding: false, label: 'Thao tác' },
];

interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof IPenData) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property: keyof IPenData) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {/* <StyledTableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                        className={classes.checkbox}
                    />
                </StyledTableCell> */}
                {headCells.map((headCell) => (
                    <StyledTableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            hideSortIcon={true}>
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

interface EnhancedTableToolbarProps {
    numSelected: number;
    page: number;
    totalPen: number
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const classes = useToolbarStyles();
    const { numSelected, totalPen, page } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}>
            {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    Đã chọn {numSelected} chuồng lợn
                </Typography>
            ) : (
                <Typography className={classes.title} id="tableTitle" component="div">
                    {totalPen} Chuồng nuôi <span>(Chuồng nuôi là chuồng đã có lợn và được hệ thống đánh số theo dõi)</span>
                </Typography>
            )}
            {/* {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                // <Tooltip title="Filter list">
                //     <IconButton aria-label="filter list">
                //         <SearchIcon />
                //     </IconButton>
                // </Tooltip>
                ''
            )} */}
        </Toolbar>
    );
};

const useToolbarStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(1),
        },
        highlight:
            theme.palette.type === 'light'
                ? {
                    color: theme.palette.secondary.main,
                    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
                }
                : {
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.secondary.dark,
                },
        title: {
            flex: '1 1 100%',
            color: '#ABABAB',
            fontSize: '24px',
        },
    }),
);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '80%',
            overflowX: 'hidden',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 1000,
            width: '100%',
            overflow: 'hidden',
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        warning: {
            color: 'red',
        },
        normal: {
            color: '#18191F',
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            alignSelf: 'center',
            // maxHeight: '700px'
        },
        modalContentCreate: {
            backgroundColor: 'white',
            width: '70%',
        },
        modalContentAlert: {
            width: '30%',
        },
        checkbox: {
            color: '#ABABAB',
        },
        tablePagination: {
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            flexDirection: 'row',
            marginTop: '20px',
        },
        breadcrumb: {
            cursor: 'pointer',
            // marginBottom: '-20px',
        },

    }),
);

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: '#FFDADE',
            color: '#18191F',
            fontWeight: 'bold',
            fontSize: '14px',
            textAlign: 'center',
        },
        body: {
            fontSize: '14px',
            border: 'none',
            textAlign: 'center',
        },
    }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:nth-of-type(even)': {
                backgroundColor: '#FFDADE',
            },
        },
    }),
)(TableRow);
interface DataItem {
    penId: string;
    farmId: string;
    batteryLevel: number;
    manufacturer: string;
    warrantyPeriod: number;
    tagId: string;
    createdAt: string;
    id: string;
    temperatureStatus: {
        tagId: string;
        timestamp: string;
        temperature: number;
        status: string;
        id: string;
    }
}

// Các cột của bảng
const columns = [
    {
        title: 'STT',
        dataIndex: 'ordinalNumber',
        key: 'ordinalNumber',
    },
    {
        title: 'Mã thẻ',
        dataIndex: 'tagId',
        key: 'tagId',
    },
    {
        title: 'Thân nhiệt hiện tại',
        dataIndex: 'temperature',
        key: 'temperature',
    },
    {
        title: 'Tình trạng bệnh',
        dataIndex: 'status',
        key: 'status',
    },

    {
        key: 'operation',
        title: 'Thao tác',
        dataIndex: 'operation',
    },
];

// 
const Pen: FC = () => {
    const classes = useStyles();
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof IPenData>('area');
    const [selected, setSelected] = useState<string[]>([]);
    const [openCU, setOpenCU] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openTemp, setOpenTemp] = useState(false);
    const [openCS, setOpenCS] = useState(false);
    const [pens, setPens] = useState<Array<IPenData | any>>([]);
    const [penSelected, setPenSelected] = useState<IPenData | any>();
    const [isUpdate, setIsUpdate] = useState(true);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [totalPen, setTotalPen] = useState<number>(0);
    const [isShowConfirmRestartAnalysis, setIsShowConfirmRestartAnalysis] = useState<boolean>(false);
    const { restartFlag } = useSelectorRoot(state => state.camera);
    const context = React.useContext(Context);
    const [offset, setOffset] = useState(0); // Vị trí bắt đầu lấy dữ liệu
    const [size, setSize] = useState(1000);
    const dispatch = useDispatchRoot();
    const [dataTagPigs, setDataTagPigs] = React.useState<any[]>([]); // Dữ liệu thẻ lợn
    const [currentPage, setCurrentPage] = useState(1); // Trang hiện tại
    const [pageSize, setPageSize] = useState(5); // Số thẻ lợn trên một trang
    const currentFarm = farmService.getCurrentFarm();
    const [lstPen, setLstPen] = useState<any[]>([]); // Danh sách chuồng
    const [tempPen, setTempPen] = useState<any[]>([]); // Danh sách chuồng
    const [tempTagByPen, setTempTagByPen] = useState<any[]>([]); // Danh sách chuồng
    const history = useHistory();
    const { tagPenId, dataTagPigSick, CpenSelected } = useSelectorRoot(state => state.farm);
    const [loading, setLoading] = useState(false);
    const [behaviorPen, setBehaviorPen] = useState<IBehaviorPen[]>([]); // Danh sách chuồng
    const [eatingPeriod, setEatingPeriod] = React.useState<any[]>([{ start: '9:00', end: '10:00', id: 0 }]);
    const [lyingPeriod, setLyingPeriod] = React.useState<any[]>([{ start: '12:00', end: '13:00', id: 0 }]);
    const [playingPeriod, setPlayingPeriod] = React.useState<any[]>([{ start: '15:00', end: '16:00', id: 0 }]);
    const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
    const [idDelete, setIdDelete] = useState<string>('');
    const [lstGateway, setLstGateway] = useState<any[]>([]); // Danh sách chuồng

    const loadBehaviorPenValue = async (id: string | undefined) => {
        await axios.get(`https://sit.api.pigman.com.vn/behaviourForPen/{penId}?penId=${id}`)
            .then((res: any) => {
                const behaviorPen = res.data.data;
                let indexEat = 0;
                let indexLie = 0;
                let indexPlay = 0;
                const lstEating: React.SetStateAction<any[]> = [];
                const lstLying: React.SetStateAction<any[]> = [];
                const lstPlaying: React.SetStateAction<any[]> = [];
                behaviorPen?.map((item: any) => {
                    const start = decimalToTime(item.start);
                    const end = decimalToTime(item.end);
                    if (item.behaviourType === 'Eat') {
                        lstEating.push({ start: start, end: end, id: indexEat++ });
                    }
                    if (item.behaviourType === 'Lie') {
                        lstLying.push({ start: start, end: end, id: indexLie++ });
                    }
                    if (item.behaviourType === 'Play') {
                        lstPlaying.push({ start: start, end: end, id: indexPlay++ });
                    }
                })
                console.log('lstEating', lstEating);

                setEatingPeriod(lstEating);
                setLyingPeriod(lstLying);
                setPlayingPeriod(lstPlaying);
            })
    }
    const decimalToTime = (decimalValue: any) => {
        const hours = Math.floor(decimalValue);
        const minutes = (decimalValue - hours) * 60;
        const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')}`;
        return formattedTime;
    };

    useEffect(() => {
        // console.log('dataTagPigSick', dataTagPigSick);
        // console.log('CpenSelected', CpenSelected);
        // if (dataTagPigSick.length > 0 && CpenSelected) {
        //     setOpenCS(true);
        // }

    }, [dataTagPigSick, CpenSelected]);

    useEffect(() => {
        dispatch(setCpenSelected(penSelected))
    }, [penSelected]);

    useEffect(() => {
        getAllPens();
        getAllPenByFarmId();
        const interval = setInterval(() => {
            const date = new Date();
            const minutes = date.getMinutes();
            console.log(minutes);

            // Mỗi 5 phút gọi api lấy dữ liệu
            if (minutes % 5 === 0) {
                getAllPens();
            }
        }, 60 * 1000);
        return () => clearInterval(interval);
    }, [page, context.changeLocation.isChangeLocation, restartFlag, lstGateway]);

    // Hàm thực hiện lấy dữ liệu lần đầu khi trang được load
    useEffect(() => {
        getAllGateway();
        loadLstPen();
    }, []);
    const getAllPens = async (isDelete = false) => {
        const params = {
            offset: UtilPage.calculateOffset(page),
            size: CFG_SIZE_PAGINATION,
            farmId: currentFarm?.id,
            name: '',
            area: 0,
            weightTypeId: '',
        };
        console.log(params);

        await apiPen
            .getAllPen(params)
            .then((res: any) => {
                console.log(res.items);
                // Lấy ra gateway  theo id của gateway chuồng đó
                const lstPen = res.items.map((item: any) => {
                    const gateway = lstGateway.find(gateway => gateway.code === item.gatewayCode);
                    if (gateway) {
                        console.log(gateway);
                        return {
                            ...item,
                            lastSyncAt: gateway.lastSyncAt,
                            temperature: item.temperature + parseFloat(gateway.offsetTemp),
                            humidity: item.humidity + parseFloat(gateway.offsetHumi),
                            pressure: item.pressure + parseFloat(gateway.offsetPress),
                        }
                    }
                    else {
                        return {
                            ...item,
                            lastSyncAt: '',
                            temperature: item.temperature,
                            humidity: item.humidity,
                            pressure: item.pressure,
                        }
                    }
                });
                console.log(lstPen);
                setPens(lstPen);
                setTotalPen(res.total);
                const numberPage = UtilPage.calculateNumberPage(res.total);
                setTotal(numberPage);
                isDelete && setPage(numberPage);
                getAllPenByFarmId();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const doDelete = async () => {
        setLoading(true);

        await axios.delete(`https://sit.api.pigman.com.vn/pens/${penSelected?.id}/deleteAllDataOfPen`)
            .then((res) => {
                setTimeout(() => {
                    // Hiển thị thông báo sửa thành công
                    notification['success']({
                        message: 'Xóa chuồng nuôi thành công',
                        // description:
                        // 'Đã Xóa chuồng vào cơ sở dữ liệu',
                    });
                    // Gọi hàm fetchData để lấy lại dữ liệu
                    getAllPens(true);
                    // Tắt modal thêm/sửa thẻ lợn
                    setOpenDelete(false);
                    // Tắt loading
                    setLoading(false);
                }, 1000);
            })
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IPenData) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = pens.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, penCode: any) => {
        const selectedIndex = selected.indexOf(penCode);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, penCode);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };


    const handleOpenCU = (penSelected?: IPenData) => {
        setPenSelected(penSelected);
        loadBehaviorPen(penSelected?.id);
        dispatch(setCpenSelected(undefined))
        dispatch(setDataTagPigSick([]))
        setOpenCU(true);
    };

    const handleOpenCS = (isUpdate: boolean, IpenSelected: IPenData) => {
        // handleClickLstTemp(penSelected?.id);
        loadTempPen(IpenSelected?.id);
        loadBehaviorPenValue(IpenSelected?.id);
        loadTempByPenId(IpenSelected);
        dispatch(setCpenSelected(undefined))
        dispatch(setDataTagPigSick([]))
        setPenSelected(IpenSelected);
    }

    const handleOpenDelete = (penSelected: IPenData) => {
        // setOpenDelete(true);
        // setIdDelete(penSelected.id);
        setIsShowDeleteModal(true);
        setPenSelected(penSelected);
        dispatch(setCpenSelected(undefined))
        dispatch(setDataTagPigSick([]))
    }
    const sortPenById = (id: string) => {
        const pen = lstPen?.find(pen => pen.id === id);
        return pen?.name;
    }
    const loadLstPen = async () => {
        await axios.get(`https://sit.api.pigman.com.vn/pens/allPens/${currentFarm?.id}?offset=${offset}&size=${size}`)
            .then(res => {
                const tmp: { id: any; name: any; }[] = []
                console.log(res);
                res.data.data.items.forEach((item: any) => {
                    item.analysisWarning !== null &&
                        tmp.push({
                            id: item.id,
                            name: item.name
                        })
                })
                setLstPen(tmp);
            })
    }
    const loadTempTagByPen = async (id: string) => {
        await axios.get(`https://sit.api.pigman.com.vn/temperatureTag/penId/${id}?offset=${offset}&size=${size}`)
            .then((res: any) => {
                console.log(res.data.data.items);
                if (res.data.data.items !== null && res.data.data.items.length > 0 && res.data.data.items[0] !== null) {
                    // Gán dữ liệu vào biến dataTagPigs
                    setDataTagPigs(res.data.data.items);
                    dispatch(setDataTagPigSick(res.data.data.items));
                }
                else {
                    notification['error']({
                        message: 'Không tìm thấy thẻ nhiệt',
                        description:
                            'Chưa có thẻ lợn nào được gắn vào chuồng này',
                    });
                    setDataTagPigs([]);
                    dispatch(setDataTagPigSick([]));

                }
            })
    }

    const loadBehaviorPen = async (id: string | undefined) => {
        await axios.get(`https://sit.api.pigman.com.vn/behaviourForPen/{penId}?penId=${id}`)
            .then((res: any) => {
                console.log(res.data.data);
                setBehaviorPen(res.data.data);
            })
    }

    const handleClickTemp = (penSelected?: IPenData) => {
        dispatch(setCpenSelected(penSelected))
        setPenSelected(penSelected);
        penSelected && loadTempTagByPen(penSelected.id);
    }
    const handleRestartAnalysisCam = (penSelected?: IPenData) => {
        setIsShowConfirmRestartAnalysis(true);
        setPenSelected(penSelected);
    };

    const importPig = () => {
        const data: IImportPig = {
            importPig: true,
            farmId: currentFarm?.id,
            penId: penSelected?.id
        }
        importPigFromAI(penSelected?.cameras[0]?.id, data)
            .then((res: any) => {
                console.log(res);
            })
            .catch((err) => {
                console.log('err:' + JSON.stringify(err));
            });
    }

    const handleCloseTemp = () => {
        setOpenTemp(false);
    }

    const handleCloseCU = () => {
        setOpenCU(false);
        setPenSelected(undefined);
    };

    const handleCloseCS = () => {
        setOpenCS(false);
        // setPenSelected(undefined);
    }


    const handleCloseDelete = () => {
        setOpenDelete(false);
        setPenSelected(undefined);
    };

    const onOkConfirmRestartAnalysis = () => {
        const importPigReqBody: IImportPigExpanded = {
            cameraId: penSelected?.cameras[0]?.id,
            importPig: true,
            farmId: currentFarm?.id,
            penId: penSelected?.id
        }
        const dataPayload = {
            analysisCamId: penSelected?.cameras[0]?.id,
            importPigReqBody: importPigReqBody
        }
        dispatch(restartAnalysisCameraReq(dataPayload));
        setIsShowConfirmRestartAnalysis(false);
        // getAllPens();
    }

    const onCloseConfirmRestartAnalysis = () => {
        setIsShowConfirmRestartAnalysis(false);
    }

    const isSelected = (penCode: any) => selected.indexOf(penCode) !== -1;
    // Hàm thực hiện gán classname cho các dòng của bảng
    const getRowClassName = (record: any, index: number) => {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
    };
    const handleChangePageTemp = (page: number, pageSize?: number) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setCurrentPage(page);
        pageSize && setPageSize(pageSize);
        // Tắt loading
    };

    // Hàm thực hiện thay đổi các giá trị trong phân trang
    const paginationConfig = {
        current: currentPage,
        pageSize,
        total: dataTagPigs.length,
        onChange: handleChangePageTemp,
        showSizeChanger: false,
        // showTotal: false,
        pageSizeOptions: ['10', '20', '30', '50'],
    };

    const handleCloseCUWithoutCancelStream = () => {
        setOpenCS(false);
        getAllPens();
    }
    // Hàm thực hiện lấy dữ liệu chuồng
    const loadTempByPenId = async (penSelected: IPenData) => {
        await axios.get(`https://sit.api.pigman.com.vn/temperatureTag/penId/${penSelected.id}?size=10000`)
            .then(res => {
                const tmp: any = [];
                res.data.data.items.forEach((item: any) => {
                    const name = item.name ? item.name : 'Heo ' + item.tagId;

                    tmp.push({ value: item.id, label: name })
                });
                console.log(tmp);

                setTempTagByPen(tmp);
            })
    }
    // Hàm thực hiện lấy dữ liệu chuồng
    const loadTempPen = async (penId: any) => {
        await axios.get(`https://sit.api.pigman.com.vn/temperatureTag/farm/${currentFarm.id}?size=1000`)
            .then(res => {
                const tmp: any = [];
                console.log(res.data?.data?.items);

                res.data?.data?.items.forEach((item: any) => {
                    if (!item.penId || item.penId === penId) {
                        const name = item.name ? item.name : 'Heo ' + item.tagId;
                        console.log(name);

                        tmp.push({ value: item.id, label: name })
                    }
                });
                console.log(tmp);

                setTempPen(tmp);
            })
    }

    useEffect(() => {
        loadTempPen(penSelected?.id);
    }, [penSelected])

    useEffect(() => {

        if (penSelected || tempTagByPen.length > 0) {
            setIsUpdate(true);
            setOpenCS(true);
        }
    }, [playingPeriod, tempTagByPen]);

    const setDataTagPigsByTag = (data: any[]) => {
        setDataTagPigs(data);
    }

    const [totalPigPen, setTotalPigPen] = useState<any>();

    const getAllPenByFarmId = async () => {
        await axios.get(`https://sit.api.pigman.com.vn/temperatureTag/all/byPen?farmId=${currentFarm.id}`)
            .then(res => {
                console.log(res.data.data);

                setTotalPigPen(res.data.data);
            })
    }

    useEffect(() => {
        getAllPenByFarmId();
    }, []);

    const [totalPigs, setTotalPigs] = useState(0);
    const getTagByPenId = (penId: string) => {
        const data = totalPigPen?.pens?.find((item: any) => item.penId === penId);
        return data?.total;
    }

    // const getTotalPigsByPenId = (penId: string) => {
    //     axios.get(`https://sit.api.pigman.com.vn/pigs/penId/${penId}`)
    //         .then(req => {
    //             console.log(req);
    //         })
    //         .catch(err => {
    //             console.log(err);

    //         })
    //     // const data = totalPigPen?.pens?.find((item: any) => item.penId === penId);
    //     // return data?.total;
    // }

    const handleFinishDeleteForm = () => {
        doDelete();
        setIsShowDeleteModal(false);
    }

    console.log(pens);

    const rowsPerPage = 10; // Number of rows to display per page
    const [currentPageTable, setCurrentPageTable] = useState(1);

    const startIndex = (currentPageTable - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const currentRows = pens.slice(startIndex, endIndex);
    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setCurrentPageTable(newPage);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const getAllGateway = async () => {
        await axios.get(`https://sit.api.pigman.com.vn/farms/getGateway/all`)
            .then(res => {
                console.log(res.data.data);
                setLstGateway(res.data.data);
            })
            .catch(err => {
                console.log(err);
            })
    }

    return (
        <>
            {(!CpenSelected || dataTagPigSick.length <= 0) ?
                <div className={`${classes.root} main-content`}>
                    <Spin spinning={loading} delay={500} tip="Đang lấy dữ liệu...">
                        <Paper className={classes.paper} elevation={0}>
                            <PageHeader pageTile="" isButton={false} />
                            <div className='main-breadcrumb'>
                                <Breadcrumb className={classes.breadcrumb}>
                                    <Breadcrumb.Item>
                                        <span onClick={() => history.push('/trang-trai')}>Trang trại</span>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>Chuồng nuôi</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                            <EnhancedTableToolbar numSelected={selected.length} totalPen={totalPen} page={total} />
                            <TableContainer>
                                <Table
                                    className={classes.table}
                                    aria-labelledby="tableTitle"
                                    size={'medium'}
                                    aria-label="enhanced table">
                                    <EnhancedTableHead
                                        classes={classes}
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={handleSelectAllClick}
                                        onRequestSort={handleRequestSort}
                                        rowCount={pens.length}
                                    />
                                    <TableBody>
                                        {currentRows.map((row, index) => {
                                            const isItemSelected = isSelected(row.id);
                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            return (
                                                <StyledTableRow
                                                    // hover
                                                    // role="checkbox"
                                                    // aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={index}
                                                    selected={isItemSelected}>
                                                    {/* <StyledTableCell padding="checkbox">
                                            <Checkbox
                                                checked={isItemSelected}
                                                inputProps={{ 'aria-labelledby': labelId }}
                                                className={classes.checkbox}
                                                onClick={(event) => handleClick(event, row.id)}
                                            /> 
                                        </StyledTableCell>  */}
                                                    <StyledTableCell align="right">{(currentPageTable - 1) * rowsPerPage + index + 1}</StyledTableCell>
                                                    <StyledTableCell component="th" id={labelId} scope="row" padding="none">
                                                        <div style={{ whiteSpace: 'nowrap' }}>
                                                            {row.name}
                                                        </div>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right">{(row.totalPigs) ? (row.totalPigs) : 'Chưa có dữ liệu'}</StyledTableCell>
                                                    <StyledTableCell align="right">{row.pigType.name}</StyledTableCell>
                                                    <StyledTableCell align="right">{row.weightType.name}</StyledTableCell>
                                                    <StyledTableCell
                                                        align="right"
                                                        className={row.penStatus !== 'Bình thường' ? 'warning-text' : 'normal'}>
                                                        {row.penStatus}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right">{row.area}</StyledTableCell>
                                                    {(row.temperature !== 0 && row.temperature) ?
                                                        <StyledTableCell align="right" style={{ width: 150 }}>{row.temperature}</StyledTableCell>
                                                        :
                                                        <StyledTableCell align="right" style={{ width: 150, }}>Không có dữ liệu</StyledTableCell>
                                                    }
                                                    {
                                                        (row.humidity !== 0 && row.humidity) ?
                                                            <StyledTableCell align="right" style={{ width: 150 }}>{row.humidity}</StyledTableCell>
                                                            :
                                                            <StyledTableCell align="right" style={{ width: 150 }}>Không có dữ liệu</StyledTableCell>
                                                    }
                                                    {
                                                        (row.pressure !== 0 && row.pressure) ?
                                                            <StyledTableCell align="right" style={{ width: 150 }}>{row.pressure}</StyledTableCell>
                                                            :
                                                            <StyledTableCell align="right" style={{ width: 150 }}>Không có dữ liệu</StyledTableCell>
                                                    }
                                                    {
                                                        (row.gatewayCode !== 0 && row.gatewayCode) ?
                                                            <StyledTableCell align="right" style={{ width: 150 }}>{row.gatewayCode}</StyledTableCell>
                                                            :
                                                            <StyledTableCell align="right" style={{ width: 150 }}>Không có dữ liệu</StyledTableCell>
                                                    }
                                                    {
                                                        (row.lastSyncAt !== 0 && row.lastSyncAt) ?
                                                            <StyledTableCell align="right" style={{ width: 150 }}>{moment(row.lastSyncAt).format('DD/MM/YYYY HH:mm:ss')}</StyledTableCell>
                                                            :
                                                            <StyledTableCell align="right" style={{ width: 150 }}>Không có dữ liệu</StyledTableCell>
                                                    }
                                                    <StyledTableCell align="right">
                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <Tooltip title="Xem">
                                                                <IconButton aria-label="Xem" onClick={() => handleOpenCU(row)}>
                                                                    <CameraAltIcon fontSize="small" />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Danh sách thẻ nhiệt">
                                                                <IconButton style={{ fontSize: 18 }} aria-label="Danh sách thẻ nhiệt" onClick={() => handleClickTemp(row)}>
                                                                    <FaRegListAlt />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Sửa chuồng">
                                                                <IconButton style={{ fontSize: 18 }} aria-label="Sửa chuồng" onClick={() => handleOpenCS(true, row)}>
                                                                    <FaEdit />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Xóa chuồng">
                                                                <IconButton style={{ fontSize: 18 }} aria-label="Xóa chuồng"
                                                                    onClick={() => handleOpenDelete(row)}
                                                                >
                                                                    <FaTrashAlt />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Môi trường chuồng ">
                                                                <IconButton style={{ fontSize: 18 }} aria-label="Môi trường chuồng " onClick={() => {
                                                                    history.push(`/moi-truong-chuong/${row.gatewayCode}`)
                                                                }}>
                                                                    {/* Cho tôi 1 cái icon biểu hiện cho gateway đo môi trường */}
                                                                    <FaTemperatureHigh />
                                                                </IconButton>
                                                            </Tooltip>
                                                            {/* <Tooltip title="Camera tại chuồng cần được phân tích lại">
                                                    {row.analysisStatus !== 1 ? <img style={{ marginTop: 12, width: 19, height: 19, cursor: 'pointer' }} id="restart-img" src={RestartIcon} alt='imageRestart' onClick={() => handleRestartAnalysisCam(row)} /> : <></>}
                                                </Tooltip> */}
                                                        </div>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Pagination
                                className={classes.tablePagination}
                                count={Math.ceil(pens.length / rowsPerPage)}
                                onChange={handleChangePage}
                            />
                            {/* <Pagination count={total} className={classes.tablePagination} onChange={handleChangePage} /> */}
                            <Modal
                                open={openCU}
                                onClose={handleCloseCU}
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description"
                                className={classes.modal}>
                                <div className={classes.modalContentCreate}>
                                    <ModalHeader title={`${penSelected?.name}`} closeButton={handleCloseCU} />
                                    <PenDetail
                                        penSelected={penSelected}
                                        behaviourPen={behaviorPen}
                                    />
                                </div>
                            </Modal>
                            <Modal
                                open={openCS}
                                onClose={handleCloseCS}
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description"
                                className={classes.modal}>
                                <div className={classes.modalContentCreate}>
                                    <ModalHeader title={isUpdate ? 'Cập nhật chuồng nuôi' : 'Tạo chuồng'} closeButton={handleCloseCS} />
                                    <div style={{ zIndex: 2000 }}>
                                        <PenCreate
                                            onClose={handleCloseCS}
                                            onRefresh={getAllPens}
                                            penData={penSelected}
                                            isUpdate={isUpdate}
                                            onCloseWithoutCancelStream={handleCloseCUWithoutCancelStream}
                                            tempPen={tempPen}
                                            dataTagPigsByPen={tempTagByPen}
                                            eatingPeriodLst={eatingPeriod}
                                            lyingPeriodLst={lyingPeriod}
                                            playingPeriodLst={playingPeriod}
                                        />
                                    </div>
                                </div>
                            </Modal>
                            <Modal
                                open={openTemp}
                                onClose={handleCloseCU}
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description"
                                className={classes.modal}>
                                <div className={classes.modalContentCreate}>
                                    <ModalHeader title={'Danh sách thẻ nhiệt'} closeButton={handleCloseTemp} />
                                    <div className='table-tag-pig'>
                                        <TableAntd
                                            className='table-temp'
                                            dataSource={dataTagPigs}
                                            columns={columns}
                                            rowClassName={getRowClassName}
                                            pagination={paginationConfig}
                                        />
                                    </div>
                                </div>
                            </Modal>
                            {/* <Modal
                                open={openDelete}
                                onClose={handleCloseDelete}
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description"
                                className={classes.modal}>
                                <div className={classes.modalContentAlert}>
                                    <CustomAlert
                                        title={`Chuồng nuôi này hiện có lợn, bạn có chắc chắn muốn xóa chuồng nuôi ${penSelected?.name}?`}
                                        okButton={doDelete}
                                        cancelButton={handleCloseDelete}
                                    />
                                </div>
                            </Modal> */}
                            {(isShowConfirmRestartAnalysis && penSelected?.cameras[0]) && <Modal
                                open={isShowConfirmRestartAnalysis}
                                onClose={onCloseConfirmRestartAnalysis}
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description"
                                className={classes.modal}>
                                <div className={classes.modalContentAlert}>
                                    <CustomAlert
                                        title={`Camera sẽ được phân tích lại. Có thể sẽ mất ít phút.`}
                                        okButton={onOkConfirmRestartAnalysis}
                                        cancelButton={onCloseConfirmRestartAnalysis}
                                    />
                                </div>
                            </Modal>
                            }
                            <ModalAntd
                                title="Xóa chuồng nuôi"
                                visible={isShowDeleteModal}
                                footer={null}
                                onCancel={() => setIsShowDeleteModal(false)}
                            >
                                <p>Bạn có chắc chắn muốn xóa chuồng nuôi này?</p>
                                <div className="modal-footer" style={{ textAlign: 'end' }}>
                                    <Button onClick={() => setIsShowDeleteModal(false)} style={{ marginRight: 10 }}>
                                        Hủy bỏ
                                    </Button>
                                    <Button type='primary' htmlType='submit' onClick={handleFinishDeleteForm}>
                                        Đồng ý
                                    </Button>
                                </div>


                            </ModalAntd>
                        </Paper>
                    </Spin>
                </div>
                :
                <TagPigsPen
                    penSelected={CpenSelected}
                    dataTagPigs={dataTagPigSick}
                    resetDataTagPigs={loadTempTagByPen}
                />
            }
        </>
    );
};

export default Pen;
