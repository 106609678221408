import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { useDispatchRoot } from '@src/src/app/redux/store';
import { notification } from 'antd';

const firebaseConfig = {
    apiKey: 'AIzaSyAV1TBVNA6krPDznNdVv_H7GjnHukc8kvU',
    authDomain: 'pigman-12ba8.firebaseapp.com',
    projectId: 'pigman-12ba8',
    storageBucket: 'pigman-12ba8.appspot.com',
    messagingSenderId: '697321494357',
    appId: '1:697321494357:web:9e3881aa440f80e09ac1dc',
    measurementId: 'G-XPT209GZFK',
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const fetchToken = (setTokenFound) => {
    return getToken(messaging, {
        vapidKey: 'BOu0c55QbVsgBpO6AoWveWi358ZAzZrNGwUlvHo2hw-WBA25b26AqM8sSAreFGGIi-e2BftyXF_TR0D9JGrDEnw',
    })
        .then((currentToken) => {
            if (currentToken) {
                console.log('current token for client: ', currentToken);
                setTokenFound(currentToken);
            } else {
                console.log('No registration token available. Request permission to generate one.');
                setTokenFound(null);
            }
        })
        .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
            setTokenFound(null);
        });
};

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });
