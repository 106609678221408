/* eslint-disable @typescript-eslint/no-explicit-any */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFarmData } from "../../types/farm";
import { IPenData } from "../../types/pen";

interface FarmState {
    lstEmptyPens: IPenData[],
    nameOfPensWarning: string[],
    isCloseWarning: boolean,
    selectedFarm: IFarmData | undefined,
    lstPens: IPenData[],
    lstFarms: any,
    lstAnalysisPens: IPenData[]
    tagPenId: IPenData | undefined
    clickPigLst: any,
    showNotification: boolean,
    showModalAddSickPig: boolean,
    countClickSaveFoodOrder: number,
    dataTagPigSick: [],
    CpenSelected: IPenData | undefined,
    currentStep: number,
    notEnoughFood: boolean,
    tempPigId: any | undefined,
    tokenPublic: string | undefined,
    clickNotificationType: string | undefined,
}
const initialStateBootstrap: FarmState = {
    lstEmptyPens: [],
    nameOfPensWarning: [],
    isCloseWarning: false,
    selectedFarm: undefined,
    lstPens: [],
    lstFarms: undefined,
    lstAnalysisPens: [],
    tagPenId: undefined,
    clickPigLst: undefined,
    showNotification: false,
    showModalAddSickPig: false,
    countClickSaveFoodOrder: 0,
    dataTagPigSick: [],
    CpenSelected: undefined,
    currentStep: 0,
    notEnoughFood: false,
    tempPigId: undefined,
    tokenPublic: undefined,
    clickNotificationType: undefined,
};

const farmSlice = createSlice({
    name: 'farm',
    initialState: initialStateBootstrap,
    reducers: {
        fetchLstEmptyPens: (state, action: PayloadAction<IPenData[]>) => {
            state.lstEmptyPens = action.payload
            const res: string[] = []
            action.payload.map(item => {
                if (item?.analysisWarning === true && item.name !== undefined) {
                    res.push(item.name)
                }
            })
            state.nameOfPensWarning = res;
        },
        setIsCloseWarning: (state, action: PayloadAction<boolean>) => {
            state.isCloseWarning = action.payload;
        },
        changeSelectedFarm: (state, action: PayloadAction<IFarmData | undefined>) => {
            state.selectedFarm = action.payload;
        },
        fetchLstPens: (state, action: PayloadAction<IPenData[]>) => {
            console.log(action.payload)
            state.lstPens = action.payload
        },
        updateFarmList: (state, action: PayloadAction<any>) => {
            state.lstFarms = action.payload;
        },
        fetchLstAnalysingPens: (state, action: PayloadAction<IPenData[]>) => {
            console.log(action.payload)
            state.lstPens = action.payload
        },

        setTagPenId: (state, action: PayloadAction<IPenData | undefined>) => {
            state.tagPenId = action.payload;
        },

        setClickPigLst: (state, action: PayloadAction<any>) => {
            state.clickPigLst = action.payload;
        },

        setShowNotification: (state, action: PayloadAction<boolean>) => {
            state.showNotification = action.payload;
        },

        setShowModalAddSickPig: (state, action: PayloadAction<boolean>) => {
            state.showModalAddSickPig = action.payload;
        },

        setCountClickSaveFoodOrder: (state) => {
            state.countClickSaveFoodOrder = state.countClickSaveFoodOrder + 1;
        },

        setDataTagPigSick: (state, action: PayloadAction<any>) => {
            console.log(action.payload);
            state.dataTagPigSick = action.payload;
        },

        setCpenSelected: (state, action: PayloadAction<IPenData | undefined>) => {
            console.log(action.payload);
            state.CpenSelected = action.payload;
        },

        setCurrentStep: (state, action: PayloadAction<number>) => {
            state.currentStep = action.payload;
        },

        setNotEnoughFood: (state, action: PayloadAction<boolean>) => {
            state.notEnoughFood = action.payload;
        },

        setTempPigId: (state, action: PayloadAction<any | undefined>) => {
            console.log(action.payload);

            state.tempPigId = action.payload;
        },

        setTokenPublic: (state, action: PayloadAction<string | undefined>) => {
            console.log(action.payload);

            state.tokenPublic = action.payload;
        },

        setClickNotificationType: (state, action: PayloadAction<string | undefined>) => {
            console.log(action.payload);

            state.clickNotificationType = action.payload;
        }

    }
})

export const {
    fetchLstEmptyPens,
    setIsCloseWarning,
    changeSelectedFarm,
    fetchLstPens,
    updateFarmList,
    setTagPenId,
    setClickPigLst,
    setShowNotification,
    setShowModalAddSickPig,
    setCountClickSaveFoodOrder,
    setDataTagPigSick,
    setCpenSelected,
    setCurrentStep,
    setNotEnoughFood,
    setTempPigId,
    setTokenPublic,
    setClickNotificationType,
} = farmSlice.actions;
export const farmReducer = farmSlice.reducer;