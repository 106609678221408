export enum AuthPopupOrigin {
    Header,
    GetStarted,
    None
}

class GlobalService {
    static authPopupOrigin: AuthPopupOrigin = AuthPopupOrigin.None;
    static color = '#fb8ca7';
}

export default GlobalService;