/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import PigItem from '../../common/pig-item/pig-item';
import { IBehaviorPen, IPenData } from '@type/pen';
import { UtilDate } from '@utils/date';
import CamList from './cam-list';
import { ICam } from '@src/src/app/types/cam';
import CamDetail from './cam-detail';
import { getBeHavioursOfAPen, getBeHaviourStatistic } from '@api/behaviour';
import { IBeHaviour } from '@src/src/app/types/behaviour';
import { useToggleModal } from '@src/src/app/elements/modal/modal';
import DetailPig from '../../pigs/detail';
import userServices from '@services/user';
import farmServices from '@services/farm';
import { IPigData } from '@src/src/app/types/pig';
import ModalHeader from '../../common/modal/modal-header';
import Modal from '@material-ui/core/Modal';
import { apiPen } from '@src/src/app/api/pen';
import { Button } from 'antd';
import { ScaleLoader } from 'react-spinners';

type PenDetailProps = {
    penSelected: IPenData;
    isEmptyPen?: boolean;
    behaviourPen?: IBehaviorPen[]
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            width: '100%',
            overflowY: 'scroll',
            height: '92vh'
            // maxHeight: '638px'
        },
        contentEmptyPen: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            width: '100%',
        },
        penVideo: {
            width: '100%',
        },
        penInfor: {
            width: '100%'
        },
        currentTime: {
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'row',
            fontSize: '12px',
            color: '#18191F',
            paddingTop: '20px',
            paddingBottom: '20px',
            paddingRight: '20px'
        },
        penList: {
            margin: '20px'
        },
        pigList: {
            overflowY: 'scroll',
            height: '500px'
        },
        warning: {
            color: 'red',
        },
        normal: {
            color: '#18191F',
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        modalContentCreate: {
            backgroundColor: 'white',
            width: '60%',
        },
        spinnerLoadingAPI: {
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%'
        },
    }),
);

const PenDetail: FC<PenDetailProps> = ({ penSelected, isEmptyPen, behaviourPen }: PenDetailProps) => {

    console.log(penSelected);


    const classes = useStyles();
    const [currentHour, setCurrentHour] = useState(UtilDate.currentHour());
    const [currentSecond, setCurrentSecond] = useState(UtilDate.currentSeconds());
    const [currentMinute, setCurrentMinute] = useState(UtilDate.currentMinute());
    const [camSelected, setCamSelected] = useState<ICam | any>();
    const [behavioursStatistic, setBehavioursStatistic] = useState<IBeHaviour[] | any>([{ eatingTimes: 0, lieDownTimes: 0, sleepingTimes: 0, standingTimes: 0, runningTimes: 0 }]);
    const [behavioursOfPen, setBehavioursOfPen] = useState<any>();
    // const [isShowDetailModal, toggleDetailModal] = useToggleModal();
    const [selectedPig, setSelectedPig] = useState<IPigData | null>(null);
    const user = userServices.get();
    const currentFarm: any = farmServices.getCurrentFarm() as any;
    const [isShowDetailModal, setIsShowModalDetail] = useState(false);
    const [numberPig, setNumberPig] = useState(0);
    const [loadingCamera, setLoadingCamera] = useState(false);

    const [penSelectReal, setPenSelectReal] = useState<IPenData | any>();

    useEffect(() => {
        apiPen.getTempTagByPenId(penSelected.id)
            .then((res: any) => {
                console.log(res.items);
                setNumberPig(res.items.length)
            })
    }, [penSelected]);


    useEffect(() => {
        if (isEmptyPen != true) {
            getBehavioursStatistic();
            getBehavioursOfAPenFunc();
            const intervalGetBehaviour = setInterval(getBehavioursOfAPenFunc, 10000);
            return () => {
                clearInterval(intervalGetBehaviour);
            }
        }
    }, []);

    const handleClosePigDetail = () => {
        setIsShowModalDetail(false);
    }

    const getBehavioursStatistic = () => {
        console.log('Show res');
        const params = {
            startDate: UtilDate.getStartedDate(new Date()).toISOString(),
            endDate: UtilDate.getEndedDate(new Date()).toISOString()
        }

        // getBeHaviourStatistic(penSelected.id, params)
        //     .then((res: any) => {
        //         setBehavioursStatistic(res);
        //         console.log('----------------');
        //         console.log(res)
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //     })

    }

    const getBehavioursOfAPenFunc = () => {
        console.log('Show res');
        const start_ = new Date(penSelected?.pigs[0].createdDate);
        start_.setDate(start_.getUTCDate());
        start_.setHours(0);
        start_.setMinutes(0);
        start_.setSeconds(0);
        const end_ = new Date()
        end_.setDate(end_.getUTCDate());
        // console.log(start_);
        // console.log(end_);
        // console.log(start_.toUTCString());
        // console.log(end_.toUTCString());
        const payload = {
            startDate: start_.toISOString(),
            endDate: end_.toISOString(),
            penId: penSelected.id,
            lstPigAIId: penSelected?.pigs?.map(item => item.pigAIId)
        }
        getBeHavioursOfAPen(payload)
            .then((res: any) => {
                console.log('Show res');
                console.log(res);

                // Lấy ra tổng thời gian trong mảng 2 chiều
                const tmp = [];
                for (let index = 1; index <= penSelected?.pigs?.length; index++) {
                    let eatingTime = 0;
                    let standingTime = 0;
                    let lieDownTime = 0;
                    let runningTime = 0;
                    let unspecified = 0;
                    for (let j = 0; j < res[index]?.length; j++) {
                        eatingTime += res[index][j].eatingTimes;
                        standingTime += res[index][j].standingTimes;
                        lieDownTime += res[index][j].lieDownTimes;
                        runningTime += res[index][j].runningTimes;
                        unspecified += res[index][j].unspecified;
                    }
                    tmp.push({
                        pigAIId: index,
                        total: ((eatingTime + standingTime + lieDownTime + runningTime + unspecified) / 60).toFixed(2),
                        eatingTimes: (eatingTime / 60).toFixed(2),
                        standingTimes: (standingTime / 60).toFixed(2),
                        lieDownTimes: (lieDownTime / 60).toFixed(2),
                        runningTimes: (runningTime / 60).toFixed(2),
                        unspecified: (unspecified / 60).toFixed(2),
                    })
                }
                console.log(tmp);
                // Sắp xếp mảng theo total 
                tmp.sort((a, b) => {
                    return parseFloat(b.total) - parseFloat(a.total);
                })

                console.log(tmp);

                // Sắp xếp penSelected.pigs theo thứ tự của mảng tmp 
                const tmp2 = [];
                for (let index = 0; index < tmp.length; index++) {
                    for (let j = 0; j < penSelected?.pigs?.length; j++) {
                        if (tmp[index].pigAIId === parseInt(penSelected?.pigs[j].pigAIId)) {
                            tmp2.push(penSelected?.pigs[j]);
                        }

                    }
                }
                console.log(tmp2);

                setPenSelectReal({
                    ...penSelected,
                    pigs: tmp2
                } as IPenData);

                setBehavioursOfPen(res);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const camSelectedHandle = (camSelected: ICam) => {
        setCamSelected(camSelected);
    }

    const backOnClick = () => {
        setCamSelected(undefined);
    }

    const onClickDetailPig = (pig: IPigData) => {
        setIsShowModalDetail(true);
        setSelectedPig(pig);
    }
    const decimalToTime = (decimalValue: any) => {
        const hours = Math.floor(decimalValue);
        const minutes = (decimalValue - hours) * 60;
        const formattedTime = `${hours}:${Math.round(parseInt(minutes.toString().padStart(2, '0')))}`;
        return formattedTime;
    };

    const loadingNoCamera = () => {
        setLoadingCamera(true);
        setTimeout(() => {
            setLoadingCamera(false);
        }, 2000);
    }
    return (
        <div className={isEmptyPen ? classes.contentEmptyPen : classes.content}>
            {
                (penSelected.cameras.length > 1 && !camSelected) && (
                    <div className={classes.penVideo}>
                        <CamList camLists={penSelected.cameras} camSelected={camSelectedHandle} />
                    </div>
                )
            }
            {
                (penSelected.cameras.length == 1 && !camSelected) && (
                    <div className={classes.penVideo}>
                        <CamDetail penSelected={penSelected} camSelectedData={penSelected?.cameras[0]} isBackOnClick={false} isEmptyPen={isEmptyPen} />
                    </div>
                )
            }
            {
                camSelected && (
                    <div className={classes.penVideo}>
                        <CamDetail penSelected={penSelected} camSelectedData={camSelected} backOnClick={backOnClick} isBackOnClick={true} isEmptyPen={isEmptyPen} />
                    </div>
                )
            }
            {
                (penSelected.cameras.length == 0 && !camSelected) && (
                    <div className={classes.penVideo}>
                        {!loadingCamera ?
                            <div className='pen-video' >
                                <div className='pen-video-text'>
                                    KHÔNG CÓ KẾT NỐI
                                </div>
                                <Button className='pen-video-button' onClick={loadingNoCamera}>Thử lại</Button>
                            </div>
                            :
                            <div className='pen-video-loading'>
                                <div className={classes.spinnerLoadingAPI}>
                                    <ScaleLoader color={"#FF5A80"} loading={loadingCamera} />
                                </div>
                            </div>

                        }
                    </div>
                )
            }
            {!isEmptyPen && <div className={classes.penInfor}>
                {/* <div className={classes.currentTime}>
                    Thời gian: Hiện tại ({currentHour}:{currentMinute}:{currentSecond}, {UtilDate.formatDate(UtilDate.currentDateFull())})
                </div> */}
                <div className={classes.penList}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={5}>
                                <Box display="flex" justifyContent="flex-start" p={1}>
                                    <span style={{ fontSize: '16px' }}>Tình trạng:</span> <span style={{ fontWeight: 'bold', fontSize: '16px', marginLeft: '5px' }} className={penSelected.penStatus == "Bất thường" ? classes.warning : classes.normal}>{penSelected.penStatus}</span>
                                </Box>
                                {/* <Box display="flex" justifyContent="flex-start" p={1}>
                                    <span style={{ fontSize: '16px', color: '#18191F' }}>Thời gian đi trung bình: {behavioursStatistic[0].run ? behavioursStatistic[0].runningTimes / 60 : "0"} phút/ngày</span>
                                </Box>
                                <Box display="flex" justifyContent="flex-start" p={1}>
                                    <span style={{ fontSize: '16px', color: '#18191F' }}>Thời gian đứng trung bình: {behavioursStatistic[0].standingTimes ? parseFloat((behavioursStatistic[0].standingTimes / 60).toFixed(2)) : "0"} phút/ngày</span>
                                </Box>
                                <Box display="flex" justifyContent="flex-start" p={1}>
                                    <span style={{ fontSize: '16px', color: '#18191F' }}>Thời gian nằm trung bình: {behavioursStatistic[0].lieDownTimes ? (behavioursStatistic[0].lieDownTimes / 60).toFixed(2) : "0"} phút/ngày</span>
                                </Box>
                                <Box display="flex" justifyContent="flex-start" p={1}>
                                    <span style={{ fontSize: '16px', color: '#18191F' }}>Thời gian ăn trung bình: {behavioursStatistic[0].eatingTimes ? (behavioursStatistic[0].eatingTimes / 60).toFixed(2) : "0"} phút/ngày</span>
                                </Box> */}

                                <Box display="flex" justifyContent="flex-start" p={1}>
                                    <span style={{ fontSize: '16px', color: '#18191F' }}>Diện tích chuồng: {penSelected.area} (m²)</span>
                                </Box>
                                <Box display="flex" justifyContent="flex-start" p={1}>
                                    {/* <span style={{ fontSize: '16px', color: '#18191F' }}>Số lượng: {numberPig ? numberPig : '0'} (con)</span> */}
                                    <span style={{ fontSize: '16px', color: '#18191F' }}>{penSelected.totalPigs ? `Số lượng: ${penSelected.totalPigs} con` : 'Số lượng: Chưa có dữ liệu'}</span>

                                </Box>
                                <Box display="flex" justifyContent="flex-start" p={1}>
                                    <span style={{ fontSize: '16px', color: '#18191F' }}>Loại lợn: {penSelected.pigType.name}</span>
                                </Box>
                                <Box display="flex" justifyContent="flex-start" p={1}>
                                    <span style={{ fontSize: '16px', color: '#18191F' }}>Cân nặng: {penSelected.weightType.name}</span>
                                </Box>
                                <Box display="flex" justifyContent="flex-start" p={1}>
                                    <span style={{ fontSize: '16px', color: '#18191F' }}>Nguồn gốc lợn: {penSelected.origin}</span>
                                </Box>
                                <Box display="flex" justifyContent="flex-start" p={1}>
                                    <span style={{ fontSize: '16px', color: '#18191F' }}>Ngưỡng thân nhiệt: {penSelected.lowerTemperatureThreshold}°C - {penSelected.upperTemperatureThreshold}°C</span>
                                </Box>
                                {(behaviourPen && behaviourPen.length > 0) &&
                                    <>

                                        <Box display="flex" justifyContent="flex-start" p={1}>
                                            <div>
                                                <div style={{ fontSize: '16px' }}>Khung giờ ăn: </div>
                                                <div className='lst-behaviour-pen'>
                                                    {
                                                        behaviourPen?.map((item: any, index: number) => (
                                                            <>
                                                                {item.behaviourType === 'Eat' &&
                                                                    <div style={{ fontWeight: 'bold', fontSize: '14px' }}>{decimalToTime(item.start)}h - {decimalToTime(item.end)}h</div>
                                                                }
                                                            </>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </Box>
                                        <Box display="flex" justifyContent="flex-start" p={1}>
                                            <div>
                                                <div style={{ fontSize: '16px' }}>Khung giờ nằm: </div>
                                                <div className='lst-behaviour-pen'>
                                                    {
                                                        behaviourPen?.map((item: any, index: number) => (
                                                            <>
                                                                {item.behaviourType === 'Lie' &&
                                                                    <div style={{ fontWeight: 'bold', fontSize: '14px' }}>{decimalToTime(item.start)}h - {decimalToTime(item.end)}h</div>
                                                                }
                                                            </>
                                                        )
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </Box>
                                        <Box display="flex" justifyContent="flex-start" p={1}>
                                            <div>
                                                <div style={{ fontSize: '16px' }}>Khung giờ chơi:</div>
                                                <div className='lst-behaviour-pen'>
                                                    {
                                                        behaviourPen?.map((item: any, index: number) => (
                                                            <>
                                                                {item.behaviourType === 'Play' &&
                                                                    <div style={{ fontWeight: 'bold', fontSize: '14px' }}>{decimalToTime(item.start)}h - {decimalToTime(item.end)}h</div>
                                                                }
                                                            </>
                                                        )
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </Box>
                                    </>
                                }
                                <Box display="flex" justifyContent="flex-start" p={1}>
                                    <span style={{ fontSize: '16px', color: '#18191F' }}>Ghi chú: {penSelected.note ? penSelected.note : 'Chưa có'}</span>
                                </Box>
                            </Grid>
                            <Grid item xs>
                                <div className={classes.pigList}>
                                    {(penSelected?.pigs?.length == 0 && (penSelected?.pigs[0]?.pigAIId === '')) &&
                                        <div style={{ fontSize: '16px', color: '#18191F' }}>Hệ thống phát hiện chuồng không có lợn</div>
                                    }
                                    {(penSelectReal?.pigs[0]?.pigAIId) && penSelectReal?.pigs?.map((pig: any, index: any) => {
                                        if ((behavioursOfPen && behavioursOfPen[`${pig.pigAIId}`] != null)) {
                                            // const behaviourObj = behavioursOfPen[`${pig.pigAIId}`][0] as IBeHaviour;

                                            const behaviourObj: IBeHaviour = {
                                                date: behavioursOfPen[`${pig.pigAIId}`][0]?.date,
                                                eatingTimes: 0,
                                                farmId: '',
                                                lieDownTimes: 0,
                                                pigAIId: behavioursOfPen[`${pig.pigAIId}`][0]?.pigAIId,
                                                sleepingTimes: 0,
                                                standingTimes: 0,
                                                runningTimes: 0,
                                                unspecified: 0,
                                                penId: behavioursOfPen[`${pig.pigAIId}`][0]?.penId,
                                                startTime: '',
                                                endTime: '',
                                                id: ''
                                            }

                                            for (let i = 0; i < behavioursOfPen[`${pig.pigAIId}`]?.length; i++) {
                                                behaviourObj.eatingTimes += behavioursOfPen[`${pig.pigAIId}`][i]?.eatingTimes;
                                                behaviourObj.runningTimes += behavioursOfPen[`${pig.pigAIId}`][i]?.runningTimes;
                                                behaviourObj.standingTimes += behavioursOfPen[`${pig.pigAIId}`][i]?.standingTimes;
                                                behaviourObj.lieDownTimes += behavioursOfPen[`${pig.pigAIId}`][i]?.lieDownTimes;
                                                behaviourObj.unspecified += behavioursOfPen[`${pig.pigAIId}`][i]?.unspecified;
                                            }
                                            return (
                                                <PigItem
                                                    key={index}
                                                    runningTime={`${behaviourObj?.runningTimes ? (behaviourObj?.runningTimes / 60).toFixed(2) : 0} phút`}
                                                    standingTime={`${behaviourObj?.standingTimes ? (behaviourObj?.standingTimes / 60).toFixed(2) : 0} phút`}
                                                    lieDownTime={`${behaviourObj?.lieDownTimes ? (behaviourObj?.lieDownTimes / 60).toFixed(2) : 0} phút`}
                                                    eatingTime={`${behaviourObj?.eatingTimes ? (behaviourObj?.eatingTimes / 60).toFixed(2) : 0} phút`}
                                                    pigAIId={`pig-${pig.pigAIId}`}
                                                    pigSelected={false}
                                                    onClick={() => {
                                                        onClickDetailPig(pig);
                                                    }}
                                                    pigInfo={pig}
                                                    lastUpdate={penSelected.last_update_date}
                                                />
                                            );
                                        }
                                    })}
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                    {(isShowDetailModal && selectedPig != null) && (
                        // <div className={classes.modalContentCreate}>
                        //     <ModalHeader title='Chi tiết cá thể' closeButton={handleClosePigDetail} />
                        //     <DetailPig
                        //         dataRenderProps={{ pigId: selectedPig.id, pigAIId: '', user: { ...user, defaultFarm: currentFarm } }}
                        //     />
                        // {/* <Modal title={'Chi tiết'} toggleModal={toggleDetailModal} isShowModal={isShowDetailModal} >
                        //     <DetailPig
                        //         dataRenderProps={{ pigId: selectedPig.id, pigAIId: '', user: { ...user, defaultFarm: currentFarm } }}
                        //     />
                        // </Modal> */}
                        // </div>
                        <Modal
                            open={isShowDetailModal}
                            onClose={handleClosePigDetail}
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            className={classes.modal}
                        >
                            <div className={classes.modalContentCreate}>
                                <ModalHeader title='Chi tiết lợn' closeButton={handleClosePigDetail} />
                                <DetailPig
                                    dataRenderProps={{ pigId: selectedPig.id, pigAIId: '', user: { ...user, defaultFarm: currentFarm } }}
                                    lastUpdate={penSelected.last_update_date}
                                />
                            </div>
                        </Modal>
                    )}
                </div>
            </div>}
        </div>
    );
};

export default PenDetail;
