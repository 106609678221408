/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import { withStyles, createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Modal from '@material-ui/core/Modal';
import Pagination from '@material-ui/lab/Pagination';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { v4 as uuidv4 } from 'uuid';

import PageHeader from '../../common/page-header/page-header';
import ModalHeader from '../../common/modal/modal-header';
import CustomAlert from '../../common/alert/custom-alert';

import { CFG_SIZE_PAGINATION } from '@constants/config';
import farmService from '@services/farm';
import { apiPen } from '@api/pen';
import { IPenData } from '@type/pen';
import { UtilPage } from '@utils/page';
import { Context } from '@components/context/context';

import { Breadcrumb, Button, message, notification } from 'antd';
import { IImportPig } from '@src/src/app/types/pig';
import { importPigFromAI } from '@src/src/app/api/pig';
import PenDetail from '../pen/pen-detail';
import { useDispatchRoot, useSelectorRoot } from '@src/src/app/redux/store';
import { fetchLstEmptyPens, fetchLstPens, setIsCloseWarning } from '@src/src/app/redux/controller/farm.slice';
import { NotificationType } from '../../common/notification/Notification';
import openNotification from '../../common/notification/Notification';
import CImageLoading from '../../video/CImageLoading';
import StreamAPI from '../../../api/camera/streaming.api';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import PenEmptyCreat from './pen-empty-create';
import { Modal as ModalAntd } from 'antd';

type Order = 'asc' | 'desc';

interface HeadCell {
    disablePadding: boolean;
    id: any;
    label: string;
    numeric: boolean;
}

const headCells: HeadCell[] = [
    { id: 'stt', numeric: false, disablePadding: true, label: 'STT' },
    { id: 'name', numeric: false, disablePadding: true, label: 'Tên chuồng' },
    { id: 'area', numeric: false, disablePadding: false, label: 'Diện tích(m²)' },
    // { id: 'capacity', numeric: false, disablePadding: false, label: 'Số lượng' },
    { id: 'pigType', numeric: false, disablePadding: false, label: 'Loại lợn' },
    { id: 'weightType', numeric: false, disablePadding: false, label: 'Cân nặng' },
    { id: 'temperature', numeric: false, disablePadding: false, label: 'Nhiệt độ (°C)' },
    { id: 'humidity', numeric: false, disablePadding: false, label: 'Độ ẩm (%)' },
    { id: 'pressure', numeric: false, disablePadding: false, label: 'Áp suất (hPa)' },
    { id: 'action', numeric: false, disablePadding: false, label: 'Thao tác' },
];

interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof IPenData) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property: keyof IPenData) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {/* <StyledTableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                        className={classes.checkbox}
                    />
                </StyledTableCell> */}
                {headCells.map((headCell) => (
                    <StyledTableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            hideSortIcon={true}>
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

interface EnhancedTableToolbarProps {
    numSelected: number;
    page: number;
    total?: number;
    deleteAll: () => void;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const classes = useToolbarStyles();
    const { numSelected, page, total, deleteAll } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}>
            {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    Đã chọn {numSelected} chuồng lợn
                </Typography>
            ) : (
                <Typography className={classes.title} id="tableTitle" component="div">
                    {total} Chuồng trống <span>(Chuồng trống là chuồng không có lợn hoặc có lợn nhưng chưa thao tác đánh số theo dõi)</span>
                </Typography>
            )}
            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <div onClick={deleteAll}>
                        <IconButton aria-label="delete">
                            <DeleteIcon />
                        </IconButton>
                    </div>
                </Tooltip>
            ) : (
                // <Tooltip title="Filter list">
                //     <IconButton aria-label="filter list">
                //         <SearchIcon />
                //     </IconButton>
                // </Tooltip>
                ''
            )}
        </Toolbar>
    );
};

const useToolbarStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(1),
        },
        highlight:
            theme.palette.type === 'light'
                ? {
                    color: theme.palette.secondary.main,
                    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
                }
                : {
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.secondary.dark,
                },
        title: {
            flex: '1 1 100%',
            color: '#ABABAB',
            fontSize: '24px',
        },
    }),
);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '80%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        warning: {
            color: 'red',
        },
        normal: {
            color: '#18191F',
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '700!important' as any
        },
        modalContentCreate: {
            // backgroundColor: 'white',
            // width: '80%',
            // zIndex: '800!important' as any
            backgroundColor: 'white',
            width: '72%',
        },
        modalContentAlert: {
            width: '30%',
        },
        checkbox: {
            color: '#ABABAB',
        },
        tablePagination: {
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            flexDirection: 'row',
            marginTop: '20px',
        },
        videoItemFullScreen: {
            marginLeft: '10px',
            marginRight: '10px'
        },
        breadcrumb: {
            cursor: 'pointer',
        }
    }),
);

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: '#FFDADE',
            color: '#18191F',
            fontWeight: 'bold',
            fontSize: '14px',
            textAlign: 'center',
        },
        body: {
            fontSize: '14px',
            border: 'none',
            textAlign: 'center',
        },
    }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:nth-of-type(even)': {
                backgroundColor: '#FFDADE',
            },
        },
    }),
)(TableRow);

const PenEmpty: FC = () => {
    const classes = useStyles();
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof IPenData>('area');
    const [selected, setSelected] = useState<string[]>([]);
    const [openCU, setOpenCU] = useState(false);
    const [openViewCam, setOpenViewCam] = useState<boolean>(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [pens, setPens] = useState<Array<IPenData | any>>([]);
    const [penSelected, setPenSelected] = useState<IPenData | any>();
    const [isUpdate, setIsUpdate] = useState(false);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [totalPen, setTotalPen] = useState<number>(0);
    const [first, setFirst] = useState<boolean>(true);
    const [tempPen, setTempPen] = useState<any[]>([]); // Danh sách chuồng
    const context = React.useContext(Context);
    const [updateList, setUpdateList] = useState(new Date());
    const currentFarm = farmService.getCurrentFarm();
    const [tempTagByPen, setTempTagByPen] = useState<any[]>(); // Danh sách chuồng
    const [clickOpenCU, setClickOpenCU] = useState(false);
    const dispatch = useDispatchRoot();
    const { nameOfPensWarning, isCloseWarning } = useSelectorRoot(state => state.farm)
    const history = useHistory();
    const [eatingPeriod, setEatingPeriod] = React.useState<any[]>([{ start: '9:00', end: '10:00', id: 0 }]);
    const [lyingPeriod, setLyingPeriod] = React.useState<any[]>([{ start: '12:00', end: '13:00', id: 0 }]);
    const [playingPeriod, setPlayingPeriod] = React.useState<any[]>([{ start: '15:00', end: '16:00', id: 0 }]);
    const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
    const [idDelete, setIdDelete] = useState<string>('');
    // Hàm thực hiện lấy dữ liệu chuồng
    const loadTempPen = async (penId: any) => {
        await axios.get(`https://sit.api.pigman.com.vn/temperatureTag/farm/${currentFarm.id}?size=1000`)
            .then(res => {
                const tmp: any = [];
                console.log(res.data?.data?.items);

                res.data?.data?.items.forEach((item: any) => {
                    if (!item.penId || item.penId === penId) {
                        const name = item.name ? item.name : 'Heo ' + item.tagId;
                        console.log(name);

                        tmp.push({ value: item.id, label: name })
                    }
                });
                console.log(tmp);

                setTempPen(tmp);
            })
    }
    // Hàm thực hiện lấy dữ liệu chuồng
    const loadTempByPenId = async (penSelected: IPenData) => {
        await axios.get(`https://sit.api.pigman.com.vn/temperatureTag/penId/${penSelected.id}?size=1000`)
            .then(res => {
                const tmp: any = [];
                res.data.data.items.forEach((item: any) => {
                    const name = item.name ? item.name : 'Heo ' + item.tagId;

                    tmp.push({ value: item.id, label: name })
                });
                console.log(tmp);
                setTempTagByPen(tmp);
            })
    }

    const loadBehaviorPen = async (id: string | undefined) => {
        await axios.get(`https://sit.api.pigman.com.vn/behaviourForPen/{penId}?penId=${id}`)
            .then((res: any) => {
                const behaviorPen = res.data.data;
                let indexEat = 0;
                let indexLie = 0;
                let indexPlay = 0;
                const lstEating: React.SetStateAction<any[]> = [];
                const lstLying: React.SetStateAction<any[]> = [];
                const lstPlaying: React.SetStateAction<any[]> = [];
                behaviorPen?.map((item: any) => {
                    const start = decimalToTime(item.start);
                    const end = decimalToTime(item.end);
                    if (item.behaviourType === 'Eat') {
                        lstEating.push({ start: start, end: end, id: indexEat++ });
                    }
                    if (item.behaviourType === 'Lie') {
                        lstLying.push({ start: start, end: end, id: indexLie++ });
                    }
                    if (item.behaviourType === 'Play') {
                        lstPlaying.push({ start: start, end: end, id: indexPlay++ });
                    }
                })
                console.log('lstEating', lstEating);

                setEatingPeriod(lstEating);
                setLyingPeriod(lstLying);
                setPlayingPeriod(lstPlaying);
            })
    }

    const decimalToTime = (decimalValue: any) => {
        const hours = Math.floor(decimalValue);
        const minutes = (decimalValue - hours) * 60;
        const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')}`;
        return formattedTime;
    };

    const onCloseWarning = () => {
        dispatch(setIsCloseWarning(true))
    }

    const fetchAllPensData = async () => {
        const allPens: any = currentFarm && await apiPen.getAllPensByFarmId({ farmId: currentFarm.id, offset: 0, size: 150 });
        console.log(allPens)
        dispatch(fetchLstPens(allPens.items))
    }

    useEffect(() => {
        loadTempPen(penSelected?.id);
    }, [penSelected])

    useEffect(() => {
        dispatch(fetchLstEmptyPens(pens));
        dispatch(setIsCloseWarning(false));
        fetchAllPensData();
    }, [pens])

    useEffect(() => {
        if (!isCloseWarning && nameOfPensWarning.length > 0 && first) {
            let lstPenName = '';
            nameOfPensWarning.map(item => {
                lstPenName += item + ','
            })
            lstPenName = lstPenName.substring(0, lstPenName.length - 1)
            openNotification(NotificationType.Warning, 'topRight', `Cảnh báo hệ thống`, `Có ${nameOfPensWarning.length} chuồng nuôi đã nhập lợn nhưng chưa được hệ thống theo dõi. Bao gồm: ${lstPenName}.`, onCloseWarning)
            setFirst(false);
        }
    }, [isCloseWarning, nameOfPensWarning])

    useEffect(() => {
        getAllPenEmptys();
        return getAllPenEmptys();
    }, [page, context.changeLocation.isChangeLocation, updateList]);

    const getAllPenEmptys = (isDelete = false) => {
        const params = {
            offset: UtilPage.calculateOffset(page),
            size: CFG_SIZE_PAGINATION,
            farmId: currentFarm.id,
            name: '',
            area: 0,
            weightTypeId: '',
        };

        apiPen
            .getAllPenEmpty(params)
            .then((res: any) => {
                console.log(res);

                setPens(res.items);
                setTotalPen(res.total);
                const numberPage = UtilPage.calculateNumberPage(res.total);
                setTotal(numberPage);
                isDelete && setPage(numberPage);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const doDelete = async () => {
        await axios.delete(`https://sit.api.pigman.com.vn/pens/${penSelected?.id}/deleteAllDataOfPen`)
            .then((res) => {
                getAllPenEmptys(true);
                handleCloseDelete();
                openNotification(NotificationType.Info, 'topRight', `PIGMAN`, `Xóa chuồng trống thành công.`);
            })
            .catch((err) => {
                console.log('doDelete-err:' + JSON.stringify(err));
            });
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IPenData) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = pens.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, penCode: any) => {
        const selectedIndex = selected.indexOf(penCode);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, penCode);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const setDefaultValueBehavior = () => {
        setEatingPeriod([{ start: '9:00', end: '10:00', id: 0 }]);
        setLyingPeriod([{ start: '12:00', end: '13:00', id: 0 }]);
        setPlayingPeriod([{ start: '15:00', end: '16:00', id: 0 }]);
    }

    const handleOpenCU = (isUpdate: boolean, penSelected?: IPenData) => {
        loadTempPen(penSelected?.id);
        if (isUpdate) {
            loadBehaviorPen(penSelected?.id);
        }
        else {
            setDefaultValueBehavior();
        }
        setPenSelected(penSelected);
        if (penSelected)
            loadTempByPenId(penSelected);
        else {
            setTempTagByPen([]);
        }
        setIsUpdate(isUpdate ? true : false);
        setClickOpenCU(true);
    };

    const importPig = () => {
        const data: IImportPig = {
            importPig: false,
            farmId: currentFarm?.id,
            penId: penSelected?.id
        }
        importPigFromAI(penSelected?.cameras[0]?.id, data)
            .then((res: any) => {
                console.log(res);
            })
            .catch((err) => {
                console.log('err:' + JSON.stringify(err));
            });
    }

    const handleCloseCU = () => {
        setOpenCU(false);
        setClickOpenCU(false);
        getAllPenEmptys();
        importPig()
    };

    const handleOpenViewCam = (penSelected?: IPenData) => {
        setOpenViewCam(true);
        setPenSelected(penSelected);

    };

    const handleCloseViewCam = () => {
        setOpenViewCam(false);
    };

    const handleCloseCUWithoutCancelStream = () => {
        setOpenCU(false);
        getAllPenEmptys();
    }

    const handleOpenDelete = (penSelected?: IPenData) => {
        // setIdDelete(penSelected?.id);
        setIsShowDeleteModal(true);
        // setOpenDelete(true);
        setPenSelected(penSelected);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    const isSelected = (penCode: any) => selected.indexOf(penCode) !== -1;

    const deleteAll = () => {
        const deletePensApi = selected.map((v) => {
            return apiPen.deletePen(v);
        });

        Promise.all(deletePensApi).then(() => {
            notification.success({
                message: 'Xóa thành công',
            } as any);
            setUpdateList(new Date());
            setSelected([]);
        });
    };

    useEffect(() => {
        if (clickOpenCU) {
            setOpenCU(true);
            setIsUpdate(isUpdate);
        }
    }, [playingPeriod]);

    const handleFinishDeleteForm = () => {
        doDelete();
        setIsShowDeleteModal(false);
    }
    const rowsPerPage = 10; // Number of rows to display per page
    const [currentPageTable, setCurrentPageTable] = useState(1);

    const startIndex = (currentPageTable - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const currentRows = pens.slice(startIndex, endIndex);
    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setCurrentPageTable(newPage);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div className={`${classes.root} main-content`}>
            <Paper className={classes.paper} elevation={0}>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                    <div className='main-breadcrumb'>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <span onClick={() => history.push('/trang-trai')}>Trang trại</span>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>Chuồng trống</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <PageHeader
                        pageTile=""
                        isButton={true}
                        buttonClicked={() => handleOpenCU(false)}
                        buttonTitle="Tạo chuồng"
                    />
                </div>
                <EnhancedTableToolbar numSelected={selected.length} page={total} total={totalPen} deleteAll={deleteAll} />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="enhanced table">
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={pens.length}
                        />
                        <TableBody>
                            {currentRows.map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <StyledTableRow
                                        hover
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={index}
                                        selected={isItemSelected}>
                                        {/* <StyledTableCell padding="checkbox">
                                            <Checkbox
                                                checked={isItemSelected}
                                                inputProps={{ 'aria-labelledby': labelId }}
                                                className={classes.checkbox}
                                                onClick={(event) => handleClick(event, row.id)}
                                            />
                                        </StyledTableCell> */}
                                        <StyledTableCell align="right">{index + 1}</StyledTableCell>
                                        <StyledTableCell component="th" id={labelId} scope="row" padding="none">
                                            {row.name}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">{row.area}</StyledTableCell>
                                        {/* <StyledTableCell align="right">{row.capacity}</StyledTableCell> */}
                                        <StyledTableCell align="right">{row.pigType.name}</StyledTableCell>
                                        <StyledTableCell align="right">{row.weightType.name}</StyledTableCell>
                                        {(row.temperature !== 0 && row.temperature) ?
                                            <StyledTableCell align="right" style={{ width: 150 }}>{row.temperature}</StyledTableCell>
                                            :
                                            <StyledTableCell align="right" style={{ width: 150 }}></StyledTableCell>
                                        }
                                        {
                                            (row.humidity !== 0 && row.humidity) ?
                                                <StyledTableCell align="right" style={{ width: 150 }}>{row.humidity}</StyledTableCell>
                                                :
                                                <StyledTableCell align="right" style={{ width: 150 }}></StyledTableCell>
                                        }
                                        {
                                            (row.pressure !== 0 && row.pressure) ?
                                                <StyledTableCell align="right" style={{ width: 150 }}>{row.pressure}</StyledTableCell>
                                                :
                                                <StyledTableCell align="right" style={{ width: 150 }}></StyledTableCell>
                                        }
                                        <StyledTableCell align="right">
                                            <Tooltip title="Xem">
                                                <IconButton aria-label="Xem" disabled={row.cameras.length <= 0} onClick={() => handleOpenViewCam(row)}>
                                                    <CameraAltIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Sửa">
                                                <IconButton aria-label="edit" onClick={() => handleOpenCU(true, row)}>
                                                    <EditIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Xoá">
                                                <IconButton aria-label="delete" onClick={() => handleOpenDelete(row)}>
                                                    <DeleteIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Pagination
                    className={classes.tablePagination}
                    count={Math.ceil(pens.length / rowsPerPage)}
                    onChange={handleChangePage}
                />
                <Modal
                    open={openViewCam}
                    onClose={handleCloseViewCam}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className={classes.modal}>
                    <div className={classes.modalContentCreate}>
                        <ModalHeader title={`${penSelected?.name}`} closeButton={handleCloseViewCam} />
                        <PenDetail penSelected={penSelected} isEmptyPen={true} />
                        {/* <div className={`cursor-pointer ${classes.videoItemFullScreen}`}>
                        <CImageLoading
                            src={StreamAPI.getStream(penSelected?.id)}
                            className="h-60"
                            uuid={uuidv4()}
                            isHiddenCamera={penSelected?.cameras[0]?.isHiddenCamera ? true : false}
                            idCamera={penSelected?.cameras[0]?.id}
                        />
                        </div> */}
                    </div>
                </Modal>
                <Modal
                    open={openCU}
                    onClose={handleCloseCU}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className={classes.modal}>
                    <div className={classes.modalContentCreate}>
                        <ModalHeader title={isUpdate ? 'Cập nhật chuồng trống' : 'Tạo chuồng'} closeButton={handleCloseCU} />
                        <div style={{ zIndex: 2000 }}>
                            <PenEmptyCreat
                                onClose={handleCloseCU}
                                onRefresh={getAllPenEmptys}
                                penData={penSelected}
                                isUpdate={isUpdate}
                                onCloseWithoutCancelStream={handleCloseCUWithoutCancelStream}
                                tempPen={tempPen}
                                dataTagPigsByPen={tempTagByPen}
                                eatingPeriodLst={eatingPeriod}
                                lyingPeriodLst={lyingPeriod}
                                playingPeriodLst={playingPeriod}
                            />
                        </div>
                    </div>
                </Modal>
                {/* <Modal
                    open={openDelete}
                    onClose={handleCloseDelete}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className={classes.modal}>
                    <div className={classes.modalContentAlert}>
                        <CustomAlert
                            title={`Bạn có chắc chắn muốn xóa chuồng trống ${penSelected?.name}?`}
                            okButton={doDelete}
                            cancelButton={handleCloseDelete}
                        />
                    </div>
                </Modal> */}
                <ModalAntd
                    title="Xóa chuồng trống"
                    visible={isShowDeleteModal}
                    footer={null}
                    onCancel={() => setIsShowDeleteModal(false)}
                >
                    <p>Bạn có chắc chắn muốn xóa chuồng trống này?</p>
                    <div className="modal-footer" style={{ textAlign: 'end' }}>
                        <Button onClick={() => setIsShowDeleteModal(false)} style={{ marginRight: 10 }}>
                            Hủy bỏ
                        </Button>
                        <Button type='primary' htmlType='submit' onClick={handleFinishDeleteForm}>
                            Đồng ý
                        </Button>
                    </div>


                </ModalAntd>
            </Paper>
        </div>
    );
};

export default PenEmpty;
