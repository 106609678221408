/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import { withStyles, createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Modal from '@material-ui/core/Modal';
import ModalHeader from '../common/modal/modal-header';
import Pagination from '@material-ui/lab/Pagination';

import FoodSupplierCreate from './food-supplier-create';
import PageHeader from '../common/page-header/page-header';
import CustomAlert from '../common/alert/custom-alert';

import { CFG_SIZE_PAGINATION } from '@constants/config';
import { IFoodSupplierData } from '@type/food-supplier';
import { apiFoodSupplier } from '@src/src/app/api/food-supplier';
import { UtilPage } from '@utils/page';
import farmService from '@services/farm';
import { Context } from '@components/context/context';

import { Breadcrumb, Button, message, notification } from 'antd';
import openNotification, { NotificationType } from '../common/notification/Notification';
import { useHistory } from 'react-router-dom';
import { Modal as ModalAntd } from 'antd';


type Order = 'asc' | 'desc';

interface HeadCell {
    disablePadding: boolean;
    id: any;
    label: string;
    numeric: boolean;
}

const headCells: HeadCell[] = [
    { id: 'stt', numeric: false, disablePadding: true, label: 'STT' },
    { id: 'name', numeric: false, disablePadding: true, label: 'Tên nhà cung cấp' },
    { id: 'address', numeric: false, disablePadding: false, label: 'Địa chỉ' },
    { id: 'action', numeric: false, disablePadding: false, label: 'Thao tác' },
];

interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof IFoodSupplierData) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property: keyof IFoodSupplierData) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {/* <StyledTableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                        className={classes.checkbox}
                    />
                </StyledTableCell> */}
                {headCells.map((headCell) => (
                    <StyledTableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            hideSortIcon={true}>
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

interface EnhancedTableToolbarProps {
    numSelected: number;
    page: number;
    deleteAll: () => void;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const classes = useToolbarStyles();
    const { numSelected, page, deleteAll } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}>
            {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    Đã chọn {numSelected} nhà cung cấp
                </Typography>
            ) : (
                <Typography className={classes.title} id="tableTitle" component="div">
                    {page} Nhà cung cấp
                </Typography>
            )}
            {/* {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <div onClick={deleteAll}>
                        <IconButton aria-label="delete">
                            <DeleteIcon />
                        </IconButton>
                    </div>
                </Tooltip>
            ) : (
                // <Tooltip title="Filter list">
                //     <IconButton aria-label="filter list">
                //         <SearchIcon />
                //     </IconButton>
                // </Tooltip>
                ''
            )} */}
        </Toolbar>
    );
};

const useToolbarStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(1),
        },
        highlight:
            theme.palette.type === 'light'
                ? {
                    color: theme.palette.secondary.main,
                    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
                }
                : {
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.secondary.dark,
                },
        title: {
            flex: '1 1 100%',
            color: '#ABABAB',
            fontSize: '24px',
        },
    }),
);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '80%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        warning: {
            color: 'red',
        },
        normal: {
            color: '#18191F',
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        modalContentCreate: {
            backgroundColor: 'white',
            width: '35%',
        },
        modalContentAlert: {
            width: '30%',
        },
        checkbox: {
            color: '#ABABAB',
        },
        tablePagination: {
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            flexDirection: 'row',
            marginTop: '20px',
        },
        eventHappening: {
            color: '#00B1FF',
        },
        eventHappened: {
            color: '#ABABAB',
        },
    }),
);

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: '#FFDADE',
            color: '#18191F',
            fontWeight: 'bold',
            fontSize: '14px',
            textAlign: 'center',
        },
        body: {
            fontSize: '14px',
            border: 'none',
            textAlign: 'center',
        },
    }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:nth-of-type(even)': {
                backgroundColor: '#FFDADE',
            },
        },
    }),
)(TableRow);

const FoodSupplier: FC = () => {
    const classes = useStyles();
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof IFoodSupplierData>('name');
    const [selected, setSelected] = useState<string[]>([]);
    const [page, setPage] = useState(1);
    const [openCU, setOpenCU] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [foodSuppliers, setFoodSuppliers] = useState<Array<IFoodSupplierData>>([]);
    const [total, setTotal] = useState(0);
    const [foodSupplierSelected, setFoodSupplierSelected] = useState<IFoodSupplierData | any>();
    const [isUpdate, setIsUpdate] = useState(false);
    const context = React.useContext(Context);
    const [updateList, setUpdateList] = useState(new Date());
    const history = useHistory();
    const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
    const [idDelete, setIdDelete] = useState<string>('');
    const currentFarm = farmService.getCurrentFarm();

    useEffect(() => {
        getAllFoodSuppliers();
    }, [page, context.changeLocation.isChangeLocation, updateList]);

    const getAllFoodSuppliers = () => {
        const params = {
            offset: UtilPage.calculateOffset(page),
            size: CFG_SIZE_PAGINATION,
            farmId: currentFarm.id,
        };

        apiFoodSupplier
            .getAll(params)
            .then((res: any) => {
                if (res.items) {
                    setFoodSuppliers(res.items);
                    setTotal(UtilPage.calculateNumberPage(res.total));
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const doDelete = () => {
        apiFoodSupplier
            .deleteFoodSupplier(foodSupplierSelected?.id)
            .then((res) => {
                getAllFoodSuppliers();
                handleCloseDelete();
                openNotification(NotificationType.Info, 'topRight', `PIGMAN`, `Xóa nhà cung cấp thành công.`);
            })
            .catch((err) => {
                console.log('doDelete-err:' + JSON.stringify(err));
            });
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IFoodSupplierData) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = foodSuppliers.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, eventId: any) => {
        const selectedIndex = selected.indexOf(eventId);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, eventId);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };
    const handleOpenCU = (isUpdate: boolean, foodSupplierSelected?: IFoodSupplierData) => {
        setOpenCU(true);
        setFoodSupplierSelected(foodSupplierSelected);
        setIsUpdate(isUpdate);
    };

    const handleCloseCU = () => {
        setOpenCU(false);
    };

    const handleOpenDelete = (foodSupplierSelected?: IFoodSupplierData) => {
        // setOpenDelete(true);
        // setIdDelete(foodSupplierSelected?.id);
        setIsShowDeleteModal(true);
        setFoodSupplierSelected(foodSupplierSelected);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    const isSelected = (eventId: any) => selected.indexOf(eventId) !== -1;

    const deleteAll = () => {
        const deleteSuppliersApi = selected.map((v) => {
            return apiFoodSupplier.deleteFoodSupplier(v);
        });

        Promise.all(deleteSuppliersApi).then(() => {
            notification.success({
                message: 'Xóa thành công',
            } as any);
            setUpdateList(new Date());
            setSelected([]);
        });
    };
    const rowsPerPage = 10; // Number of rows to display per page
    const [currentPage, setCurrentPage] = useState(1);

    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const currentRows = foodSuppliers.slice(startIndex, endIndex);
    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setCurrentPage(newPage);
    };

    const handleFinishDeleteForm = () => {
        doDelete();
        setIsShowDeleteModal(false);
    }
    return (
        <div className={`${classes.root} main-content`}>
            <Paper className={classes.paper} elevation={0}>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                    <div className='main-breadcrumb'>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <span>Thức ăn</span>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>Danh sách nhà cung cấp</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <PageHeader
                        pageTile=""
                        isButton={true}
                        buttonClicked={() => handleOpenCU(false)}
                        buttonTitle="Tạo nhà cung cấp"
                    />
                </div>
                <EnhancedTableToolbar numSelected={selected.length} page={foodSuppliers.length} deleteAll={deleteAll} />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="enhanced table">
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={foodSuppliers.length}
                        />
                        <TableBody>
                            {currentRows.map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <StyledTableRow
                                        hover
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={index}
                                        selected={isItemSelected}>
                                        {/* <StyledTableCell padding="checkbox">
                                            <Checkbox
                                                checked={isItemSelected}
                                                inputProps={{ 'aria-labelledby': labelId }}
                                                className={classes.checkbox}
                                                onClick={(event) => handleClick(event, row.id)}
                                            />
                                        </StyledTableCell> */}
                                        <StyledTableCell align="right">{(currentPage - 1) * rowsPerPage + index + 1}</StyledTableCell>
                                        <StyledTableCell component="th" id={labelId} scope="row" padding="none">
                                            {row.name}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">{row.address}</StyledTableCell>
                                        <StyledTableCell align="right" style={{ width: 130 }}>
                                            <Tooltip title="Sửa">
                                                <IconButton aria-label="edit" onClick={() => handleOpenCU(true, row)}>
                                                    <EditIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Xoá">
                                                <IconButton aria-label="delete" onClick={() => handleOpenDelete(row)}>
                                                    <DeleteIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Pagination
                    className={classes.tablePagination}
                    count={Math.ceil(foodSuppliers.length / rowsPerPage)}
                    onChange={handleChangePage}
                />
                <Modal
                    open={openCU}
                    onClose={handleCloseCU}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className={classes.modal}>
                    <div className={classes.modalContentCreate}>
                        <ModalHeader
                            title={isUpdate ? 'Cập nhật nhà cung cấp' : 'Tạo nhà cung cấp'}
                            closeButton={handleCloseCU}
                        />
                        <FoodSupplierCreate
                            onClose={handleCloseCU}
                            onRefresh={getAllFoodSuppliers}
                            foodSupplier={foodSupplierSelected}
                            isUpdate={isUpdate}
                        />
                    </div>
                </Modal>
                {/* <Modal
                    open={openDelete}
                    onClose={handleCloseDelete}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className={classes.modal}>
                    <div className={classes.modalContentAlert}>
                        <CustomAlert
                            title={`Bạn có chắc chắn muốn xóa nhà cung cấp ${foodSupplierSelected?.name}?`}
                            okButton={doDelete}
                            cancelButton={handleCloseDelete}
                        />
                    </div>
                </Modal> */}
                <ModalAntd
                    title="Xóa nhà cung cấp"
                    visible={isShowDeleteModal}
                    footer={null}
                    onCancel={() => setIsShowDeleteModal(false)}
                >
                    <p>Bạn có chắc chắn muốn xóa nhà cung cấp này?</p>
                    <div className="modal-footer" style={{ textAlign: 'end' }}>
                        <Button onClick={() => setIsShowDeleteModal(false)} style={{ marginRight: 10 }}>
                            Hủy bỏ
                        </Button>
                        <Button type='primary' htmlType='submit' onClick={handleFinishDeleteForm}>
                            Đồng ý
                        </Button>
                    </div>


                </ModalAntd>
            </Paper>
        </div>
    );
};

export default FoodSupplier;
