/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { FC } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

type CustomAlertPros = {
    title: string;
    okButton: any;
    cancelButton: any;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            backgroundColor: '#E8F8FF',
            borderRadius: '10px'
        },
        title: {
            fontWeight: 500,
            fontSize: '14px',
            color: '#000000',
            padding: '40px',
            textAlign: 'center'
        },
        btn: {
            marginBottom: '20px'
        },
        btnDone: {
            backgroundColor: '#FFDADE',
            color: '#FF5A80',
            fontSize: '16px',
            textTransform: 'none',
            paddingLeft: '40px',
            paddingRight: '40px',
            '&:hover' : {
                backgroundColor: '#FF5A80',
                color: '#FFFFFF',
            }
        },
        btnCanel: {
            backgroundColor: '#FFDADE',
            color: '#FF5A80',
            fontSize: '16px',
            textTransform: 'none',
            paddingLeft: '40px',
            paddingRight: '40px',
            marginRight: '10px'
        }
    }),
);

const CustomAlert: FC<CustomAlertPros> = ({ title, okButton, cancelButton }: CustomAlertPros) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.title}>{title}</div>
            <div className={classes.btn}>
                <Button variant="contained" onClick={cancelButton} className={classes.btnCanel}>
                    Hủy bỏ
                </Button>
                <Button onClick={okButton} variant="contained" className={classes.btnDone}>
                    Đồng ý
                </Button>
            </div>
        </div>
    )
}

export default CustomAlert;
